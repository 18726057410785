import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Menu from '../pages/Menu/Admin/Menu';
import Login from '../pages/Ingreso/Login';
import Register from '../pages/Ingreso/Register';
import cambiar from '../pages/Menu/Cambiarcl';
import Nosotros from '../pages/Menu/Nosotros';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login}/>
        <Route exact path="/register" component={Register}/>
        <Route exact path="/menu" component={Menu}/>
        <Route exact path="/cambiar_clave" component={cambiar}/>        
        <Route exact path="/nosotros" component={Nosotros}/>
      </Switch>
      
    </BrowserRouter>
  );
}

export default App;
