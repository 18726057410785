import React, { Fragment, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Container, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FaPlus, FaAddressBook, FaPencilAlt, FaTrash } from "react-icons/fa";
import { withRouter, Route } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import SubCurso from "./SubCursos/SubCurso";
import { url } from "../../../../config";
import "../../../../css/Styles.css";
import SessionManager from "../../../SessionManagement.js";
import CerrarSesion from "../../../CerrarSesion.js"

function Cursos(props) {
  
SessionManager(props);

const cerrarSesion = () =>{
  CerrarSesion(props);
}
  const baseUrl = url;
  // Cerrar sesión
  

  const cookies = new Cookies();
  const [cursos, setCursos] = useState([]);
  const [cursosdocentes, setCursosdocentes] = useState([]);
  const [selectedCurso, setSelectedCurso] = useState(null);

  useEffect(() => {
    const fetchCursos = async () => {
      try {
        const token = cookies.get("token");
        const result = await axios.get(baseUrl + "cursos/menuCursos", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (result.status === 200) {
          setCursosdocentes(result.data.cursosdocentes);
          setCursos(result.data.cursosPanel);
        } else {
          // Realizar alguna acción en caso de que la respuesta no sea exitosa
        }
      } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
      }
    };
    fetchCursos();

    
  }, []);

  const redirectToSubCurso = (idCurso) => {
    setSelectedCurso(idCurso);
  };

  const renderSubCurso = () => {
    if (selectedCurso) {
      return <SubCurso idCurso={selectedCurso} />;
    }
    return null;
  };

  // Paginación y limitación de mapeo de cursos

  const [currentPage, setCurrentPage] = useState(1);
  const coursesPerPage = 9; // Cantidad de cursos por página

  // Función para ir a la página anterior
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Función para ir a la página siguiente
  const nextPage = () => {
    const totalPages = Math.ceil(cursos.length / coursesPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = cursos.slice(indexOfFirstCourse, indexOfLastCourse);

  // Generar los botones de paginación
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(cursos.length / coursesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Fragment>
      {renderSubCurso()}
      {!selectedCurso && (
        <Container style={{marginTop: '21px'}}>
          <div>
          <div className="d-flex align-items-center justify-content-between">
      <div className="col-12">
        <h1 className="titulo-inicio">Lista de cursos</h1>
      </div>
    </div>

    {currentCourses.length > 0 ? (
      <div className="table-responsive">
        <Table hover className="custom-table">
                <thead>
                  <tr>
                    <th className="align-middle">NOMBRE</th>
                    <th className="align-middle">INICIO</th>
                    <th className="align-middle">FIN</th>
                    <th className="align-middle">DOCENTES A CARGO</th>
                    <th className="align-middle">PUBLICADO</th>
                    <th className="align-middle">IR</th>
                  </tr>
                </thead>
                <tbody>
                  {currentCourses.map((curso, index) => (
                    <tr key={curso.idCurso}>
                      <td className="truncate-text align-middle mayusc">{curso.nom_curso}</td>
                      <td className="truncate-date align-middle">{new Date(curso.fecha_inicio).toLocaleDateString()}</td>
                      <td className="truncate-date align-middle">{new Date(curso.fecha_fin).toLocaleDateString()}</td>
                      <td className="truncate-docentes align-middle">{cursosdocentes[index]}</td>
                      <td style={{ width: "98px", height: "40px" }}>
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: curso.estado ? "#D55613" : "",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #D55613",
                          }}
                        >
                          <span
                            style={{ color: curso.estado ? "white" : "black" }}
                          >
                            {curso.estado ? "Si" : "No"}
                          </span>
                        </div>
                      </td>

                      <td>
                        <Button
                          variant="warning"
                          onClick={() => redirectToSubCurso(curso.idCurso)}
                        >
                          <FaAddressBook />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>  
      </div>
    ) : (
      <h3 className="nohaycursoslista">No hay cursos para mostrar</h3>
    )}
            {/* Paginación */}
            <nav aria-label="Page navigation example" className="fixed-pagination">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
                {pageNumbers.map((number) => (
                  <li key={number} className={`page-item ${currentPage === number ? "active" : ""}`}>
                    <a className="page-link" href="#" onClick={() => setCurrentPage(number)}>
                      {number}
                    </a>
                  </li>
                ))}
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>

          </div>
        </Container>
      )}
    </Fragment>
  );
}

export default withRouter(Cursos);
