import React, { Fragment, useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Modal, Button } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import "reactjs-popup/dist/index.css";
import "react-tabs/style/react-tabs.css";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import * as QuillTableUI from "quill-table-ui";
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function CursoEditorHTML(props) {
  
SessionManager(props);

const cerrarSesion = () =>{
  CerrarSesion(props);
}
  const baseUrl = url;
  const { idCurso } = props;
  const cookies = new Cookies();
  const [cursos, setCursos] = useState([]);
  const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
  const [validacionCompleta, setValidacionCompleta] = useState(false);
  const [editorContent, setEditorContent] = useState(""); // Content in rich text format
  const [htmlCode, setHtmlCode] = useState(""); // HTML code content
  const [showHtmlModal, setShowHtmlModal] = useState(false); // To control the HTML modal

  // Cerrar sesión
  

  const resetForm = () => {
    setEditorHTML({
      contenido: "",
    });
  };

  const fetchCursos = async () => {
    try {
      const token = cookies.get("token");
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(
        baseUrl + "modulos/listarModulos",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setCursos(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  const eliminarCurso = async (idModulos) => {
    Swal.fire({
      title: "¿Estás seguro de que deseas ELIMINAR este modulo?",
      text: "El modulo y su contenido no se podrán recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const request = {
            idModulos: idModulos,
          };
          const token = cookies.get("token");
          const response2 = await axios.post(
            baseUrl + "modulos/eliminarModulo",
            request,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Mostrar Sweet Alert
          Swal.fire({
            icon: "success",
            title: "Curso eliminado correctamente",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
          fetchCursos();
        } catch (error) {
          console.error(error);
          // Manejar el error de la solicitud al backend
        }
      } else {
        setCursoSeleccionado(null); // Restablecer a null
      }
    });
  };

  const [nuevoHTML, setEditorHTML] = useState({
    contenido: "", // Initialize contenido as an empty string
    idCurso: idCurso,
  });

  const handleSubmitContent = () => {
    const token = cookies.get("token"); // Get the authorization token from cookies
    // Update nuevoHTML with the content from quill
    nuevoHTML.contenido = editorContent;

    // Make the API call with nuevoHTML as the content
    axios
      .post(
        baseUrl + "Paginas_contenido/registrarPaginas_contenido",
        nuevoHTML,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include the token
          },
        }
      )
      .then((response) => {
        // Handle the success
        console.log("CONTENIDO ENVIADO CORRECTAMENTE");
      })
      .catch((error) => {
        // Handle errors
        console.error("ERROR ENVIANDO DATOS:", error);
      });
  };

  // Popup Editar
  const [editarCurso, setEditarCurso] = useState(false);

  const abrirEditarCurso = (editorhtml) => {
    setEditarCurso(true);

    // Asignar los valores del editorhtml seleccionado al objeto nuevoHTML
    setEditorHTML({
      contenido: editorhtml.contenido,
      idCurso: editorhtml.idCurso,
    });
  };

  useEffect(() => {
    fetchCursos();
    
  }, []);
  //

  useEffect(() => {
    // Realiza la validación cuando se actualicen los valores relevantes
    if (nuevoHTML.contenido) {
      setValidacionCompleta(true);
    } else {
      setValidacionCompleta(false);
    }
  }, [nuevoHTML.contenido]);

  // Ref para el editor Quill
  const quillRef = useRef(null);
  const [quillInstance, setQuillInstance] = useState(null);

  const handleCloseHtmlModal = () => {
    setShowHtmlModal(false);
  };

  const handleShowHtmlModal = () => {
    setHtmlCode(editorContent);
    setShowHtmlModal(true);
  };

  const updateQuillContent = (html) => {
    if (quillInstance) {
      quillInstance.root.innerHTML = html;
      setEditorContent(html);
    }
  };

  const handleSaveHtmlChanges = () => {
    setEditorContent(htmlCode); // Update the content immediately in the rich text editor
    handleCloseHtmlModal();
  };

  useEffect(() => {
    // Inicializa Quill dentro de useEffect para asegurarnos de que el DOM esté listo
    if (!quillInstance && quillRef.current) {
      const Font = Quill.import("formats/font");
      Font.whitelist = [
        "serif",
        "sans-serif",
        "monospace",
        "times-new-roman",
        "arial",
        "tahoma",
        "helvetica",
      ];
      Quill.register(Font, true);
      Quill.register(
        {
          "modules/tableUI": QuillTableUI.default,
        },
        true
      );

      const toolbarOptions = [
        ["bold", "italic", "underline", "strike"],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "+1" }, { indent: "-1" }],
        [{ align: [] }],
        [{ size: ["small", "large", "huge", false] }],
        ["image", "link", "video", "formula"],
        [{ color: [] }, { background: [] }],
        [
          {
            font: [
              "arial",
              "helvetica",
              "serif",
              "sans-serif",
              "monospace",
              "times-new-roman",
              "tahoma",
            ],
          },
        ],
        ["code-block", "blockquote"],
      ];

      const quill = new Quill(quillRef.current, {
        modules: {
          toolbar: toolbarOptions,
          table: true,
          tableUI: true,
        },
        theme: "snow",
      });

      setQuillInstance(quill);
    }
  }, [quillInstance]);

  const createTable = () => {
    if (quillInstance) {
      const table = quillInstance.getModule("table");
      if (table) {
        table.insertTable(2, 2); // You can specify the number of rows and columns
      }
    }
  };

  useEffect(() => {
    if (quillInstance) {
      quillInstance.on("text-change", () => {
        const html = quillInstance.root.innerHTML;
        setEditorContent(html);
      });
    }
  }, [quillInstance]);

  return (
    <Fragment>
      <Container>
        <div className="col-12">
          <h1>Agregando Datos con Editor html</h1>
        </div>
        <div>
          <Button variant="info" onClick={handleShowHtmlModal}>
            Cambiar vista a HTML
          </Button>
        </div>
        <br></br>
        <div className="align-items-center justify-content-between mb-3">
          <div id="editando" ref={quillRef}>
            <p>
              <br />
            </p>
          </div>
          <br />
          {/* <Button variant="warning" onClick={createTable}>
            Crear tabla
          </Button> */}
          &nbsp;&nbsp;
          <Button variant="success" onClick={handleSubmitContent}>
            Guardar contenido
          </Button>
        </div>
        <Modal show={showHtmlModal} onHide={handleCloseHtmlModal} size="lg">
          {" "}
          {/* Set the size to large */}
          <Modal.Header className="centered-title" closeButton>
            <Modal.Title>Editor HTML</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              rows="10"
              value={htmlCode}
              onChange={(e) => setHtmlCode(e.target.value)}
              className="custom-textarea"
              spellCheck="false"
            />
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="danger" onClick={handleCloseHtmlModal}>
              Cerrar
            </Button>
            <Button
              variant="success"
              onClick={() => {
                updateQuillContent(htmlCode);
                handleSaveHtmlChanges();
              }}
            >
              Guardar cambios
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Fragment>
  );
}

export default withRouter(CursoEditorHTML);
