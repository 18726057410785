import React, { Fragment, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withRouter } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { FaPlus, FaAsterisk, FaPencilAlt, FaTrash, FaArrowCircleLeft, FaWindowClose } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import ExamenPrDetalle from './ExamenPrDetalle';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../../config";
import SessionManager from "../../../../../SessionManagement.js";
import CerrarSesion from "../../../../../CerrarSesion.js"

function ExamenPreguntas(props) {
    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idExamen } = props;
    const { idCurso } = props;
    const cookies = new Cookies();
    // Estado para almacenar los idTipo_preguntas disponibles
    const [idExamenOptions, setidExamenOptions] = useState([]);
    const [idExamenNombre, setidExamenNombre] = useState([]);
    const [idTipoPregunta, setTipoPregunta] = useState([]);
    const [idexamen, setIdexamen] = useState(null);
    const [idcurso, setIdcurso] = useState(null);
    const [idexamenpreguntas, setIdExamenPreguntas] = useState(null);
    const [mostrarPreguntaDetalle, setMostrarPreguntaDetalle] = useState(false);
    const [showCrud, setShowCrud] = useState(true);
    const [examenpreguntas, setExamenpreguntas] = useState([]);
    const [selectedPregunta, setSelectedPregunta] = useState(null);
    const [nombreClase, setNombreClase] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [preguntaSeleccionada, setPreguntaSeleccionada] = useState(null);
    const [checkboxesSeleccionados, setCheckboxesSeleccionados] = useState({});
    const [nuevoCursoDocente, setNuevoCursoDocente] = useState([]);
    const [nuevoCursoEstudiante, setNuevoCursoEstudiante] = useState([]);
    const [soloPregunta, setSoloCurso] = useState(true);
    const [validacionCompleta, setValidacionCompleta] = useState(false);

    const [nuevaPregunta, setNuevaPregunta] = useState({
        descripcion_pregunta: '',
        puntos: '',
        idExamen: idExamen,
        idTipo_preguntas: '',
    });

    // Capturando datos para el nuevaPregunta.
    const ModuloPregunta = {
        descripcion_pregunta: nuevaPregunta.descripcion_pregunta,
        puntos: parseInt(nuevaPregunta.puntos),
        idExamen: parseInt(idExamen),
        idTipo_preguntas: parseInt(nuevaPregunta.idTipo_preguntas)
    };
    const resetForm = () => {
        setNuevaPregunta({
            descripcion_pregunta: '',
            puntos: '',
            idExamen: '',
            idTipo_preguntas: '',
        });
        setNuevoCursoDocente([]);
        setNuevoCursoEstudiante([]);
        setCheckboxesSeleccionados({});
        // Restablecer otras variables relacionadas si las hay
    };

    const togglePopup = () => {
        resetForm();
        setShowPopup(!showPopup);
        return true;
    };

    const fetchExamenpreguntas = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idExamen: idExamen };
            const result = await axios.post(baseUrl + "Examen_preguntas/listarExamenesPreguntas", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                const nombreTipoPreguntas = {};
                Object.values(result.data.nombreTipo_preguntas).forEach(obj => {
                    const key = Object.keys(obj)[0];
                    nombreTipoPreguntas[key] = obj[key];
                });

                // Actualizar examenpreguntas con la lista de preguntas de examen
                setExamenpreguntas(result.data.examenesPreguntas);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    // Agregar nuevo Modulo:
    const handleChange = (event) => {
        const { name, value } = event.target;

        let newValue;
        if (name === 'puntos' || name === 'idExamen' || name === 'idTipo_preguntas') {
            newValue = value === "" ? "" : parseInt(value);
        } else {
            newValue = value;
        }

        setNuevaPregunta((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };


    const fetchIdExamen = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "examen/listarExamenesMenu", requestBody, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (result.status === 200) {
                // Extraer los idModulos de la respuesta y almacenarlos en el estado
                setidExamenOptions(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const agregarPregunta = async (event) => {
        event.preventDefault(); // Cancelar el evento por defecto
        try {
            if (editarPregunta) {
                // EDITAR TAREA
                //
                // Establecer los valores del pregunta seleccionado al formulario popup
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevaPregunta.descripcion_pregunta ||
                    !nuevaPregunta.puntos ||
                    !nuevaPregunta.idTipo_preguntas
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                const response = await axios.post(
                    baseUrl + 'Examen_preguntas/editarExamenPreguntas',
                    nuevaPregunta,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Realizar alguna acción después de registrar el pregunta exitosamente
                // Por ejemplo, cerrar el popup y volver a cargar la lista de examenpreguntas
                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Cambio exitoso',
                        text: 'Modulo actualizado correctamente!',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    togglePopup();
                    fetchExamenpreguntas();
                    resetForm();
                }
            } else {
                // AGREGAR TAREA
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevaPregunta.descripcion_pregunta ||
                    !nuevaPregunta.puntos ||
                    !nuevaPregunta.idTipo_preguntas
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                if (soloPregunta) {
                    // Llamar a la API para agregar un pregunta, docentes y estudiantes
                    const response = await axios.post(

                        baseUrl + 'Examen_preguntas/registrarExamenPreguntas',
                        ModuloPregunta,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el pregunta exitosamente
                    // cerrar el popup y volver a cargar la lista de examenpreguntas
                    if (response.status === 201) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            text: 'Tarea creado correctamente!',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                        togglePopup();
                        fetchExamenpreguntas();
                        resetForm();
                    }
                }
            }
        } catch (error) {
            // MANEJO DE ERRORES
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };

    const eliminarPregunta = async (idExamen_preguntas) => {
        Swal.fire({
            title: '¿Estás seguro de que deseas ELIMINAR esta pregunta?',
            text: 'El modulo y su contenido no se podrán recuperar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const request = {
                        idExamen_preguntas: idExamen_preguntas
                    };
                    const token = cookies.get('token');
                    const response2 = await axios.post(baseUrl + 'Examen_preguntas/eliminarExamenPreguntas', request, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    // Mostrar Sweet Alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Pregunta eliminada correctamente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    fetchExamenpreguntas();

                } catch (error) {
                    console.error(error);
                    // Manejar el error de la solicitud al backend
                }
            } else {
                setPreguntaSeleccionada(null); // Restablecer a null
            }
        });
    };

    //
    const setOptionColor = (optionValue) => {
        if (optionValue >= 0 && optionValue <= 12) {
            return 'option-red';
        } else if (optionValue >= 13 && optionValue <= 20) {
            return 'option-green';
        }
    };
    // Popup Editar
    const [editarPregunta, setEditarPregunta] = useState(false);

    const abrirEditarPregunta = (pregunta) => {
        setEditarPregunta(true);
        togglePopup();

        // Asignar los valores del pregunta seleccionado al objeto nuevaPregunta
        setNuevaPregunta({
            idExamen_preguntas: pregunta.idExamen_preguntas,
            descripcion_pregunta: pregunta.descripcion_pregunta,
            puntos: parseInt(pregunta.puntos),
            idExamen: parseInt(idExamen),
            idTipo_preguntas: parseInt(pregunta.idTipo_preguntas)
        });
    };

    const fetchIdTipoPregunta = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idExamen: idExamen };
            const result = await axios.get(baseUrl + "Examen_preguntas/tipos_preguntas", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Extraer los idTipo_preguntas de la respuesta y almacenarlos en el estado
                setidExamenOptions(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchforosTipoPregunta = async () => {
        try {
            const token = cookies.get('token');
            const result = await axios.get(baseUrl + "Tipo_preguntas", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Extraer los idTipo_preguntas de la respuesta y almacenarlos en el estado
                setTipoPregunta(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };


    const fetchnombretipregunta = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idExamen: idExamen };
            const result = await axios.post(baseUrl + "Examen_preguntas/listarExamenesPreguntas", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                const nombreTipoPreguntas = {};
                Object.values(result.data.nombreTipo_preguntas).forEach(obj => {
                    const key = Object.keys(obj)[0];
                    nombreTipoPreguntas[key] = obj[key];
                });
                setidExamenNombre(nombreTipoPreguntas);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };


    useEffect(() => {
        const fetchNombreCurso = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idCurso: idCurso };

                const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    setNombreClase(result.data);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };

        fetchNombreCurso();
        fetchIdExamen();
        fetchIdTipoPregunta();
        fetchnombretipregunta();
        fetchExamenpreguntas();
        fetchforosTipoPregunta();
    }, [nuevaPregunta]);
    // 

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevaPregunta.descripcion_pregunta && nuevaPregunta.puntos && nuevaPregunta.idExamen && nuevaPregunta.idTipo_preguntas) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevaPregunta.descripcion_pregunta, nuevaPregunta.puntos, nuevaPregunta.idExamen, nuevaPregunta.idTipo_preguntas]);

    // Paginación y limitación de mapeo de cursos

    const [currentPageCursos, setCurrentPageCursos] = useState(1);
    const coursesPorPage = 3; // Cantidad de cursos por página

    // Función para ir a la página anterior
    const previousPage = () => {
        if (currentPageCursos > 1) {
            setCurrentPageCursos(currentPageCursos - 1);
        }
    };

    // Función para ir a la página siguiente
    const nextPage = () => {
        const totalPages = Math.ceil(examenpreguntas.length / coursesPorPage);
        if (currentPageCursos < totalPages) {
            setCurrentPageCursos(currentPageCursos + 1);
        }
    };

    const indexOfLastCoursePage = currentPageCursos * coursesPorPage;
    const indexOfFirstCoursePage = indexOfLastCoursePage - coursesPorPage;
    const currentCoursesA = examenpreguntas.slice(indexOfFirstCoursePage, indexOfLastCoursePage);

    // Generar los botones de paginación
    const pageNumbersA = [];
    for (let i = 1; i <= Math.ceil(examenpreguntas.length / coursesPorPage); i++) {
        pageNumbersA.push(i);
    }

    const redirectToPreguntasOpciones = (idExamen_preguntas) => {
        ;
        setSelectedPregunta(idExamen_preguntas);
        setShowCrud(false);
    };

    const renderSelectedPreguntas = () => {
        if (selectedPregunta) {
            return <ExamenPrDetalle idExamen_preguntas={selectedPregunta} idCurso={idCurso} idExamen={idExamen} />;
        }
        return null;
    };

    const handlePreguntaDetalle = (idExamen_preguntas, idCurso, idExamen) => {
        setIdExamenPreguntas(idExamen_preguntas);
        setIdcurso(idCurso);
        setIdexamen(idExamen);
        setMostrarPreguntaDetalle(true);
    };


    const handleocultarPreguntaDetalle = () => {
        setMostrarPreguntaDetalle(false);
    };
    return (
        <Fragment>
            {renderSelectedPreguntas()}
            {!selectedPregunta && showCrud && (
                <div>
                    {/* Si estamos en la vista del editor */}
                    {mostrarPreguntaDetalle && (
                        <div className="align-right-container">
                            <Button style={{ marginTop: '5px' }} className="lavandabu direction-button" onClick={handleocultarPreguntaDetalle}>
                                Volver a lista de Preguntas de Exámenes <FaArrowCircleLeft className="fa-icon"/>
                            </Button>
                        </div>
                    )}
                    {/* ---------------------------------------------------------- */}
                    {/* ---------------NAVEGACION: REEDIRECION------------------- */}
                    {mostrarPreguntaDetalle ? (
                        <ExamenPrDetalle idExamen_preguntas={idexamenpreguntas} idCurso={idcurso} idExamen={idexamen} />
                    ) : (
                        <Container>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="col-12">
                                    <h1>Lista de Preguntas del examen</h1>
                                    <Button variant="success" onClick={togglePopup}>
                                        Agregar Pregunta  <FaPlus />
                                    </Button>
                                </div>
                            </div>
                            <div className="table-responsive">
                            {currentCoursesA.length > 0 ? (
                                <Table hover className="custom-table">
                                    <thead>
                                        <tr>
                                            <th>Descripción de la Pregunta</th>
                                            <th>Puntos</th>
                                            <th>Tipo de preguntas</th>
                                            <th>Acciones</th>
                                            <th>Definir Respuestas de la pregunta</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentCoursesA.map((pregunta, index) => (
                                            <tr key={pregunta.idExamen_preguntas}>
                                                <td className='truncate-pregunta align-middle'>{pregunta.descripcion_pregunta}</td>
                                                <td className='align-middle'>{pregunta.puntos}</td>
                                                <td className='align-middle'>{idExamenNombre[pregunta.idTipo_preguntas]}</td>
                                                <td >
                                                    <Button className='align-middle' variant="warning" onClick={() => abrirEditarPregunta(pregunta)}>
                                                        <FaPencilAlt />
                                                    </Button>{' '}
                                                    <Button className='align-middle' variant="danger" onClick={() => eliminarPregunta(pregunta.idExamen_preguntas)}>
                                                        <FaTrash />
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button className="btn-block align-middle" variant="success" onClick={() => handlePreguntaDetalle(pregunta.idExamen_preguntas, idCurso, idExamen)}
                                                    >
                                                        <FaAsterisk />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <h3 className="nohaycursoslista">No hay preguntas para mostrar</h3>
                            )}
                            </div>
                            {/* Paginación */}
                            <nav aria-label="Page navigation example" className="fixed-pagination2">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    {pageNumbersA.map((number) => (
                                        <li key={number} className={`page-item ${currentPageCursos === number ? "active" : ""}`}>
                                            <a className="page-link" href="#" onClick={() => setCurrentPageCursos(number)}>
                                                {number}
                                            </a>
                                        </li>
                                    ))}
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </Container>
                    )}
                </div>
            )}

            <CSSTransition
                in={showPopup}
                timeout={300}
                classNames="popup-transition"
                unmountOnExit
            >
                <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} className="popup-contenta">
                    <div className="popup-wrapper">
                        <div className="popup-contenta">
                            <div>
                                <div className="popup-header">
                                    <div className="popup-title">
                                        Agregar Pregunta
                                    </div>
                                    <div className="popup-close-button">
                                        <FaWindowClose color="#c82333" className="invert-icon" onClick={togglePopup} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                                <div className="popup-message">
                                    Por favor ingrese los siguientes datos:
                                </div>
                                <hr />
                                <form onSubmit={agregarPregunta}>
                                    <div className="input-group">
                                        <span>Titulo de la pregunta: </span>
                                        <input
                                            id="descripcion_pregunta"
                                            type="text"
                                            maxLength={350}
                                            className="popup-input"
                                            placeholder="Escriba una descripcion de la pregunta"
                                            name="descripcion_pregunta"
                                            value={nuevaPregunta.descripcion_pregunta}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-group">
                                        <span>Puntos:</span>
                                        <select
                                            id="puntos"
                                            type="text"
                                            className="popup-input"
                                            placeholder="19"
                                            name="puntos"
                                            value={nuevaPregunta.puntos}
                                            onChange={handleChange}
                                            required
                                        >
                                            {Array.from({ length: 21 }, (_, i) => (
                                                <option
                                                    key={i}
                                                    value={i}
                                                    className={setOptionColor(i)}
                                                >
                                                    {i.toString().padStart(2, '0')}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <hr />
                                    <div className="input-group">
                                        <span>Tipo de preguntas:</span>
                                        <select
                                            id="idTipo_preguntas"
                                            className="popup-input"
                                            name="idTipo_preguntas"
                                            value={nuevaPregunta.idTipo_preguntas}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Seleccionar Tipo de preguntas</option>
                                            {idTipoPregunta.map((tipo) => (
                                                <option key={tipo.idTipo_preguntas} value={tipo.idTipo_preguntas}>
                                                    {tipo.nombre}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                    <hr style={{ marginTop: '1px' }} />
                                    <div className="input-group">
                                        <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                                            {editarPregunta ? "Guardar cambios" : "Crear nueva pregunta"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Popup>
            </CSSTransition>
        </Fragment>
    );
}

export default withRouter(ExamenPreguntas);