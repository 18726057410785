import React, { Fragment, useEffect, useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import {
    FaChevronDown, FaComment, FaTrash, FaChevronRight, FaWindowClose,
    FaPencilAlt, FaTable, FaArrowCircleLeft, FaPlusSquare, FaArrowUp, FaBookOpen, FaArrowDown
} from 'react-icons/fa';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import CursoModulosCrud from './CursoModuloCrud';
import CursoTareaEnvio from './CursoTareaEnvio';
import ExamenPrevResolution from './CursoExamenes/ExamenPrevResolution';
import CursoForoDiscu from './CursoForoDiscu';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import ContenidoEditorHTML from './ContenidoEditorHTML';
import ContenidoUpdateHTML from './ContenidoUpdateHTML';
import CursoRenderContenido from './CursoRenderContenido';


function CursoContenido(props) {
    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idCurso, idPagina } = props;
    const { idUser } = props;
    const [examenes, setCursos] = useState([]);
    const [contenido, setContenido] = useState([]);
    const [nombremodulos, setNombreModulos] = useState([]);
    const [modulosOrdenados, setModulosOrdenados] = useState([]);
    const [nombreClase, setNombreClase] = useState('');
    const [showHeader, setShowHeader] = useState(true); // Initialize as true  
    const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const [mostrarEditor, setMostrarEditor] = useState(false);
    const [mostrarUpdate, setMostrarUpdate] = useState(false);
    const [idModuloActual, setIdModuloActual] = useState(null);
    const [idTarea, setIdTarea] = useState(null);
    const [alertaMostrada, setAlertaMostrada] = useState(false);
    const [mostrarContenido, setMostrarContenido] = useState(false);
    // Estado para controlar si el collapse está abierto o cerrado
    const [open, setOpen] = useState(true);
    // Función para alternar el collapse
    const toggleCollapse = () => {
        setOpen(!open);
    };

    const handleLeerContenido = (idModulo) => {
        setIdModuloActual(idModulo);
        setMostrarContenido(true);
    };

    const handleocultarContenido = () => {
        setMostrarContenido(false);
    };

    const handleocultarUpdate = () => {
        setMostrarUpdate(false);
    };

    const handlemostrarUpdate = (idModulo) => {
        setIdModuloActual(idModulo);
        setMostrarUpdate(true);
    };

    const handleocultarEditor = () => {
        setMostrarEditor(false);
    };

    const handlemostrarEditor = () => {
        setMostrarEditor(true);
    };

    // State to track the open collapse. This will track the index of the open collapse.
    const [abrirCollapse, setAbrirCollapse] = useState(null);
    const [buttonClicked, setButtonClicked] = useState(
        localStorage.getItem('buttonClicked') === 'true'
    );

    // Function to toggle collapses
    const DesplegarCollapse = (index) => {
        setAbrirCollapse(abrirCollapse === index ? null : index);
    };

    const usuarioId = parseInt(idUser, 10);

    const fetchCursos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setCursos(result.data);
                console.log("eeee", result.data)
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const EliminandoContenido = async (idModulo) => {
        try {
            const token = cookies.get('token');
            const requestBody = { idModulos: idModulo };
            const result = await axios.post(baseUrl + "Paginas_contenido/BuscarPaginas_contenidoModulo", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (result.status === 200 && result.data) {
                const idPaginaContenido = result.data.idPagina_contenido;
                // Solicitar confirmación antes de eliminar
                Swal.fire({
                    title: '¿Estás seguro de que deseas ELIMINAR el contenido?',
                    text: 'El contenido no se podrá recuperar',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        eliminarContenido(idPaginaContenido);
                    } else {
                        setCursoSeleccionado(null); // Restablecer a null si se cancela
                    }
                });

            } else {
                // Manejar el caso en que no se encuentre el contenido o la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
        }
    };


    const eliminarContenido = async (idPaginaContenido) => {
        try {
            const request = {
                idPagina_contenido: idPaginaContenido
            };
            const token = cookies.get('token');
            const response2 = await axios.post(baseUrl + 'Paginas_contenido/eliminarPaginas_contenido', request, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            // Mostrar Sweet Alert
            Swal.fire({
                icon: "success",
                title: "Contenido eliminado correctamente",
                showConfirmButton: true,
                confirmButtonText: "Ok",
            });
            fetchCursos();
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    /*-------------------------------------------------------------------------------------------------------*/
    const cookies = new Cookies();

    const fetchNombreCurso = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setNombreClase(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchExamenesinModulos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "examen/listarExamenesMenu", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setCursos(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchContenido = async (idModulo) => {
        try {
            const token = cookies.get('token');
            const requestBody = { idModulos: idModulo };
            const result = await axios.post(baseUrl + "Paginas_contenido/BuscarPaginas_contenidoModulo", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Aquí debes decidir cómo manejar múltiples respuestas.
                // Por ejemplo, podrías agregar los datos de contenido a un arreglo o un objeto.
                setContenido(prevContenido => [...prevContenido, result.data]);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    //<------------------------------------------MANEJANDO REORGANIZACION--------------------------------------------------------------
    const [modulosFetched, setModulosFetched] = useState(false);

    const fetchModulos = async () => {
        try {
            console.log("Iniciando fetchModulos...");
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                console.log("Respuesta exitosa en fetchModulos:", result.data);
                // Establecer el estado de todos los módulos
                setNombreModulos(result.data);
                setModulosFetched(true);


                // Aquí reordena los módulos según el nuevo orden basado en idModulos
                const modulosReordenados = ordenarModulosSegunId(modulosOrdenados, result.data);
                setModulosOrdenados(modulosReordenados);

                // Llama a fetchContenido para cada módulo
                modulosReordenados.forEach(modulo => {
                    console.log("Fetching contenido para el módulo:", modulo.idModulos);
                    fetchContenido(modulo.idModulos);
                });
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const ordenarModulosSegunId = (modulosOrdenados, modulosOriginales) => {
        return modulosOrdenados.map(idModulo =>
            modulosOriginales.find(modulo => modulo.idModulos === idModulo)
        );
    };

    const obtenerDetalleModulo = (idModulo) => {
        return nombremodulos.find(modulo => modulo.idModulos === idModulo);
    };

    useEffect(() => {
        console.log("Módulos actualizados:", nombremodulos);
    }, [nombremodulos]);

    const handleMoveModule = (currentIndex, targetIndex) => {
        const updatedModules = [...nombremodulos];
        const temp = updatedModules[currentIndex];
        updatedModules[currentIndex] = updatedModules[targetIndex];
        updatedModules[targetIndex] = temp;

        setNombreModulos(updatedModules);
        setModulosOrdenados(updatedModules.map(modulo => modulo.idModulos)); // Actualizar modulosOrdenados
        console.log("Estado actualizado:", updatedModules);

        // Aquí ya no mapeas a IDs, sino que envías el array reordenado
        guardarOrdenModulos(updatedModules);
    };


    const guardarOrdenModulos = async (updatedModules) => {
        try {
            const token = cookies.get('token');
            const requestBody = {
                idCurso: idCurso,
                idPagina: idPagina,
                nuevoOrden: updatedModules.map(modulo => modulo.idModulos).join(",")
            };
            const result = await axios.post(baseUrl + "OrdenModulo/editarOrdenModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                console.log("Orden de módulos guardado exitosamente");
            } else {
                // Manejar errores o respuestas no exitosas
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchModulosOrdenados = async () => {
        try {
            console.log("Ejecutando useEffect para fetchModulosOrdenados...");
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso, idPagina: 3 };
            const result = await axios.post('https://localhost:44322/api/OrdenModulo/listarModulos', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                console.log("Respuesta exitosa en fetchModulosOrdenados:", result.data);
                setModulosOrdenados(result.data);
            } else {
                console.error("Error al obtener los módulos ordenados");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const confirmMoveModule = (currentIndex, targetIndex) => {
        if (!alertaMostrada) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: '¿Deseas mover este módulo?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, moverlo',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleMoveModule(currentIndex, targetIndex);
                }
            });

            // Marcar la alerta como mostrada
            setAlertaMostrada(true);
        } else {
            // Si la alerta ya se mostró antes, simplemente realizar la acción
            handleMoveModule(currentIndex, targetIndex);
        }
    };

    useEffect(() => {
        if (modulosFetched) { // Solo ejecuta si fetchModulos se ha completado
            console.log("Ejecutando useEffect para fetchModulosOrdenados...");
            fetchModulosOrdenados();
        }
    }, [modulosFetched]);  // Ahora solo depende de modulosFetched
    //<--------------------------------------------------------------------------------------------------------


    //<------------------------------------------MANEJANDO LLAMADAS--------------------------------------------------------------
    //El problema de las múltiples solicitudes parece estar relacionado con las dependencias del
    //useEffect. Si nombremodulos, idCurso, o tareas cambian, se volverá a ejecutar todo el 
    //bloque de useEffect, lo que incluye las llamadas a todas las funciones de fetch que has definido.

    // Este useEffect se ejecutará una sola vez al montar el componente ya que no tiene dependencias.
    useEffect(() => {
        fetchNombreCurso();
        // No agregamos dependencias a este useEffect si solo queremos que se ejecute una vez.
    }, [idCurso]);

    // Este useEffect manejará la carga de módulos y solo se ejecutará cuando idCurso cambie.
    useEffect(() => {
        console.log("Ejecutando useEffect para fetchModulos...");
        fetchModulos();
        // Agrega cualquier otra función que solo necesite ejecutarse cuando idCurso cambie.
    }, [idCurso]);

    // Este useEffect manejará la carga de exámenes y también solo se ejecutará cuando idCurso cambie.
    useEffect(() => {
        fetchExamenesinModulos();
        // Si necesitas llamar a otras funciones similares, añádelas aquí.
    }, [idCurso]);

    // Este efecto es para el manejo de acciones después de un click de botón, por ejemplo.
    useEffect(() => {
        if (buttonClicked) {
            localStorage.setItem('buttonClicked', 'true');
            // Llama a cualquier función que deba ejecutarse después de un click.
        }
    }, [buttonClicked]);

    //Al separar estas llamadas, previenes que una llamada fetch no necesaria se realice debido a cambios
    //en el estado que no afectan a esa llamada en particular. Además, esto hace que tu componente sea
    //más predecible y más fácil de mantener.

    //<--------------------------------------------------------------------------------------------------------

    const renderHeader = () => (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {nombreClase.nom_curso && <h5 style={{ margin: '0', marginRight: '10px' }}>{nombreClase.nom_curso}</h5>}
                <FaChevronRight color="#F47735" />
                <h5 style={{ margin: '0', marginLeft: '10px' }}>Portafolio de Contenido</h5>
                <FaChevronRight color="#F47735" />
                <h5 style={{ margin: '0', marginLeft: '10px' }}>Contenidos</h5>
            </div>
            <hr />
        </div>
    );

    return (
        <Fragment>
            <div className='container'>
                {showHeader && renderHeader()}
                {mostrarEditor ? (
                    <>
                        <div className="d-flex justify-content-end">
                            <Button className="tealbu direction-button" onClick={handleocultarEditor}>
                                Volver lista de Contenidos <FaArrowCircleLeft className="fa-icon" />
                            </Button>
                        </div>
                        <ContenidoEditorHTML idCurso={idCurso} />

                    </>
                ) : mostrarUpdate ? (
                    <>
                        <div className="d-flex justify-content-end">
                            <Button className="tealbu direction-button" onClick={handleocultarUpdate}>
                                Volver lista de Contenidos <FaArrowCircleLeft className="fa-icon" />
                            </Button>
                        </div>
                        <ContenidoUpdateHTML idCurso={idCurso} idModulo={idModuloActual} />
                    </>
                ) : mostrarContenido ? (
                    <>
                        <div className="d-flex justify-content-end">
                            <Button className="tealbu direction-button" onClick={handleocultarContenido}>
                                Volver a la lista de Contenidos <FaArrowCircleLeft className="fa-icon" />
                            </Button>
                        </div>
                        <CursoRenderContenido idCurso={idCurso} idModulo={idModuloActual} />
                    </>
                ) : (
                    <div>
                        {/* Muestra el encabezado si showHeader es true */}
                        <div className="d-flex justify-content-end mb-3">
                            {usuarioId === 4 || usuarioId === 5 ? (
                                <div className="d-flex justify-content-end mb-3">
                                    {/* Si no estamos en ninguna vista de CRUD */}
                                    {!mostrarEditor && !mostrarUpdate && (
                                        <div className="d-flex justify-content-end arribitanomas">
                                            <Button className="amarillobu" onClick={handlemostrarEditor}>
                                                Agregar contenido al modulo <FaPlusSquare />
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            ) : null}
                        </div>
                        <div className="collapse-header" onClick={toggleCollapse}>
                            {open ? <FaChevronDown color="#F47735" /> : <FaChevronRight color="#F47735" />}
                            <h4 className="naranjah4">Contenidos registrados</h4>
                        </div>
                        <Collapse in={open}>
                            <div className="collapse-content">
                                {modulosOrdenados.length > 0 ? modulosOrdenados.map((idModulo, index) => {
                                    console.log(idModulo)
                                    const modulo = obtenerDetalleModulo(idModulo);
                                    console.log(modulo)
                                    return (
                                        <Fragment key={index}>
                                            <hr className='lineamodulo' />
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                {/*BOTONES DE REORDENAMIENTO*/}
                                                {/*{usuarioId === 4 || usuarioId === 5 ? (
                                                    <div className="d-flex align-items-center" style={{ flexDirection: 'column' }}>
                                                        
                                                        {index > 0 && (
                                                            <Button className="boton-arriba blue-outline-button mb-2" onClick={() => confirmMoveModule(index, index - 1)}>
                                                                <FaArrowUp />
                                                            </Button>
                                                        )}
                                                        {index < modulosOrdenados.length - 1 && (
                                                            <Button className="boton-abajo green-outline-button" onClick={() => confirmMoveModule(index, index + 1)}>
                                                                <FaArrowDown />
                                                            </Button>
                                                        )}
                                                    </div>
                                                ) : null}&nbsp;&nbsp;&nbsp;&nbsp;*/}
                                                <span><h4 className="naranjah4 truncate-text-listar-modulo-modulo">{modulo.nombre_modulo}</h4></span>&nbsp;&nbsp;
                                                <div className="d-flex align-items-center justify-content-end texto-derecha ml-auto">
                                                    {usuarioId === 4 || usuarioId === 5 ? (
                                                        <div className="d-flex align-items-center">
                                                            <Button className="yellow-outline-button" onClick={() => handlemostrarUpdate(modulo.idModulos)}>
                                                                <FaPencilAlt color="#F47735" />
                                                            </Button>
                                                            <Button className="red-outline-button me-2" onClick={() => EliminandoContenido(modulo.idModulos)}>
                                                                <FaTrash color="red" />
                                                            </Button>
                                                        </div>
                                                    ) : null}&nbsp;&nbsp;
                                                    <Button className="marronbu direction-button" onClick={() => handleLeerContenido(modulo.idModulos)}>
                                                        Leer Contenido <FaBookOpen className="fa-icon" />
                                                    </Button>
                                                </div>

                                            </div>
                                            <hr className='lineamodulo' />
                                        </Fragment>
                                    );
                                }) : nombremodulos.length > 0 ?
                                    nombremodulos.map((modulos, index) => {
                                        // Aquí va el código para renderizar nomtarea en su orden original...
                                        return (
                                            <Fragment key={index}>
                                                <hr className='lineamodulo' />
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {/*BOTONES DE REORDENAMIENTO2/}
                                                    {/*{usuarioId === 4 || usuarioId === 5 ? (
                                                        <div className="d-flex align-items-center" style={{ flexDirection: 'column' }}>
                                                            
                                                            {index > 0 && (
                                                                <Button className="boton-arriba blue-outline-button mb-2" onClick={() => confirmMoveModule(index, index - 1)}>
                                                                    <FaArrowUp />
                                                                </Button>
                                                            )}
                                                            {index < nombremodulos.length - 1 && (
                                                                <Button className="boton-abajo green-outline-button" onClick={() => confirmMoveModule(index, index + 1)}>
                                                                    <FaArrowDown />
                                                                </Button>
                                                            )}
                                                        </div>
                                                    ) : null}&nbsp;&nbsp;*/}
                                                    <h4 className="naranjah4 cambio-truncate-moviles me-2">{modulos.nombre_modulo}</h4>
                                                    <div className="d-flex align-items-center justify-content-end texto-derecha ml-auto">
                                                        {usuarioId === 4 || usuarioId === 5 ? (
                                                            <div className="d-flex align-items-center">
                                                                <Button className="yellow-outline-button" onClick={() => handlemostrarUpdate(modulos.idModulos)}>
                                                                    <FaPencilAlt color="#F47735" />
                                                                </Button>
                                                                <Button className="red-outline-button me-2" onClick={() => EliminandoContenido(modulos.idModulos)}>
                                                                    <FaTrash color="red" />
                                                                </Button>
                                                            </div>
                                                        ) : null}&nbsp;&nbsp;
                                                        <Button className="marronbu direction-button" onClick={() => handleLeerContenido(modulos.idModulos)}>
                                                            Leer Contenido <FaBookOpen className="fa-icon" />
                                                        </Button>
                                                    </div>
                                                </div>
                                                <hr className='lineamodulo' />
                                            </Fragment>
                                        );
                                    })
                                    :
                                    <h3 className="nohaycursos">No hay Modulos registrados.</h3>
                                }
                            </div>
                        </Collapse>
                    </div>
                )}
            </div>
        </Fragment>
    );

}

export default CursoContenido;
