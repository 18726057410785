import React, { Fragment, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { FaChevronRight, FaClipboard, FaThList } from 'react-icons/fa';
import { withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import { Container, Form, Button, Alert, Breadcrumb, Card } from 'react-bootstrap';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"
import TareaRespuestas from "../SubCursos/TareaRespuestas.js";


function CursoRenderContenido(props) {
    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idCurso } = props;
    const { idTarea } = props;
    const { idUser } = props;
    const { idModulo } = props;
    const [nombreClase, setNombreClase] = useState('');
    const [contenido, setContenido] = useState([]);
    const [nombretareas, setNombreTareas] = useState([]);

    const [cursos, setCursos] = useState([]);
    const [idUsuario, setIdUsuario] = useState(null);
    const [mostrartareasnotas, setmostrartareasnotas] = useState(false);

    const cookies = new Cookies();

    // En el componente CursoRenderContenido
    useEffect(() => {
        if (idModulo) {
            fetchContenido(idModulo);
        }
    }, [idModulo]);


    const fetchContenido = async (idModulo) => {
        try {
            const token = cookies.get('token');
            const requestBody = { idModulos: idModulo };
            const result = await axios.post(baseUrl + "Paginas_contenido/BuscarPaginas_contenidoModulo", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setContenido(result.data.contenido);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchCursos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (result.status === 200) {
                // Suponiendo que tienes un idModulo específico para buscar
                const idModuloEspecifico = idModulo;
                const moduloEncontrado = result.data.find(modulo => modulo.idModulos === idModuloEspecifico);

                if (moduloEncontrado) {
                    // Si se encuentra un módulo con el idModulo específico, almacena su nombre
                    setCursos(moduloEncontrado.nombre_modulo);
                } else {
                    // Manejar el caso en que no se encuentre un módulo con el idModulo específico
                    console.log("Módulo no encontrado.");
                }
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };


    const usuarioId = parseInt(idUser, 10);

    const handleMostrarRespuestas = () => {
        setmostrartareasnotas(true);
    };

    useEffect(() => {
        const fetchNombreCurso = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idCurso: idCurso };
                const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    setNombreClase(result.data);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };
        fetchCursos();
        fetchNombreCurso();
    }, []);

    useEffect(() => {
        const token = cookies.get('token');
        if (token) {
            const decodedToken = jwt_decode(token);
            const idUsuarioFromToken = decodedToken.nameid;
            // Set the user ID using the correct setter function
            setIdUsuario(idUsuarioFromToken);
        } else {
            console.log('No token found!');
        }
    }, []);

    const esContenidoVacio = (htmlContent) => {
        if (!htmlContent) return true; // Verifica si es null o vacío

        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlContent;
        return !tempDiv.textContent.trim(); // Verifica si solo contiene espacios en blanco o etiquetas vacías
    };

    return (
        <div>
            <Fragment>
                <div className='container'>
                    <Container className="my-5 p-3 bg-light border rounded">
                        <hr />
                        <div className="text-center my-4">
                        <h1 className="curso-tarea-envio-title" style={{ textTransform: 'uppercase' }}>Contenido: {cursos || 'Cargando...'}</h1>
                            <hr />
                            {esContenidoVacio(contenido) ? (
                                <h3 className="nohayforos">No hay contenido para este módulo, por favor ingrese datos al contenido.</h3>
                            ) : (
                                <div className="curso-tarea-envio-description" dangerouslySetInnerHTML={{ __html: contenido }}></div>
                            )}
                            <hr />
                        </div>
                    </Container>
                </div>
            </Fragment>
        </div>
    );
}

export default withRouter(CursoRenderContenido);