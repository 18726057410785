import React, { Fragment, useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import * as QuillTableUI from 'quill-table-ui'
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function ForoUpdateHTML(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idModulos } = props;
    const { idCurso } = props;
    const { idForo } = props;
    const cookies = new Cookies();
    const [cursos, setCursos] = useState([]);
    const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const [editorContent, setEditorContent] = useState(''); // Content in rich text format
    const [htmlCode, setHtmlCode] = useState(''); // HTML code content
    const [showHtmlModal, setShowHtmlModal] = useState(false); // To control the HTML modal    
    const [tareas, setForos] = useState([]);
    const [soloForo, setsoloForo] = useState(true);
    const [idModulosOptions, setIdModulosOptions] = useState([]);
    const [foroHtmlContent, setForoHtmlContent] = useState('');


    // Cerrar sesión


    const resetForms = () => {
        setEditorHTML({
            contenido: '',
        });
    };

    //--------------------------------------------------------------------------------------------------------------   
    const [nuevoForo, setNuevoForo] = useState({
        titulo: '',
        descripcion: '',
        fecha_inicio: '',
        fecha_fin: '',
        estado: false,
        idModulos: '',
    });

    // Capturando datos para el nuevoForo.
    const ModuloForo = {
        titulo: nuevoForo.titulo,
        descripcion: nuevoForo.descripcion,
        fecha_inicio: nuevoForo.fecha_inicio,
        fecha_fin: nuevoForo.fecha_fin,
        estado: nuevoForo.estado,
        idModulos: parseInt(nuevoForo.idModulos),
    };
    const resetForm = () => {
        setNuevoForo({
            titulo: '',
            descripcion: '',
            fecha_inicio: '',
            fecha_fin: '',
            estado: false,
            idModulos: '',
        });
        // Restablecer otras variables relacionadas si las hay
    };
    // Popup Editar
    const [editarForo, seteditarForo] = useState(false);

    const abrireditarForo = (foro) => {
        seteditarForo(true);

        // Asignar los valores del foro seleccionado al objeto nuevoForo
        setNuevoForo({
            idForo: foro.idForo,
            titulo: foro.titulo,
            descripcion: foro.descripcion,
            fecha_inicio: foro.fecha_inicio,
            fecha_fin: foro.fecha_fin,
            estado: foro.estado,
            idModulos: foro.idModulos
        });
    };

    // Agregar nuevo Modulo:
    const handleChange = (event) => {
        const { name, value } = event.target;
        // Convertir el valor a entero solo si el campo es 'idModulos'
        const newValue = name === 'idModulos' ? parseInt(value) : value;

        setNuevoForo((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    const fetchForos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "Foros/listarForosMenu", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setForos(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const LeerForoEditar = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idForo: idForo };
            const result = await axios.post(baseUrl + "Foros/buscarForoSolo", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Assuming result.data contains all the fields for the form
                const foroData = result.data;
                setNuevoForo({
                    titulo: foroData.titulo,
                    descripcion: foroData.descripcion, // This will be set in the Quill editor separately
                    fecha_inicio: foroData.fecha_inicio.split('T')[0], // Assuming the date comes in ISO format
                    estado: foroData.estado,
                    fecha_fin: foroData.fecha_fin.split('T')[0], // Assuming the date comes in ISO format
                    idModulos: foroData.idModulos.toString(), // Convert to string if it's a number
                });
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const EditarForo = async (event) => {
        event.preventDefault(); // Cancelar el evento por defecto

        // Convert idModulos to an integer if it's a string
        const updatedForo = {
            ...nuevoForo,
            idModulos: parseInt(nuevoForo.idModulos),
            idForo: idForo
        };

        try {
            // EDITAR TAREA
            //
            // Establecer los valores del foro seleccionado al formulario popup
            //
            // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
            const token = cookies.get('token');
            const response = await axios.post(
                baseUrl + 'Foros/editarForo',
                updatedForo,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // Realizar alguna acción después de registrar el foro exitosamente
            // Por ejemplo, cerrar el popup y volver a cargar la lista de tareas
            if (response.status === 201 || response.status === 200) {
                let timerInterval;
                Swal.fire({
                    icon: 'success',
                    title: 'Cambio exitoso',
                    html: 'Foro Actualizado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                    timer: 5000, // countdown duration in milliseconds
                    timerProgressBar: true,
                    didOpen: () => {
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer();
                            const b = content ? content.querySelector('b') : null;
                            if (b) {
                                // Update the text to show the countdown in seconds
                                b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                            }
                        }, 100); // Update every 100 milliseconds
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result) => {
                    // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('I was closed by the timer');
                    }
                    window.location.href = '/menu';
                });
                fetchForos();
                resetForm();
            }

        } catch (error) {
            // MANEJO DE ERRORES
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };

    //--------------------------------------------------------------------------------------------------------------

    const [nuevoHTML, setEditorHTML] = useState({
        contenido: '', // Initialize contenido as an empty string
        idModulos: idModulos,
    });

    const handleSubmitContent = () => {
        const token = cookies.get('token'); // Get the authorization token from cookies
        // Update nuevoHTML with the content from quill
        nuevoHTML.contenido = editorContent;

        // Make the API call with nuevoHTML as the content
        axios.post(baseUrl + "Paginas_contenido/registrarPaginas_contenido", nuevoHTML, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Include the token
            }
        })
            .then((response) => {
                // Handle the success
                console.log("ESTAMOS ENVIANDO:");
                console.log("CONTENIDO ENVIADO CORRECTAMENTE");
            })
            .catch((error) => {
                // Handle errors
                console.log("ESTAMOS ENVIANDO:");
                console.error("ERROR ENVIANDO DATOS:", error);
            });
    };

    // Popup Editar
    const [editarCurso, setEditarCurso] = useState(false);

    const abrirEditarCurso = (editorhtml) => {
        setEditarCurso(true);

        // Asignar los valores del editorhtml seleccionado al objeto nuevoHTML
        setEditorHTML({
            contenido: editorhtml.contenido,
            idModulos: editorhtml.idModulos,
        });
    };

    const fetchIdModulos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Extraer los idModulos de la respuesta y almacenarlos en el estado
                setIdModulosOptions(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchCursos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idForo: idForo };
            const result = await axios.post(baseUrl + "Foros/buscarForoSolo", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setCursos(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    useEffect(() => {
        const fetchForoHtmlContent = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = {
                    idForo: idForo, // Make sure to pass the correct ID for the tarea
                };
                const result = await axios.post(baseUrl + "Foros/buscarForoSolo", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (result.status === 200 && result.data.descripcion) {
                    setForoHtmlContent(result.data.descripcion); // Set the HTML content in the state
                } else {
                    // Handle non-successful status or no HTML content found
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchForoHtmlContent();
    }, [idForo]); // Dependency array to ensure the content is fetched when idTarea changes


    useEffect(() => {
        fetchIdModulos();
        fetchCursos();



    }, []);
    // 

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevoHTML.contenido) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevoHTML.contenido]);

    // Ref para el editor Quill
    const quillRef = useRef(null);
    const [quillInstance, setQuillInstance] = useState(null);

    const handleCloseHtmlModal = () => {
        setShowHtmlModal(false);
    };

    const handleShowHtmlModal = () => {
        setHtmlCode(editorContent);
        setShowHtmlModal(true);
    };

    const updateQuillContent = (html) => {
        if (quillInstance) {
            quillInstance.root.innerHTML = html;
            setEditorContent(html);
        }
    };

    const handleSaveHtmlChanges = () => {
        setEditorContent(htmlCode); // Update the content immediately in the rich text editor
        handleCloseHtmlModal();
    };


    useEffect(() => {

        // Inicializa Quill dentro de useEffect para asegurarnos de que el DOM esté listo
        if (!quillInstance && quillRef.current) {
            const Font = Quill.import('formats/font');
            Font.whitelist = ['serif', 'sans-serif', 'monospace', 'times-new-roman', 'arial', 'tahoma', 'helvetica'];
            Quill.register(Font, true);
            Quill.register({
                'modules/tableUI': QuillTableUI.default
            }, true)

            const toolbarOptions = [
                ["bold", "italic", "underline", "strike"],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "+1" }, { indent: "-1" }],
                [{ align: [] }],
                [{ size: ["small", "large", "huge", false] }],
                ["image", "link", "video", "formula"],
                [{ color: [] }, { background: [] }],
                [{ font: ["arial", "helvetica", "serif", "sans-serif", "monospace", "times-new-roman", "tahoma"] }],
                ["code-block", "blockquote"]
            ];

            const quill = new Quill(quillRef.current, {
                modules: {
                    toolbar: toolbarOptions,
                    table: true,
                    tableUI: true,
                },
                theme: 'snow',
            });

            setQuillInstance(quill);
        }
    }, [quillInstance]);

    // New useEffect to update the Quill editor content when tareaHtmlContent changes
    useEffect(() => {
        if (quillInstance && foroHtmlContent) {
            quillInstance.root.innerHTML = foroHtmlContent;
        }
    }, [quillInstance, foroHtmlContent]);

    const createTable = () => {
        if (quillInstance) {
            const table = quillInstance.getModule('table');
            if (table) {
                table.insertTable(2, 2); // You can specify the number of rows and columns
            }
        }
    };

    useEffect(() => {
        if (quillInstance) {
            quillInstance.on("text-change", () => {
                const html = quillInstance.root.innerHTML;
                setEditorContent(html);
                // Update nuevoForo.descripcion with the editor content
                setNuevoForo((prevState) => ({
                    ...prevState,
                    descripcion: html,
                }));
            });
        }
    }, [quillInstance]);

    const saveForoContent = () => {
        const updatedHtmlContent = quillInstance.root.innerHTML;
        // ...existing code to send updated content to server...
    };


    useEffect(() => {

        LeerForoEditar();
    }, []);

    return (
        <Fragment>
            <Container className="my-5 p-3 bg-light border rounded">
                <div style={{ backgroundColor: 'white' }}>
                    <div className="col-12">
                        <h1>Editando Datos con Editor html</h1>
                    </div>
                    <form onSubmit={EditarForo}>
                        <div className="input-group">
                            <span>Titulo del foro: </span>
                            <input
                                id="titulo"
                                type="text"
                                className="popup-input"
                                placeholder="Escribe el nombre del Foro"
                                name="titulo"
                                maxLength={100}
                                value={nuevoForo.titulo}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <span>Fecha de Inicio:</span>
                            <input
                                id="fecha_inicio"
                                type="date"
                                className="popup-input"
                                placeholder="Fecha de inicio"
                                name="fecha_inicio"
                                value={nuevoForo.fecha_inicio}
                                onChange={handleChange}
                            />
                        </div>
                        <hr />
                        <div className="input-group">
                            <span>Fecha Fin:</span>
                            <input
                                id="fecha_fin"
                                type="date"
                                className="popup-input"
                                placeholder="Fecha cuando termina"
                                name="fecha_fin"
                                value={nuevoForo.fecha_fin}
                                onChange={handleChange}
                            />
                        </div>
                        <hr />
                        <div className="input-group">
                            <span>Seleccionar Modulo:</span>
                            <select
                                id="idModulos"
                                className="popup-input"
                                name="idModulos"
                                value={nuevoForo.idModulos}
                                onChange={handleChange}
                                required
                            >
                                {/* Opción predeterminada del combobox */}
                                <option value="">Seleccionar Id Modulos</option>
                                {/* Mapear los idModulos disponibles en el estado */}
                                {idModulosOptions.map((idModulo) => (
                                    <option key={idModulo.idModulos} value={idModulo.idModulos}>
                                        {idModulo.nombre_modulo}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group">
                            <span>Estado:</span>
                            <label className="switch">
                                <input
                                    id="estado"
                                    type="checkbox"
                                    name="estado"
                                    checked={nuevoForo.estado}
                                    onChange={() =>
                                        setNuevoForo((prevState) => ({
                                            ...prevState,
                                            estado: !prevState.estado,
                                        }))
                                    }
                                />
                                <span className="slider round">
                                </span>
                            </label>
                        </div>
                        <br />
                        <div>
                            <Button variant="info" onClick={handleShowHtmlModal}>
                                Cambiar vista a HTML
                            </Button>
                            {/* <Button variant="warning" onClick={createTable}>Crear tabla</Button> */}
                        </div>
                        <br></br>
                        <div className="align-items-center justify-content-between mb-3">
                            <div id="editando" ref={quillRef}>
                                <p><br /></p>
                            </div>
                            <br />
                        </div>
                        <hr style={{ marginTop: '1px' }} />
                        <div className="input-group">
                            <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                                {editarForo ? "Guardar cambios" : "Crear nuevo foro"}
                            </button>
                        </div>
                    </form>
                    <Modal show={showHtmlModal} onHide={handleCloseHtmlModal} size="lg"> {/* Set the size to large */}
                        <Modal.Header className="centered-title" closeButton>
                            <Modal.Title>Editor HTML</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <textarea
                                rows="10"
                                value={htmlCode}
                                onChange={(e) => setHtmlCode(e.target.value)}
                                className="custom-textarea"
                                spellCheck="false"
                            />
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <Button variant="danger" onClick={handleCloseHtmlModal}>
                                Cerrar
                            </Button>
                            <Button variant="success" onClick={() => {
                                updateQuillContent(htmlCode);
                                handleSaveHtmlChanges();
                            }}>
                                Guardar cambios
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </Container>
        </Fragment>
    );
}

export default withRouter(ForoUpdateHTML);