import React, { Fragment, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Container, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router-dom';
import { FaPlus, FaMinus, FaPencilAlt, FaTrash, FaSearch, FaWindowClose } from 'react-icons/fa';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../config";

import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function AdminRoles(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    // 
    // Cerrar sesión

    const [roles, setRoles] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    const cookies = new Cookies();

    const fetchRoles = async () => {
        const token = cookies.get('token');
        const result = await axios.get(baseUrl + "roles", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        setRoles(result.data);
    };

    useEffect(() => {
        fetchRoles();





    }, []);

    //const togglePopup = () => {
    //    setShowPopup(!showPopup);
    //    setEditarRol(false);
    //    form.nombre_rol = '';
    //};

    const [rolSeleccionado, setRolSeleccionado] = useState(null);

    const eliminarRol = async (idRol) => {
        Swal.fire({
            title: '¿Estás seguro de que deseas ELIMINAR este rol?',
            text: 'El rol no se podrá recuperar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const request = {
                        idRol: idRol
                    };
                    const token = cookies.get('token');
                    const response2 = await axios.post(baseUrl + 'roles/eliminarRol', request, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    // Mostrar Sweet Alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Rol eliminado correctamente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    fetchRoles();

                } catch (error) {
                    console.error(error);
                    // Manejar el error de la solicitud al backend
                }
            } else {
                setRolSeleccionado(null); // Restablecer a null
            }
        });
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    }

    const [form, setForm] = useState({
        idRol: '',
        nombre_rol: '',
        estado: true,
    });

    const nuevoRol = {
        nombre_rol: form.nombre_rol,
        estado: form.estado,
    };

    const roleditado = {
        idRol: parseInt(form.idRol),
        nombre_rol: form.nombre_rol,
        estado: form.estado,
    };

    const agregarRol = async () => {
        try {
            if (editarRol) {
                // EDITAR ROL
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (!roleditado.nombre_rol) {
                    throw new Error('Por favor, complete todos los campos.');
                }

                const token = cookies.get('token');
                const response = await axios.post(
                    baseUrl + 'roles/editarRol',
                    roleditado,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Realizar alguna acción después de registrar el rol exitosamente
                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Actualización exitosa',
                        text: 'Rol actualizado correctamente!',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    cerrarPopup();
                    fetchRoles();
                }
            } else {
                // AGREGAR CURSO
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (!nuevoRol.nombre_rol) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                const response = await axios.post(
                    baseUrl + 'roles/agregarRol',
                    nuevoRol,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Realizar alguna acción después de registrar el rol exitosamente
                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Registro exitoso',
                        text: 'Rol registrado correctamente!',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    cerrarPopup();
                    fetchRoles();
                }
            }
        }
        catch (error) {
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else if (error.response && error.response.status === 400) {
                if (error.response.data.mensaje === 'Ya existe un rol con ese nombre!.') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Rol existente',
                        text: error.response.data.mensaje,
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo sentimos!',
                        text: error.response.data,
                    });
                }
            } else if (error.response && error.response.status === 409) {
                Swal.fire({
                    icon: 'error',
                    title: 'El rol ya existe',
                    text: error.response.data.message,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };

    // Popup Editar
    const [editarRol, setEditarRol] = useState(false);

    const abrirEditarRol = (id, nombre, estado) => {
        // funciones del popup
        setEditarRol(true);
        setShowPopup(true);

        //rellenar el form
        form.idRol = id;
        form.nombre_rol = nombre;
        form.estado = estado;
    };

    const cerrarPopup = () => {
        setShowPopup(false);
        setEditarRol(false);
        form.nombre_rol = '';
    };

    const abrirPopup = () => {
        form.nombre_rol = '';
        setEditarRol(false);
        setShowPopup(true);
    };

    // Paginación y limitación de mapeo de roles

    const [currentPage, setCurrentPage] = useState(1);
    const coursesPerPage = 9; // Cantidad de roles por página

    // Función para ir a la página anterior
    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Función para ir a la página siguiente
    const nextPage = () => {
        const totalPages = Math.ceil(roles.length / coursesPerPage);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const indexOfLastCourse = currentPage * coursesPerPage;
    const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
    const currentCourses = roles.slice(indexOfFirstCourse, indexOfLastCourse);

    // Generar los botones de paginación
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(roles.length / coursesPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <Fragment>
            <Container style={{ marginTop: '17px' }}>
                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-3">
                    <div className="col-md-9 mb-2 mb-md-0">
                        <h1 style={{ marginBottom: '-10px' }}>Lista de Roles</h1>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center align-items-center">
                        <Button variant="success custom-orange-btn" onClick={abrirPopup}>
                            <FaPlus />Agregar nuevo rol
                        </Button>
                    </div>
                </div>

                {currentCourses.length > 0 ? (
                    <Table hover className="custom-table">
                        <thead>
                            <tr>
                                <th>NOMBRE DEL ROL</th>
                                <th>ESTADO</th>
                                <th>ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentCourses.map((roles) => (
                                <tr key={roles.idRol}>
                                    <td>{roles.nombre_rol}</td>
                                    <td>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: roles.estado ? '#D55613' : '',
                                                borderRadius: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid #D55613',
                                            }}
                                        >
                                            <span style={{ color: roles.estado ? 'white' : 'black' }}>
                                                {roles.estado ? "Habilitado" : "Inhabilitado"}
                                            </span>
                                        </div></td>
                                    <td>
                                        <Button variant="warning" onClick={() => abrirEditarRol(roles.idRol, roles.nombre_rol, roles.estado)}>
                                            <FaPencilAlt />
                                        </Button>{' '}
                                        <Button variant="danger" onClick={() => eliminarRol(roles.idRol)}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <h3 className="nohaycursoslista">No hay roles para mostrar</h3>
                )}

                {/* Paginación */}
                <nav aria-label="Page navigation example" className="fixed-pagination">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {pageNumbers.map((number) => (
                            <li key={number} className={`page-item ${currentPage === number ? "active" : ""}`}>
                                <a className="page-link" href="#" onClick={() => setCurrentPage(number)}>
                                    {number}
                                </a>
                            </li>
                        ))}
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </Container>
            <CSSTransition
                in={showPopup}
                timeout={300}
                classNames="popup-transition"
                unmountOnExit
            >
                <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} onClose={cerrarPopup}
                >
                    <div className="popup-contenta">
                        <div className="popup-header">
                            {editarRol ? "Editar Rol" : "Agregar nuevo Rol"}
                            <div className="popup-close-button">
                                <FaWindowClose color="#c82333" className="invert-icon" onClick={cerrarPopup} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="popup-message">
                            Porfavor ingrese el nombre del nuevo rol:
                        </div>
                        <br></br>
                        <form>
                            <div className="input-group">
                                <span>Nombre del rol: </span>
                                <input
                                    id="nombre_rol"
                                    type="text"
                                    maxLength={50}
                                    className="popup-inputrs"
                                    placeholder="Ingresa el nombre del nuevo Rol"
                                    name="nombre_rol"
                                    value={form.nombre_rol}
                                    onChange={handleChange}
                                />
                            </div>
                            {editarRol ?
                                <div className="input-group">
                                    <span>Estado:</span>
                                    <label className="switch" style={{ marginTop: '5px', marginBottom: '0px', marginLeft: '5px' }}>
                                        <input
                                            id="estado"
                                            type="checkbox"
                                            name="estado"
                                            checked={form.estado}
                                            value={form.estado}
                                            onChange={() =>
                                                setForm((prevState) => ({
                                                    ...prevState,
                                                    estado: !prevState.estado,
                                                }))
                                            }
                                        />
                                        <span className="slider round">
                                        </span>
                                    </label>
                                </div>
                                : <hr></hr>
                            }
                            <div className="popup-message">
                            </div>
                            <hr></hr>
                            <button className="btn btn-block mybtn btn-success tx-tfm naranjabtn" type="button" onClick={agregarRol}>
                                {editarRol ? "Guardar cambios" : "Agregar nuevo rol"}
                            </button>
                        </form>
                    </div>
                </Popup>
            </CSSTransition>
        </Fragment>
    );
}

export default withRouter(AdminRoles);