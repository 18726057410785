import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withRouter } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { FaPlus, FaMinus, FaPencilAlt, FaTrash, FaWindowClose } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Modal } from 'react-bootstrap';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import * as QuillTableUI from 'quill-table-ui'
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function CursoTareaCrud(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idCurso } = props;
    const cookies = new Cookies();
    // Estado para almacenar los idModulos disponibles
    const [idModulosOptions, setIdModulosOptions] = useState([]);
    const [tareas, setCursos] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
    const [checkboxesSeleccionados, setCheckboxesSeleccionados] = useState({});
    const [nuevoCursoDocente, setNuevoCursoDocente] = useState([]);
    const [nuevoCursoEstudiante, setNuevoCursoEstudiante] = useState([]);
    const [soloCurso, setSoloCurso] = useState(true);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const quillRef = useRef(null);// Ref para el editor Quill
    const [quillInstance, setQuillInstance] = useState(null);//------------  
    const [showModal, setShowModal] = useState(false);
    const [showHtmlModal, setShowHtmlModal] = useState(false); // To control the HTML modal   
    const [htmlCode, setHtmlCode] = useState(''); // HTML code content  
    const [openHtmlModal, setOpenHtmlModal] = useState(false);
    const [editorContent, setEditorContent] = useState(''); // Content in rich text format

    const [nuevaTarea, setNuevaTarea] = useState({
        titulo: '',
        descripcion: '',
        fecha_entrega: '',
        nota: '',
        estado: false,
        idModulos: '',
    });

    // Capturando datos para el nuevaTarea.
    const ModuloTarea = {
        titulo: nuevaTarea.titulo,
        descripcion: nuevaTarea.descripcion,
        estado: nuevaTarea.estado,
        nota: parseInt(nuevaTarea.nota),
        fecha_entrega: nuevaTarea.fecha_entrega,
        idModulos: parseInt(nuevaTarea.idModulos),
    };
    const resetForm = () => {
        setNuevaTarea({
            titulo: '',
            descripcion: '',
            fecha_entrega: '',
            nota: '',
            estado: false,
        });
    };



    // Cerrar sesión


    const fetchCursos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "tareas/listarTareasMenu", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setCursos(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    // Agregar nuevo Modulo:
    const handleChange = (event) => {
        const { name, value } = event.target;

        // Convertir el valor a entero solo si el campo es 'idModulos' o 'nota'
        const newValue = name === 'idModulos' || name === 'nota' ? parseInt(value) : value;

        setNuevaTarea((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };



    const agregarTarea = async (event) => {
        event.preventDefault(); // Cancelar el evento por defecto
        const tareaData = {
            ...nuevaTarea,
            descripcion: htmlCode // Aquí usas la variable de estado que contiene el HTML actualizado
        };
        try {
            if (editarTarea) {
                // EDITAR TAREA
                //
                // Establecer los valores del tarea seleccionado al formulario popup
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevaTarea.titulo
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }

                // Verificar si la propiedad hora_inicio está definida, de lo contrario asignar "00:00:00"
                if (!nuevaTarea.hora_inicio) {
                    nuevaTarea.hora_inicio = "00:00:00";
                }

                // Verificar si la propiedad hora_fin está definida, de lo contrario asignar "00:00:00"
                if (!nuevaTarea.hora_fin) {
                    nuevaTarea.hora_fin = "00:00:00";
                }

                const token = cookies.get('token');
                const response = await axios.post(
                    baseUrl + 'tareas/editarTarea',
                    tareaData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Realizar alguna acción después de registrar el tarea exitosamente
                // Por ejemplo, cerrar el popup y volver a cargar la lista de tareas
                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Cambio exitoso',
                        text: 'Modulo actualizado correctamente!',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    closePopup();
                    fetchCursos();
                    resetForm();
                }
            } else {
                // AGREGAR TAREA
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO


                if (
                    !nuevaTarea.titulo ||
                    !nuevaTarea.nota ||
                    !nuevaTarea.idModulos
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }

                const ModulonewTarea = {
                    ...nuevaTarea,
                    descripcion: htmlCode // Aquí usas la variable de estado que contiene el HTML actualizado
                };
                const token = cookies.get('token');
                if (soloCurso) {

                    // Llamar a la API para agregar un tarea, docentes y estudiantes
                    const response = await axios.post(
                        baseUrl + 'tareas/registrarTarea',
                        ModulonewTarea,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el tarea exitosamente
                    // cerrar el popup y volver a cargar la lista de tareas
                    if (response.status === 201) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            text: 'Tarea creado correctamente!',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                        closePopup();
                        fetchCursos();
                        resetForm();
                    }
                }
            }
        } catch (error) {
            // MANEJO DE ERRORES
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };

    const eliminarTarea = async (idTarea) => {
        Swal.fire({
            title: '¿Estás seguro de que deseas ELIMINAR este modulo?',
            text: 'El modulo y su contenido no se podrán recuperar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const request = {
                        idTarea: idTarea
                    };
                    const token = cookies.get('token');
                    const response2 = await axios.post(baseUrl + 'tareas/eliminarTarea', request, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    // Mostrar Sweet Alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Tarea eliminado correctamente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    fetchCursos();

                } catch (error) {
                    console.error(error);
                    // Manejar el error de la solicitud al backend
                }
            } else {
                setCursoSeleccionado(null); // Restablecer a null
            }
        });
    };

    //

    // Popup Editar
    const [editarTarea, setEditarTarea] = useState(false);

    const abrirEditarTarea = (tarea) => {
        setEditarTarea(true);
        setShowPopup(true);

        const formattedFechaRegistro = tarea.fecha_entrega.split('T')[0]; // If it's in ISO format

        // Asignar los valores del tarea seleccionado al objeto nuevaTarea
        setNuevaTarea({
            idTarea: tarea.idTarea,
            titulo: tarea.titulo,
            descripcion: tarea.descripcion,
            nota: parseInt(tarea.nota),
            fecha_entrega: tarea.fecha_entrega,
            idModulos: tarea.idModulos,
            estado: tarea.estado
        });
    };

    const fetchIdModulos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Extraer los idModulos de la respuesta y almacenarlos en el estado
                setIdModulosOptions(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    useEffect(() => {
        fetchIdModulos();
        fetchCursos();

    }, [nuevaTarea]);
    // 

    const setOptionColor = (optionValue) => {
        if (optionValue >= 0 && optionValue <= 12) {
            return 'option-red';
        } else if (optionValue >= 13 && optionValue <= 20) {
            return 'option-green';
        }
    };

    // Paginación y limitación de mapeo de cursos
    const [currentPageCursos, setCurrentPageCursos] = useState(1);
    const coursesPorPage = 4; // Cantidad de cursos por página

    // Función para ir a la página anterior
    const previousPage = () => {
        if (currentPageCursos > 1) {
            setCurrentPageCursos(currentPageCursos - 1);
        }
    };

    // Función para ir a la página siguiente
    const nextPage = () => {
        const totalPages = Math.ceil(tareas.length / coursesPorPage);
        if (currentPageCursos < totalPages) {
            setCurrentPageCursos(currentPageCursos + 1);
        }
    };

    const indexOfLastCoursePage = currentPageCursos * coursesPorPage;
    const indexOfFirstCoursePage = indexOfLastCoursePage - coursesPorPage;
    const currentCoursesA = tareas.slice(indexOfFirstCoursePage, indexOfLastCoursePage);
    // Generar los botones de paginación
    const pageNumbersA = [];
    for (let i = 1; i <= Math.ceil(tareas.length / coursesPorPage); i++) {
        pageNumbersA.push(i);
    }


    const updateQuillContent = (html) => {
        if (quillInstanceRef.current) {
            quillInstanceRef.current.clipboard.dangerouslyPasteHTML(html);
        }
    };

    const handleSaveHtmlChanges = () => {
        if (quillInstanceRef.current) {
            const html = quillInstanceRef.current.root.innerHTML;
            setEditorContent(html); // Actualiza el estado con el contenido actual del editor Quill
            setNuevaTarea(prevState => ({ ...prevState, descripcion: html })); // Asegúrate de que se actualiza el estado de la tarea
        }
        handleCloseHtmlModal();
    };

    const handleCloseHtmlModal = () => {
        setShowHtmlModal(false);
    };
    const handleShowHtmlModal = () => {
        setHtmlCode(htmlCode);
        setShowHtmlModal(true);
    };


    const togglePopup = () => {
        resetForm();
        setEditarTarea(false);
        setShowPopup(true);
        return true;
    };

    const closePopup = () => {
        setEditarTarea(false);
        setShowPopup(false);
        return true;
    };

    // Utiliza useRef para mantener una referencia consistente a la instancia de Quill
    const quillInstanceRef = useRef(null);


    useEffect(() => {

        let quillInstance;

        if (showPopup && quillRef.current && !quillInstanceRef.current) {
            const Font = Quill.import('formats/font');
            Font.whitelist = ['serif', 'sans-serif', 'monospace', 'times-new-roman', 'arial', 'tahoma', 'helvetica'];
            Quill.register(Font, true);
            Quill.register({
                'modules/tableUI': QuillTableUI.default
            }, true)

            const toolbarOptions = [
                ["bold", "italic", "underline", "strike"],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "+1" }, { indent: "-1" }],
                [{ align: [] }],
                [{ size: ["small", "large", "huge", false] }],
                ["image", "link", "video", "formula"],
                [{ color: [] }, { background: [] }],
                [{ font: ["arial", "helvetica", "serif", "sans-serif", "monospace", "times-new-roman", "tahoma"] }],
                ["code-block", "blockquote"]
            ];

            quillInstanceRef.current = new Quill(quillRef.current, {
                modules: {
                    toolbar: toolbarOptions,
                    table: true,
                    tableUI: true,
                },
                theme: 'snow',


            });

            quillInstanceRef.current.on('text-change', () => {
                const html = quillInstanceRef.current.root.innerHTML;
                setHtmlCode(html); // Asegúrate de que esta función actualiza el estado correctamente
            });

        }
        return () => {
            if (quillInstanceRef.current) {
                quillInstanceRef.current.off('text-change');
                quillInstanceRef.current.disable();
                quillInstanceRef.current = null;
            }
        };
    }, [showPopup]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    };


    useEffect(() => {
        if (showPopup && quillInstanceRef.current) {
            // Actualizar contenido HTML solo cuando showPopup es true y Quill está inicializado
            const currentContent = quillInstanceRef.current.root.innerHTML;
            if (currentContent !== nuevaTarea.descripcion) {
                quillInstanceRef.current.clipboard.dangerouslyPasteHTML(nuevaTarea.descripcion);
                // Opcional: Mover el cursor al final del editor
            }
        }
    }, [showPopup, nuevaTarea.descripcion]);


    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevaTarea.titulo && nuevaTarea.descripcion && nuevaTarea.fecha_entrega && nuevaTarea.nota) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevaTarea.titulo, nuevaTarea.descripcion, nuevaTarea.fecha_entrega, nuevaTarea.nota]);

    return (
        <Fragment>
            <Container>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="col-12">
                        <h1>Lista de Tareas para el curso</h1>
                        <Button variant="success" onClick={togglePopup}>
                            Agregar nueva Tarea <FaPlus />
                        </Button>
                    </div>
                </div>
                <div className="table-responsive">
                {currentCoursesA.length > 0 ? (
                    <Table hover className="custom-table">
                        <thead>
                            <tr>
                                <th>Id Modulos</th>
                                <th>Titulo</th>
                                <th>Descripción</th>
                                <th>Nota</th>
                                <th>Fecha de entrega</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentCoursesA.map((tarea, index) => (
                                <tr key={tarea.idTarea}>
                                    <td className="align-middle">{tarea.idModulos}</td>
                                    <td className="truncate-text-tabla-modulos-tarea-crud align-middle" >{tarea.titulo}</td>
                                    <td className="truncate-text-tabla-modulos-tarea-crud align-middle">{tarea.descripcion}</td>
                                    <td className="align-middle">{tarea.nota}</td>
                                    <td className="align-middle">{formatDate(tarea.fecha_entrega)}</td>
                                    <td style={{ width: '98px', height: '40px' }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: tarea.estado ? '#D55613' : '',
                                                borderRadius: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid #D55613',
                                            }}
                                        >
                                            <span style={{ color: tarea.estado ? 'white' : 'black' }}>
                                                {tarea.estado ? 'Si' : 'No'}
                                            </span>
                                        </div>
                                    </td>

                                    <td>
                                        <Button variant="warning" onClick={() => abrirEditarTarea(tarea)}>
                                            <FaPencilAlt />
                                        </Button>{' '}
                                        <Button variant="danger" onClick={() => eliminarTarea(tarea.idTarea)}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <h3 className="nohaycursoslista">No hay Tareas para mostrar</h3>
                )}
                </div>
                {/* Paginación */}
                <nav aria-label="Page navigation example" className="fixed-pagination2">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {pageNumbersA.map((number) => (
                            <li key={number} className={`page-item ${currentPageCursos === number ? "active" : ""}`}>
                                <a className="page-link" href="#" onClick={() => setCurrentPageCursos(number)}>
                                    {number}
                                </a>
                            </li>
                        ))}
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </Container>
            <CSSTransition
                in={showPopup}
                timeout={300}
                classNames="popup-transition"
                unmountOnExit
            >
                <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} className="popup-contenta-html" contentStyle={{ maxWidth: '1103px' }} >
                    <div className="popup-wrapper tamano-popup">
                        <div className="popup-contenta-html">
                            <div className="popup-header">
                                <div className="popup-title">
                                    {editarTarea ? "Editar datos" : "Registrar datos"}
                                </div>
                                <div className="popup-close-button">
                                    <FaWindowClose color="#c82333" className="invert-icon" onClick={closePopup} style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                            <div className="popup-message">
                                Por favor ingrese los siguientes datos:
                            </div>
                            <hr />
                            <form onSubmit={agregarTarea}>
                                <div className="input-group">
                                    <span>Titulo de la tarea: </span>
                                    <input
                                        id="titulo"
                                        type="text"
                                        className="popup-input"
                                        placeholder="Escribe el nombre del Tarea"
                                        name="titulo"
                                        maxLength={100}
                                        value={nuevaTarea.titulo}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <Button variant="info" style={{ marginRight: '8px' }} onClick={handleShowHtmlModal}>
                                    Cambiar vista a HTML
                                </Button>
                                <div className="input-group">
                                    <span>Descripción: </span>
                                    <div className="align-items-center justify-content-between mb-3">
                                        <div id="editor" ref={quillRef}>
                                            <p><br /></p>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                {/* <div className="input-group">
                                    <span>Descripción de la Tarea: </span>
                                    <textarea
                                        id="descripcion"
                                        type="text"
                                        className="popup-input custom-textareav3"
                                        placeholder="Escribe una descripcion de la Tarea"
                                        name="descripcion"
                                        value={nuevaTarea.descripcion}
                                        onChange={handleChange}
                                        required
                                        rows="6"
                                    ></textarea>
                                </div> */}
                                <div className="input-group">
                                    <span>Nota:</span>
                                    <select
                                        id="nota"
                                        type="text"
                                        className="popup-input"
                                        placeholder="19"
                                        name="nota"
                                        value={nuevaTarea.nota}
                                        onChange={handleChange}
                                        required
                                    >
                                        {Array.from({ length: 21 }, (_, i) => (
                                            <option
                                                key={i}
                                                value={i}
                                                className={setOptionColor(i)}
                                            >
                                                {i.toString().padStart(2, '0')}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <hr />
                                <div className="input-group">
                                    <span>Fecha de entrega:</span>
                                    <input
                                        id="fecha_entrega"
                                        type="date"
                                        className="popup-input"
                                        placeholder="2023-08-21"
                                        name="fecha_entrega"
                                        value={nuevaTarea.fecha_entrega}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <hr />
                                <div className="input-group">
                                    <span>Seleccionar modulo:</span>
                                    <select
                                        id="idModulos"
                                        className="popup-input"
                                        name="idModulos"
                                        value={nuevaTarea.idModulos}
                                        onChange={handleChange}
                                        required
                                    >
                                        {/* Opción predeterminada del combobox */}
                                        <option value="">Seleccionar Modulo</option>
                                        {/* Mapear los idModulos disponibles en el estado */}
                                        {idModulosOptions.map((idModulo) => (
                                            <option key={idModulo.idModulos} value={idModulo.idModulos}>
                                                {idModulo.nombre_modulo}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="input-group" style={{ marginBottom: '12px' }}>
                                    <span>Estado:</span>
                                    <label className="switch" style={{ marginTop: '5px', marginBottom: '0px', marginLeft: '5px' }}>
                                        <input
                                            id="estado"
                                            type="checkbox"
                                            name="estado"
                                            checked={nuevaTarea.estado}
                                            onChange={() =>
                                                setNuevaTarea((prevState) => ({
                                                    ...prevState,
                                                    estado: !prevState.estado,
                                                }))
                                            }
                                        />
                                        <span className="slider round">
                                        </span>
                                    </label>
                                </div>
                                <hr style={{ marginTop: '1px' }} />
                                <div className="input-group">
                                    <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                                        {editarTarea ? "Guardar cambios" : "Crear nueva tarea"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Popup>
            </CSSTransition>
            <Modal show={showHtmlModal} backdrop="static" keyboard={false} onHide={handleCloseHtmlModal} size="lg"> {/* Set the size to large */}
                <Modal.Header className="centered-title" closeButton>
                    <Modal.Title>Editor HTML</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        rows="10"
                        value={htmlCode} // El contenido HTML se muestra en el textarea
                        onChange={(e) => setHtmlCode(e.target.value)}
                        className="custom-textarea"
                        spellCheck="false"
                    />
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="danger" onClick={handleCloseHtmlModal}>
                        Cerrar
                    </Button>
                    <Button variant="success" onClick={() => {
                        updateQuillContent(htmlCode);
                        handleSaveHtmlChanges();
                    }}>
                        Guardar cambios
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default withRouter(CursoTareaCrud);