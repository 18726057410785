import React, { Fragment, useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import * as QuillTableUI from 'quill-table-ui'
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"


function TareaUpdateHTML(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idCurso } = props;
    const { idTarea } = props;
    const { idModulos } = props;
    const cookies = new Cookies();
    const [cursos, setCursos] = useState([]);
    const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const [editorContent, setEditorContent] = useState(''); // Content in rich text format
    const [htmlCode, setHtmlCode] = useState(''); // HTML code content
    const [showHtmlModal, setShowHtmlModal] = useState(false); // To control the HTML modal    
    const [tareas, setForos] = useState([]);
    const [nombreClase, setNombreClase] = useState('');
    const [soloCurso, setSoloCurso] = useState(true);
    const [soloForo, setsoloForo] = useState(true);
    const [idModulosOptions, setIdModulosOptions] = useState([]);
    const [tareaHtmlContent, setTareaHtmlContent] = useState('');
    // Cerrar sesión


    //--------------------------------------------------------------------------------------------------------------   
    const [nuevaTarea, setNuevaTarea] = useState({
        titulo: '',
        descripcion: '',
        fecha_entrega: '',
        nota: '',
        estado: false,
        idModulos: '',
    });

    // Capturando datos para el nuevaTarea.
    const ModuloTarea = {
        titulo: nuevaTarea.titulo,
        descripcion: nuevaTarea.descripcion,
        estado: nuevaTarea.estado,
        nota: parseInt(nuevaTarea.nota),
        fecha_entrega: nuevaTarea.fecha_entrega,
        idModulos: parseInt(nuevaTarea.idModulos),
    };
    const resetForm = () => {
        setNuevaTarea({
            titulo: '',
            descripcion: '',
            fecha_entrega: '',
            nota: '',
            estado: false,
        });
        // Restablecer otras variables relacionadas si las hay
    };

    // Agregar nuevo Modulo:
    const handleChange = (event) => {
        const { name, value } = event.target;
        // Convertir el valor a entero solo si el campo es 'idModulos' o 'nota'
        const newValue = name === 'idModulos' || name === 'nota' ? parseInt(value) : value;

        setNuevaTarea((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    const setOptionColor = (optionValue) => {
        if (optionValue >= 0 && optionValue <= 12) {
            return 'option-red';
        } else if (optionValue >= 13 && optionValue <= 20) {
            return 'option-green';
        }
    };

    const LeerTareaEditar = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idTarea: idTarea };
            const result = await axios.post(baseUrl + "tareas/buscarTareaSola", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Assuming result.data contains all the fields for the form
                const tareaData = result.data;
                setNuevaTarea({
                    titulo: tareaData.titulo,
                    descripcion: tareaData.descripcion, // This will be set in the Quill editor separately
                    nota: tareaData.nota, // Convert to string if it's a number
                    estado: tareaData.estado,
                    fecha_entrega: tareaData.fecha_entrega.split('T')[0], // Assuming the date comes in ISO format
                    idModulos: tareaData.idModulos, // Convert to string if it's a number
                });
            } else {
                // Handle an unsuccessful response if needed.
            }
        } catch (error) {
            console.error(error);
            // Handle errors from the backend request.
        }
    };

    const EditandolaTarea = async (event) => {
        event.preventDefault(); // Prevent the default form submit action

        // Convert idModulos to an integer if it's a string
        const updatedTarea = {
            ...nuevaTarea,
            idModulos: parseInt(nuevaTarea.idModulos),
            idTarea: idTarea
        };
        try {
            // EDITAR TAREA
            const token = cookies.get('token');
            const response = await axios.post(
                baseUrl + 'tareas/editarTarea',
                updatedTarea, // Use the updatedTarea with the converted idModulos
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // Actions to take after successful edit
            if (response.status === 201 || response.status === 200) {

                let timerInterval;
                Swal.fire({
                    icon: 'success',
                    title: 'Cambio exitoso',
                    html: 'Contenido Actualizado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                    timer: 5000, // countdown duration in milliseconds
                    timerProgressBar: true,
                    didOpen: () => {
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer();
                            const b = content ? content.querySelector('b') : null;
                            if (b) {
                                // Update the text to show the countdown in seconds
                                b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                            }
                        }, 100); // Update every 100 milliseconds
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result) => {
                    // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('I was closed by the timer');
                    }
                    window.location.href = '/menu';
                });
                resetForm();
            }
        } catch (error) {
            // Error handling
            Swal.fire({
                icon: 'error',
                title: 'Error al actualizar!',
                text: 'Ha ocurrido un error al intentar actualizar la tarea.',
            });
        }
    };


    // Popup Editar
    const [editarTarea, setEditarTarea] = useState(false);

    const abrirEditarTarea = (tarea) => {
        setEditarTarea(true);

        // Asignar los valores del tarea seleccionado al objeto nuevaTarea
        setNuevaTarea({
            idTarea: tarea.idTarea,
            titulo: tarea.titulo,
            descripcion: tarea.descripcion,
            nota: parseInt(tarea.nota),
            fecha_entrega: tarea.fecha_entrega,
            idModulos: tarea.idModulos,
            estado: tarea.estado
        });
    };




    //--------------------------------------------------------------------------------------------------------------

    const [nuevoHTML, setEditorHTML] = useState({
        contenido: '', // Initialize contenido as an empty string
        idModulos: idModulos,
    });

    const handleSubmitContent = () => {
        const token = cookies.get('token'); // Get the authorization token from cookies
        // Update nuevoHTML with the content from quill
        nuevoHTML.contenido = editorContent;

        // Make the API call with nuevoHTML as the content
        axios.post(baseUrl + "Paginas_contenido/registrarPaginas_contenido", nuevoHTML, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Include the token
            }
        })
            .then((response) => {
                // Handle the success
                console.log("CONTENIDO ENVIADO CORRECTAMENTE");
            })
            .catch((error) => {
                // Handle errors
                console.error("ERROR ENVIANDO DATOS:", error);
            });
    };

    // Popup Editar
    const [editarCurso, setEditarCurso] = useState(false);

    const abrirEditarCurso = (editorhtml) => {
        setEditarCurso(true);

        // Asignar los valores del editorhtml seleccionado al objeto nuevoHTML
        setEditorHTML({
            contenido: editorhtml.contenido,
            idModulos: editorhtml.idModulos,
        });
    };

    const fetchIdModulos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Extraer los idModulos de la respuesta y almacenarlos en el estado
                setIdModulosOptions(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    useEffect(() => {

        LeerTareaEditar();
    }, []);

    useEffect(() => {
        fetchIdModulos();
    }, [nuevaTarea]);
    // 

    useEffect(() => {
        const fetchTareaHtmlContent = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = {
                    idTarea: idTarea, // Make sure to pass the correct ID for the tarea
                };
                const result = await axios.post(baseUrl + "tareas/buscarTareaSola", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (result.status === 200) {
                    setTareaHtmlContent(result.data.descripcion); // Set the HTML content in the state
                } else {
                    // Handle non-successful status or no HTML content found
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchTareaHtmlContent();
    }, [idTarea]); // Dependency array to ensure the content is fetched when idTarea changes


    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevoHTML.contenido) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevoHTML.contenido]);

    // Ref para el editor Quill
    const quillRef = useRef(null);
    const [quillInstance, setQuillInstance] = useState(null);

    const handleCloseHtmlModal = () => {
        setShowHtmlModal(false);
    };

    const handleShowHtmlModal = () => {
        setHtmlCode(editorContent);
        setShowHtmlModal(true);
    };

    const updateQuillContent = (html) => {
        if (quillInstance) {
            quillInstance.root.innerHTML = html;
            setEditorContent(html);
        }
    };

    const handleSaveHtmlChanges = () => {
        setEditorContent(htmlCode); // Update the content immediately in the rich text editor
        handleCloseHtmlModal();
    };


    useEffect(() => {

        // Inicializa Quill dentro de useEffect para asegurarnos de que el DOM esté listo
        if (!quillInstance && quillRef.current) {
            const Font = Quill.import('formats/font');
            Font.whitelist = ['serif', 'sans-serif', 'monospace', 'times-new-roman', 'arial', 'tahoma', 'helvetica'];
            Quill.register(Font, true);
            Quill.register({
                'modules/tableUI': QuillTableUI.default
            }, true)

            const toolbarOptions = [
                ["bold", "italic", "underline", "strike"],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "+1" }, { indent: "-1" }],
                [{ align: [] }],
                [{ size: ["small", "large", "huge", false] }],
                ["image", "link", "video", "formula"],
                [{ color: [] }, { background: [] }],
                [{ font: ["arial", "helvetica", "serif", "sans-serif", "monospace", "times-new-roman", "tahoma"] }],
                ["code-block", "blockquote"]
            ];

            const quill = new Quill(quillRef.current, {
                modules: {
                    toolbar: toolbarOptions,
                    table: true,
                    tableUI: true,
                },
                theme: 'snow',
            });

            setQuillInstance(quill);
        }
    }, [quillInstance, tareaHtmlContent]);

    useEffect(() => {
        if (quillInstance && tareaHtmlContent) {
            quillInstance.root.innerHTML = tareaHtmlContent;
        }
    }, [quillInstance, tareaHtmlContent]);


    const createTable = () => {
        if (quillInstance) {
            const table = quillInstance.getModule('table');
            if (table) {
                table.insertTable(2, 2); // You can specify the number of rows and columns
            }
        }
    };

    const saveTareaContent = () => {
        const updatedHtmlContent = quillInstance.root.innerHTML;
        // ...existing code to send updated content to server...
    };

    useEffect(() => {
        if (quillInstance) {
            quillInstance.on("text-change", () => {
                const html = quillInstance.root.innerHTML;
                setEditorContent(html);
                // Update nuevoForo.descripcion with the editor content
                setNuevaTarea((prevState) => ({
                    ...prevState,
                    descripcion: html,
                }));
            });
        }
    }, [quillInstance]);

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevaTarea.titulo && nuevaTarea.adjunto && nuevaTarea.puntos) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevaTarea.titulo, , nuevaTarea.adjunto, nuevaTarea.puntos]);

    return (
        <Fragment>
            <div>
                <Container className="my-5 p-3 bg-light border rounded">
                    <div className="col-12">
                        <h1>Editando Datos con Editor html</h1>
                    </div>
                    <form onSubmit={EditandolaTarea}>
                        <div className="input-group">
                            <span>Titulo de la tarea: </span>
                            <input
                                id="titulo"
                                type="text"
                                className="popup-input"
                                placeholder="Escribe el nombre del Tarea"
                                name="titulo"
                                maxLength={100}
                                value={nuevaTarea.titulo}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <span>Nota:</span>
                            <select
                                id="nota"
                                type="text"
                                className="popup-input"
                                placeholder="19"
                                name="nota"
                                value={nuevaTarea.nota}
                                onChange={handleChange}
                                required
                            >
                                {Array.from({ length: 21 }, (_, i) => (
                                    <option
                                        key={i}
                                        value={i}
                                        className={setOptionColor(i)}
                                    >
                                        {i.toString().padStart(2, '0')}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <hr />
                        <div className="input-group">
                            <span>Fecha de entrega:</span>
                            <input
                                id="fecha_entrega"
                                type="date"
                                className="popup-input"
                                placeholder="2023-08-21"
                                name="fecha_entrega"
                                value={nuevaTarea.fecha_entrega}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <hr />
                        <div className="input-group">
                            <span>Seleccionar Modulo:</span>
                            <select
                                id="idModulos"
                                className="popup-input"
                                name="idModulos"
                                value={nuevaTarea.idModulos}
                                onChange={handleChange}
                                required
                            >
                                {/* Opción predeterminada del combobox */}
                                <option value="">Seleccionar Modulo</option>
                                {/* Mapear los idModulos disponibles en el estado */}
                                {idModulosOptions.map((idModulo) => (
                                    <option key={idModulo.idModulos} value={idModulo.idModulos}>
                                        {idModulo.nombre_modulo}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="input-group" style={{ marginBottom: '12px' }}>
                            <span>Estado:</span>
                            <label className="switch" style={{ marginTop: '5px', marginBottom: '0px', marginLeft: '5px' }}>
                                <input
                                    id="estado"
                                    type="checkbox"
                                    name="estado"
                                    checked={nuevaTarea.estado}
                                    onChange={() =>
                                        setNuevaTarea((prevState) => ({
                                            ...prevState,
                                            estado: !prevState.estado,
                                        }))
                                    }
                                />
                                <span className="slider round">
                                </span>
                            </label>
                        </div>
                        <br />
                        <div>
                            <Button variant="info" onClick={handleShowHtmlModal}>
                                Cambiar vista a HTMLs
                            </Button>
                            {/* <Button variant="warning" onClick={createTable}>Crear tabla</Button> */}
                        </div>
                        <br></br>
                        <div className="align-items-center justify-content-between mb-3">
                            <div id="editando" ref={quillRef}>
                                <p><br /></p>
                            </div>
                            <br />
                        </div>
                        <hr style={{ marginTop: '1px' }} />
                        <div className="input-group">
                            <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                                {editarTarea ? "Guardar cambios" : "Crear nueva tarea"}
                            </button>
                        </div>
                    </form>
                    <Modal show={showHtmlModal} onHide={handleCloseHtmlModal} size="lg"> {/* Set the size to large */}
                        <Modal.Header className="centered-title" closeButton>
                            <Modal.Title>Editor HTML</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <textarea
                                rows="10"
                                value={htmlCode}
                                onChange={(e) => setHtmlCode(e.target.value)}
                                className="custom-textarea"
                                spellCheck="false"
                            />
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <Button variant="danger" onClick={handleCloseHtmlModal}>
                                Cerrar
                            </Button>
                            <Button variant="success" onClick={() => {
                                updateQuillContent(htmlCode);
                                handleSaveHtmlChanges();
                            }}>
                                Guardar cambios
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </div>
        </Fragment>
    );
}

export default withRouter(TareaUpdateHTML);