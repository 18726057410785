import React, { Fragment, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { FaChevronRight, FaClipboard, FaThList } from 'react-icons/fa';
import { withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import { Container, Form, Button, Alert, Breadcrumb, Card } from 'react-bootstrap';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { url } from "../../../../../../config";
import SessionManager from "../../../../../SessionManagement.js";
import CerrarSesion from "../../../../../CerrarSesion.js"
import ExamenRespuestas from "../../SubCursos/CursoExamenes/ExamenRespuestas.js";
import ExamenResolution from './ExamenResolution.js';
import { DateTime } from 'luxon';


function ExamenPrevResolution(props) {
    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idExamen } = props;
    const { idCurso } = props;
    const { idTarea } = props;
    const [nombreClase, setNombreClase] = useState('');
    const [uploadStatus, setUploadStatus] = useState(null);
    const [idtarea, setIdTarea] = useState(null);
    const [idcurso, setIdCurso] = useState(null);
    const [idUser, setIdUser] = useState([]);
    const [nombreexamen, setNombreExamen] = useState([]);
    const [idobtenido, setIdObtenido] = useState([]);
    const [homeworkLink, setHomeworkLink] = useState('');// Initialize the state variable for user ID
    const [idUsuario, setIdUsuario] = useState(null);
    const [tareanombre, setTareaNombre] = useState('');
    const [tareaapellido, setTareaApellido] = useState('');
    const [tarearespuesta, setTareaRespuesta] = useState([]);
    const [esDeshabilitado, setEsDeshabilitado] = useState(false);
    const [mostrartareasnotas, setmostrartareasnotas] = useState(false);
    const [realizarexamenes, setRealizarExamenes] = useState(false);
    const [id_examen, setid_examen] = useState('');
    const [Intentos, setIntentos] = useState(0);
    const [IntenHechos, setIntenHechos] = useState(0);


    const cookies = new Cookies();

    const fetchExamen = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idExamen: idExamen };
            const result = await axios.post(baseUrl + "examen/buscarExamenSolo", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setNombreExamen(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: 'Error',
                text: 'Hemos tenido un problema al iniciar el examen',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const InicioExamen = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idExamen: idExamen };
            const result = await axios.post(baseUrl + "Examen_respuesta/registrarExamen_respuesta", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setNombreExamen(result.data);
            } else {
                // Manejar respuestas no exitosas
                throw new Error('Respuesta no exitosa del servidor');
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchIniciadorExamen = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idExamen: idExamen };
            const result = await axios.post(baseUrl + "Examen_respuesta/listarExamen_respuesta", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setid_examen(result.data);
                // Asignar valores a los nuevos estados
                setIntentos(result.data.intentos || 3);
                setIntenHechos(result.data.inten_hechos || 1);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchExamenResuelto = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idExamen: idExamen };
            const result = await axios.post(baseUrl + "Examen_respuesta/buscarExamen_respuesta", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                const datos = result.data;
                setTareaNombre(datos.nombres);
                setTareaApellido(datos.apellidos);
                setTareaRespuesta(datos.examen_respuesta);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchUserId = async () => {
        try {
            const token = cookies.get('token');
            const response = await axios.get(baseUrl + "usuarios/userdata", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const userData = response.data;
            setIdUser(userData.rolesId);
        } catch (error) {
            console.error(error);
            // Maneja el error de la solicitud al backend
        }
    };

    // Función para verificar si tarearespuesta es válido
    const esTareaRespuestaValida = (respuesta) => {
        return respuesta && respuesta.fecha_inicio_examen && respuesta.fecha_fin_examen;
    };

    const usuarioId = parseInt(idUser, 10);

    const handleMostrarRespuestas = () => {
        setmostrartareasnotas(true);
    };

    const handleHacerExamen = async (idCurso, idExamen) => {
        setIdCurso(idCurso);
        setIdTarea(idExamen);
        await InicioExamen();
        setRealizarExamenes(true);
    };

    useEffect(() => {
        const fetchNombreCurso = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idCurso: idCurso };
                const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    setNombreClase(result.data);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };

        /* const fetchTareaRespuesta = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idTarea: idTarea };
                const result = await axios.post(baseUrl + "Tareas_respuesta/listarTareas_respuesta", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    const datos = result.data;
                    console.log("TAREA RESPUESTAS:", result.data);
                    setTareaNombre(datos.nombres);
                    setTareaApellido(datos.apellidos);
                    setTareaRespuesta(datos.tareas_respuesta);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        }; */


        fetchNombreCurso();
        fetchExamen();
        fetchUserId();
        fetchExamenResuelto();
    }, []);

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    }

    const formatDateEntregado = (dateString) => {
        // Convertir la fecha y hora del servidor a un objeto DateTime de Luxon
        const serverDateTime = DateTime.fromISO(dateString, { zone: 'UTC' }); // Especificar la zona horaria del servidor (UTC)

        // Obtener la fecha y hora local del cliente
        const localDateTime = serverDateTime.toLocal();

        // Formatear la fecha y hora local según tus preferencias
        const formattedDate = localDateTime.setLocale('es').toFormat("dd 'de' LLLL 'del' yyyy 'a las' HH:mm");

        return formattedDate;
    };

    useEffect(() => {
        const token = cookies.get('token');
        if (token) {
            const decodedToken = jwt_decode(token);
            const idUsuarioFromToken = decodedToken.nameid;
            // Set the user ID using the correct setter function
            setIdUsuario(idUsuarioFromToken);
        } else {
            console.log('No token found!');
        }
    }, []);

    useEffect(() => {
        fetchIniciadorExamen();
    }, []);

    /* ----------------DESACTIVANDO EL BOTON DE "REALIZAR EXAMEN - ORIGINAL"-----------------------
        useEffect(() => {
            const idExamenEstaVacio = !id_examen;
            const fechaEntrega = nombreexamen.fecha_entrega ? new Date(nombreexamen.fecha_entrega) : null;
            const fechaActual = new Date();

            const esDespuesDeFechaEntrega = fechaEntrega && fechaEntrega < fechaActual;
            const yaesta = id_examen != null;

            setEsDeshabilitado(!idExamenEstaVacio && (esDespuesDeFechaEntrega || yaesta));
       }, [nombreexamen, id_examen]); // Dependencias: nombreexamen, id_examen
       ------------------------------------------------------------------------------------- */

    /* ----------------DESACTIVANDO EL BOTON DE "REALIZAR EXAMEN - 2.0 "-----------------------
    useEffect(() => {
        const idExamenEstaVacio = !id_examen;
        const fechaEntrega = nombreexamen.fecha_entrega ? new Date(nombreexamen.fecha_entrega) : null;
        const fechaActual = new Date();

        const esDespuesDeFechaEntrega = fechaEntrega && fechaEntrega < fechaActual;
        const yaesta = id_examen != null;

        setEsDeshabilitado(!idExamenEstaVacio && (esDespuesDeFechaEntrega || yaesta));
    }, [nombreexamen, id_examen]); // Dependencias: nombreexamen, id_examen
    ------------------------------------------------------------------------------------- */

    useEffect(() => {
        const fechaEntrega = nombreexamen.fecha_entrega ? new Date(nombreexamen.fecha_entrega) : null;
        const fechaActual = new Date();
        const esDespuesDeFechaEntrega = fechaEntrega && fechaEntrega < fechaActual;
        console.log("Estado actual de Intentos e IntenHechos:", Intentos, IntenHechos);

        // Nuevos campos para limitar intentos y contar los realizados
        const inten_hechos = IntenHechos;
        console.log("inten_hechos", inten_hechos);
        const intentos = Intentos || 3;
        console.log("intentos", intentos);

        // Verificar si se ha alcanzado el límite de intentos o si ya ha pasado la fecha de entrega
        const superaIntentos = inten_hechos >= intentos;
        console.log(superaIntentos);

        // Verificar si la nota es igual a 20
        const notaVeinte = tarearespuesta.nota === 20;

        // Deshabilitar el botón si se cumple alguna de las condiciones
        setEsDeshabilitado(esDespuesDeFechaEntrega || superaIntentos || notaVeinte);
    }, [nombreexamen, Intentos, IntenHechos, tarearespuesta]);

    /* ------------------------------------------------------------------------------------------------------ */

    return (
        <div>
            <Fragment>
                {mostrartareasnotas ? (
                    <ExamenRespuestas idCurso={idCurso} idExamen={idExamen} />
                ) : realizarexamenes ? (
                    <ExamenResolution idCurso={idCurso} idExamen={idExamen} inten_hechos={IntenHechos} />
                ) : (
                    <div className='container'>
                        <Container className="my-5 p-3 bg-light border rounded">

                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                {usuarioId === 4 || usuarioId === 5 ? (
                                    <Button className="lavandabu direction-button" onClick={handleMostrarRespuestas} style={{ textAlign: 'left' }}>
                                        Ver Registros de Examenes  <FaThList className="fa-icon" />
                                    </Button>
                                ) : null}
                            </div>

                            <hr />
                            <div className="text-center my-4">
                                <h1 className="curso-tarea-envio-title">{nombreexamen.titulo}</h1>
                                <hr />
                                <div className="TareaContainer">
                                    <div><strong>Fecha de entrega: </strong>{formatDate(nombreexamen.fecha_entrega)}</div>
                                    <div><strong>Puntos: </strong>{nombreexamen.puntos}</div>
                                </div>

                                <hr />
                                <div className="curso-tarea-envio-description" dangerouslySetInnerHTML={{ __html: nombreexamen.descripcion }}></div>
                                <hr />

                                {esTareaRespuestaValida(tarearespuesta) && (
                                    <>

                                        <div className='div-contenedor'>
                                            <p style={{ textAlign: 'left', margin: 0 }}><strong>Fecha de inicio: </strong>{formatDateEntregado(tarearespuesta.fecha_inicio_examen)}</p>
                                            <p style={{ textAlign: 'left', margin: 0 }}><strong>Fecha de envio: </strong>{formatDateEntregado(tarearespuesta.fecha_fin_examen)}</p>
                                        </div>
                                        <div className='div-contenedor'>
                                            <p className='truncate-tarea-nombre' style={{ textAlign: 'left', margin: 0 }}><strong>Nombre: </strong> {tareanombre} {tareaapellido}</p>
                                        </div>
                                        <div className='div-contenedor'>
                                            <p style={{ textAlign: 'left', margin: 0 }}>
                                                <strong>Calificación: </strong>{tarearespuesta.nota == null ? 'Sin Realizar' : tarearespuesta.nota}
                                            </p>
                                        </div>
                                        <div className='div-contenedor'>
                                            <p style={{ textAlign: 'left', margin: 0 }}><strong>Intentos Hechos: </strong><i>{tarearespuesta.inten_hechos} de {tarearespuesta.intentos}</i></p>
                                        </div>

                                        <hr className='lineamoduloct' />
                                    </>
                                )}
                            </div>
                            <Button variant="primary" type="submit" className="curso-tarea-envio-submit" disabled={esDeshabilitado} onClick={() => handleHacerExamen(idExamen, idCurso)}>Realizar Examen</Button>
                        </Container>
                    </div>
                )}
            </Fragment>
        </div>
    );
}

export default withRouter(ExamenPrevResolution);