import React, { Fragment, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withRouter } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { FaPlus, FaPencilAlt, FaTrash, FaWindowClose } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../../config";
import SessionManager from "../../../../../SessionManagement.js";
import CerrarSesion from "../../../../../CerrarSesion.js"

function ExamenPrDetalle(props) {
    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idExamen } = props;
    const { idCurso } = props;
    const { idExamen_preguntas } = props;
    const cookies = new Cookies();
    // Estado para almacenar los idTipo_preguntas disponibles
    const [idExamenOptions, setidExamenOptions] = useState([]);
    const [showCrud, setShowCrud] = useState(true);
    const [examenpreguntasopciones, setExamenpreguntas] = useState([]);
    const [selectedPregunta, setSelectedPregunta] = useState(null);
    const [nombreClase, setNombreClase] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [preguntaSeleccionada, setPreguntaSeleccionada] = useState(null);
    const [checkboxesSeleccionados, setCheckboxesSeleccionados] = useState({});
    const [nuevoCursoDocente, setNuevoCursoDocente] = useState([]);
    const [nuevoCursoEstudiante, setNuevoCursoEstudiante] = useState([]);
    const [soloPreguntaOpcion, setSoloCurso] = useState(true);
    const [validacionCompleta, setValidacionCompleta] = useState(false);


    const [filteredExamenpreguntasopciones, setFilteredExamenpreguntasopciones] = useState([]);


    const [nuevaPreguntaOpciones, setnuevaPreguntaOpciones] = useState({
        valor: '',
        respuesta: false,
        idExamen_preguntas: idExamen_preguntas,
    });

    // Capturando datos para el nuevaPreguntaOpciones.
    const ModuloPreguntaOpciones = {
        valor: nuevaPreguntaOpciones.valor,
        respuesta: nuevaPreguntaOpciones.respuesta,
        idExamen_preguntas: parseInt(idExamen_preguntas)
    };
    const resetForm = () => {
        setnuevaPreguntaOpciones({
            valor: '',
            respuesta: false,
            idExamen_preguntas: ''
        });
    };

    const togglePopup = () => {        
        resetForm();
        setShowPopup(!showPopup);
        return true;
    };

    // Cerrar sesión


    const fetchExamenpreguntasOpciones = async () => {
        try {
            const token = cookies.get('token');
            const result = await axios.get(baseUrl + "Examen_pregunta_opciones", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Filter the data based on idExamen_preguntas
                const filteredData = result.data.filter(item => item.idExamen_preguntas === idExamen_preguntas);
                setFilteredExamenpreguntasopciones(filteredData);
            } else {
                // Handle unsuccessful response
            }
        } catch (error) {
            console.error(error);
            // Handle error
        }
    };


    // Agregar nuevo Modulo:
    const handleChange = (event) => {
        const { name, value } = event.target;
        // Convertir el valor a entero solo si el campo es 'idExamen_preguntas'
        const newValue = name === 'idExamen_preguntas' ? parseInt(value) : value;
        // Convertir el valor de 'respuesta' a un valor numérico (1 para true, 0 para false)
        const newRespuesta = name === 'respuesta' ? value === "true" ? 1 : 0 : value;
        setnuevaPreguntaOpciones((prevState) => ({
            ...prevState,
            [name]: name === 'respuesta' ? newRespuesta : newValue,
        }));
    };


    const agregarPreguntaOpcion = async (event) => {
        event.preventDefault(); // Cancelar el evento por defecto
        try {
            if (editarPreguntaOpcion) {
                // EDITAR TAREA
                //
                // Establecer los valores del preguntaopcion seleccionado al formulario popup
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevaPreguntaOpciones.valor
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                const response = await axios.post(
                    baseUrl + 'Examen_pregunta_opciones/editarExamenPreguntaOpciones',
                    nuevaPreguntaOpciones,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Realizar alguna acción después de registrar el preguntaopcion exitosamente
                // Por ejemplo, cerrar el popup y volver a cargar la lista de examenpreguntasopciones
                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Cambio exitoso',
                        text: 'Modulo actualizado correctamente!',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    togglePopup();
                    fetchExamenpreguntasOpciones();
                    resetForm();
                }
            } else {
                // AGREGAR TAREA
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevaPreguntaOpciones.valor
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                if (soloPreguntaOpcion) {
                    // Llamar a la API para agregar un preguntaopcion, docentes y estudiantes
                    const response = await axios.post(
                        baseUrl + 'Examen_pregunta_opciones/registrarExamenPreguntasOpciones',
                        ModuloPreguntaOpciones,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el preguntaopcion exitosamente
                    // cerrar el popup y volver a cargar la lista de examenpreguntasopciones
                    if (response.status === 201) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            text: 'Tarea creado correctamente!',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                        togglePopup();
                        fetchExamenpreguntasOpciones();
                        resetForm();
                    }
                }
            }
        } catch (error) {
            // MANEJO DE ERRORES
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };

    const eliminarPregunta = async (idExamen_pregunta_opciones) => {
        Swal.fire({
            title: '¿Estás seguro de que deseas ELIMINAR esta alternativa?',
            text: 'La alternativa no se podrá recuperar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const request = {
                        idExamen_pregunta_opciones: idExamen_pregunta_opciones
                    };
                    const token = cookies.get('token');
                    const response2 = await axios.post(baseUrl + 'Examen_pregunta_opciones/eliminarExamenPreguntaOpciones', request, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    // Mostrar Sweet Alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Alternativa eliminada correctamente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    fetchExamenpreguntasOpciones();

                } catch (error) {
                    console.error(error);
                    // Manejar el error de la solicitud al backend
                }
            } else {
                setPreguntaSeleccionada(null); // Restablecer a null
            }
        });
    };

    //

    // Popup Editar
    const [editarPreguntaOpcion, seteditarPreguntaOpcion] = useState(false);

    const abrireditarPreguntaOpcion = (preguntaopcion) => {
        seteditarPreguntaOpcion(true);
        togglePopup();

        // Asignar los valores del preguntaopcion seleccionado al objeto nuevaPreguntaOpciones
        setnuevaPreguntaOpciones({
            idExamen_pregunta_opciones: preguntaopcion.idExamen_pregunta_opciones,
            idExamen_preguntas: parseInt(idExamen_preguntas),
            valor: preguntaopcion.valor,
            respuesta: preguntaopcion.respuesta,
        });
    };

    const redirectToPreguntasOpciones = (idExamen_preguntas) => {
        ;
        setSelectedPregunta(idExamen_preguntas);
        setShowCrud(false);
    };

    const renderSelectedPreguntas = () => {
        if (selectedPregunta) {
            return <ExamenPrDetalle />;
        }
        return null;
    };

    useEffect(() => {
        const fetchNombreCurso = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idCurso: idCurso };

                const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    setNombreClase(result.data);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };

        fetchNombreCurso();
        fetchExamenpreguntasOpciones();
    }, [nuevaPreguntaOpciones]);
    // 

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevaPreguntaOpciones.idExamen_preguntas && nuevaPreguntaOpciones.valor && nuevaPreguntaOpciones.respuesta) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevaPreguntaOpciones.idExamen_preguntas, nuevaPreguntaOpciones.valor, nuevaPreguntaOpciones.respuesta]);

    // Paginación y limitación de mapeo de cursos

    const [currentPageCursos, setCurrentPageCursos] = useState(1);
    const coursesPorPage = 4; // Cantidad de cursos por página

    // Función para ir a la página anterior
    const previousPage = () => {
        if (currentPageCursos > 1) {
            setCurrentPageCursos(currentPageCursos - 1);
        }
    };

    // Función para ir a la página siguiente
    const nextPage = () => {
        const totalPages = Math.ceil(filteredExamenpreguntasopciones.length / coursesPorPage);
        if (currentPageCursos < totalPages) {
            setCurrentPageCursos(currentPageCursos + 1);
        }
    };

    const indexOfLastCoursePage = currentPageCursos * coursesPorPage;
    const indexOfFirstCoursePage = indexOfLastCoursePage - coursesPorPage;
    const currentCoursesA = filteredExamenpreguntasopciones.slice(indexOfFirstCoursePage, indexOfLastCoursePage);

    // Generar los botones de paginación
    const pageNumbersA = [];
    for (let i = 1; i <= Math.ceil(filteredExamenpreguntasopciones.length / coursesPorPage); i++) {
        pageNumbersA.push(i);
    }


    return (
        <Fragment>
            {renderSelectedPreguntas()}
            {!selectedPregunta && showCrud && (
                <Container>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <div className="col-12">
                            <h1>Lista de Alternativas</h1>
                            <Button variant="success" onClick={togglePopup}>
                                Agregar alternativa <FaPlus />
                            </Button>
                        </div>

                    </div>
                    <div className="table-responsive">
                    {currentCoursesA.length > 0 ? (
                        <Table hover className="custom-table">
                            <thead>
                                <tr>
                                    <th className="hide-column">Id de la Pregunta</th>
                                    <th>Valor de la Pregunta</th>
                                    <th>Respuesta</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentCoursesA.map((preguntaopcion, index) => (
                                    <tr key={preguntaopcion.idExamen_pregunta_opciones}>
                                        <td className="hide-column align-middle">{preguntaopcion.idExamen_preguntas}</td>
                                        <td  className='truncate-opcion align-middle'>{preguntaopcion.valor}</td>
                                        <td style={{ width: '98px', height: '40px' }}>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: preguntaopcion.respuesta ? '#D55613' : '',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: '1px solid #D55613',
                                                }}
                                            >
                                                <span style={{ color: preguntaopcion.respuesta ? 'white' : 'black' }}>
                                                    {preguntaopcion.respuesta ? 'Si' : 'No'}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <Button  className='align-middle' variant="warning" onClick={() => abrireditarPreguntaOpcion(preguntaopcion)}>
                                                <FaPencilAlt />
                                            </Button>{' '}
                                            <Button className='align-middle' variant="danger" onClick={() => eliminarPregunta(preguntaopcion.idExamen_pregunta_opciones)}>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    ) : (
                        <h3 className="nohaycursoslista">No hay preguntas detalles para mostrar</h3>
                    )}
                    </div>
                    {/* Paginación */}
                    <nav aria-label="Page navigation example" className="fixed-pagination2">
                        <ul className="pagination">
                            <li className="page-item">
                                <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                                    <span aria-hidden="true">&laquo;</span>
                                    <span className="sr-only">Previous</span>
                                </a>
                            </li>
                            {pageNumbersA.map((number) => (
                                <li key={number} className={`page-item ${currentPageCursos === number ? "active" : ""}`}>
                                    <a className="page-link" href="#" onClick={() => setCurrentPageCursos(number)}>
                                        {number}
                                    </a>
                                </li>
                            ))}
                            <li className="page-item">
                                <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                    <span aria-hidden="true">&raquo;</span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </Container>
            )}

            <CSSTransition
                in={showPopup}
                timeout={300}
                classNames="popup-transition"
                unmountOnExit
            >
                <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} className="popup-contenta">
                    <div className="popup-wrapper">
                        <div className="popup-contenta">
                            <div>
                                <div className="popup-header">
                                    <div className="popup-title">
                                        Control de Alternativa
                                    </div>
                                    <div className="popup-close-button">
                                        <FaWindowClose color="#c82333" className="invert-icon" onClick={togglePopup} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                                <div className="popup-message">
                                    Por favor ingrese los siguientes datos:
                                </div>                                
                                <hr />
                                <br />
                                <form onSubmit={agregarPreguntaOpcion}>
                                    <div className="input-group">
                                        <span>Valor: </span>
                                        <input
                                            id="valor"
                                            type="text"
                                            className="popup-input"
                                            placeholder="contenido de la opcion"
                                            maxLength={350}
                                            name="valor"
                                            value={nuevaPreguntaOpciones.valor}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-group" style={{ marginBottom: '12px' }}>
                                        <span>Estado:</span>
                                        <label className="switch" style={{ marginTop: '5px', marginBottom: '0px', marginLeft: '5px' }}>
                                            <input
                                                id="respuesta"
                                                type="checkbox"
                                                name="respuesta"
                                                checked={nuevaPreguntaOpciones.respuesta}
                                                onChange={() =>
                                                    setnuevaPreguntaOpciones((prevState) => ({
                                                        ...prevState,
                                                        respuesta: !prevState.respuesta,
                                                    }))
                                                }
                                            />
                                            <span className="slider round">
                                            </span>
                                        </label>
                                    </div>
                                    {/* <hr />
                                    <div className="input-group">
                                        <span>idExamen_preguntas</span>
                                        <input
                                            id="idExamen_preguntas"
                                            type="text"
                                            className="popup-input"
                                            placeholder="2"
                                            name="idExamen_preguntas"
                                            value={nuevaPreguntaOpciones.idExamen_preguntas} // Vincular el valor del input con el estado nuevaPreguntaOpciones.idExamen
                                            onChange={handleChange}
                                        />
                                    </div> */}
                                    <hr style={{ marginTop: '1px' }} />
                                    <div className="input-group">
                                        <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                                            {editarPreguntaOpcion ? "Guardar cambios" : "Crear nueva Opcion de Pregunta"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Popup>
            </CSSTransition>
        </Fragment>
    );
}

export default withRouter(ExamenPrDetalle);