import React, { Fragment, useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import * as QuillTableUI from 'quill-table-ui'
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function ExamenUpdateHTML(props) {
    
SessionManager(props);

const cerrarSesion = () =>{
  CerrarSesion(props);
}
     // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idCurso } = props;
    const { idExamen } = props;
    const { idModulos } = props;
    const cookies = new Cookies();
    const [cursos, setCursos] = useState([]);
    const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const [editorContent, setEditorContent] = useState(''); // Content in rich text format
    const [htmlCode, setHtmlCode] = useState(''); // HTML code content
    const [showHtmlModal, setShowHtmlModal] = useState(false); // To control the HTML modal    
    const [tareas, setForos] = useState([]);
    const [nombreClase, setNombreClase] = useState('');
    const [soloCurso, setSoloCurso] = useState(true);
    const [soloForo, setsoloForo] = useState(true);
    const [idModulosOptions, setIdModulosOptions] = useState([]);
    const [examenHtmlContent, setExamenHtmlContent] = useState('');


    // Cerrar sesión
    

    const resetForms = () => {
        setEditorHTML({
            contenido: '',
        });
    };

    //--------------------------------------------------------------------------------------------------------------   
    const [nuevoExamen, setNuevoExamen] = useState({
        titulo: '',
        descripcion: '',
        adjunto: '',
        puntos: '',
        estado: false,
        idModulos: '',
    });

    // Capturando datos para el nuevoExamen.
    const ModuloExamen = {
        titulo: nuevoExamen.titulo,
        descripcion: nuevoExamen.descripcion,
        estado: nuevoExamen.estado,
        puntos: parseInt(nuevoExamen.puntos),
        adjunto: nuevoExamen.adjunto,
        idModulos: parseInt(nuevoExamen.idModulos),
    };

    const resetForm = () => {
        setNuevoExamen({
            titulo: '',
            descripcion: '',
            adjunto: '',
            puntos: '',
            estado: false,
        });
        // Restablecer otras variables relacionadas si las hay
    };


    // Popup Editar
    const [editarExamen, setEditarExamen] = useState(false);

    const abrirEditarExamen = (examen) => {
        setEditarExamen(true);

        // Asignar los valores del examen seleccionado al objeto nuevoExamen
        setNuevoExamen({
            idExamen: examen.idExamen,
            titulo: examen.titulo,
            descripcion: examen.descripcion,
            puntos: parseInt(examen.puntos),
            adjunto: examen.adjunto,
            idModulos: examen.idModulos,
            estado: examen.estado
        });
    };

    // Agregar nuevo Modulo:
    const handleChange = (event) => {
        const { name, value } = event.target;
        // Convertir el valor a entero solo si el campo es 'idModulos' o 'nota'
        const newValue = name === 'idModulos' || name === 'puntos' ? parseInt(value) : value;

        setNuevoExamen((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    const LeerExamenEditar = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idExamen: idExamen };
            const result = await axios.post(baseUrl + "examen/buscarExamenSolo", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Assuming result.data contains all the fields for the form
                const examenData = result.data;
                setNuevoExamen({
                    titulo: examenData.titulo,
                    descripcion: examenData.descripcion, // This will be set in the Quill editor separately
                    puntos: examenData.puntos,
                    estado: examenData.estado,
                    fecha_entrega: examenData.fecha_entrega.split('T')[0], // Assuming the date comes in ISO format
                    idModulos: examenData.idModulos,
                });
            } else {
                // Handle an unsuccessful response if needed.
            }
        } catch (error) {
            console.error(error);
            // Handle errors from the backend request.
        }
    };


    const fetchCursos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "examen/listarExamenesMenu", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setCursos(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };


    const editandoExamen = async (event) => {
        event.preventDefault(); // Cancelar el evento por defecto

        const updatedExamen = {
            ...nuevoExamen,
            idModulos: parseInt(nuevoExamen.idModulos),
            idExamen: idExamen
        };

        try {
            // EDITAR Examen
            //
            // Establecer los valores del examen seleccionado al formulario popup
            //
            // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
            if (
                !nuevoExamen.titulo ||
                !nuevoExamen.puntos ||
                !nuevoExamen.idModulos
            ) {
                throw new Error('Por favor, complete todos los campos.');
            }
            const token = cookies.get('token');
            const response = await axios.post(
                baseUrl + 'examen/editarExamen',
                updatedExamen, // Use the updatedExamen with the converted idModulos
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // Realizar alguna acción después de registrar el examen exitosamente
            // Por ejemplo, cerrar el popup y volver a cargar la lista de examenes
            if (response.status === 201 || response.status === 200) {
                
                let timerInterval;
                Swal.fire({
                    icon: 'success',
                    title: 'Cambio exitoso',
                    html: 'Examen actualizado correctamente!<br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                    timer: 5000, // countdown duration in milliseconds
                    timerProgressBar: true,
                    didOpen: () => {
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer();
                            const b = content ? content.querySelector('b') : null;
                            if (b) {
                                // Update the text to show the countdown in seconds
                                b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                            }
                        }, 100); // Update every 100 milliseconds
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result) => {
                    // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('I was closed by the timer');
                    }
                    window.location.href = '/menu';
                });
                fetchCursos();
                resetForm();
            }

        } catch (error) {
            // MANEJO DE ERRORES
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };

    //--------------------------------------------------------------------------------------------------------------

    const setOptionColor = (optionValue) => {
        if (optionValue >= 0 && optionValue <= 12) {
            return 'option-red';
        } else if (optionValue >= 13 && optionValue <= 20) {
            return 'option-green';
        }
    };

    const [nuevoHTML, setEditorHTML] = useState({
        contenido: '', // Initialize contenido as an empty string
        idModulos: idModulos,
    });

    const handleSubmitContent = () => {
        const token = cookies.get('token'); // Get the authorization token from cookies
        // Update nuevoHTML with the content from quill
        nuevoHTML.contenido = editorContent;

        // Make the API call with nuevoHTML as the content
        axios.post(baseUrl + "Paginas_contenido/registrarPaginas_contenido", nuevoHTML, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Include the token
            }
        })
            .then((response) => {
                // Handle the success
                console.log("ESTAMOS ENVIANDO:");
                console.log("CONTENIDO ENVIADO CORRECTAMENTE");
            })
            .catch((error) => {
                // Handle errors
                console.log("ESTAMOS ENVIANDO:");
                console.error("ERROR ENVIANDO DATOS:", error);
            });
    };

    // Popup Editar
    const [editarCurso, setEditarCurso] = useState(false);

    const abrirEditarCurso = (editorhtml) => {
        setEditarCurso(true);

        // Asignar los valores del editorhtml seleccionado al objeto nuevoHTML
        setEditorHTML({
            contenido: editorhtml.contenido,
            idModulos: editorhtml.idModulos,
        });
    };

    const fetchIdModulos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idModulos };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Extraer los idModulos de la respuesta y almacenarlos en el estado
                setIdModulosOptions(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchNombreCurso = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setNombreClase(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    useEffect(() => {
        fetchNombreCurso();
        fetchIdModulos();
        fetchCursos();
    }, [nuevoExamen]);
    // 

    useEffect(() => {
        const fetchExamenHtmlContent = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = {
                    idExamen: idExamen, // Make sure to pass the correct ID for the tarea
                };
                const result = await axios.post(baseUrl + "examen/buscarExamenSolo", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (result.status === 200 && result.data.descripcion) {
                    setExamenHtmlContent(result.data.descripcion); // Set the HTML content in the state
                } else {
                    // Handle non-successful status or no HTML content found
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchExamenHtmlContent();
    }, [idExamen]); // Dependency array to ensure the content is fetched when idTarea changes

    useEffect(() => {

        LeerExamenEditar();
    }, []);

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevoHTML.contenido) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevoHTML.contenido]);

    // Ref para el editor Quill
    const quillRef = useRef(null);
    const [quillInstance, setQuillInstance] = useState(null);

    const handleCloseHtmlModal = () => {
        setShowHtmlModal(false);
    };

    const handleShowHtmlModal = () => {
        setHtmlCode(editorContent);
        setShowHtmlModal(true);
    };

    const updateQuillContent = (html) => {
        if (quillInstance) {
            quillInstance.root.innerHTML = html;
            setEditorContent(html);
        }
    };

    const handleSaveHtmlChanges = () => {
        setEditorContent(htmlCode); // Update the content immediately in the rich text editor
        handleCloseHtmlModal();
    };


    useEffect(() => {

        // Inicializa Quill dentro de useEffect para asegurarnos de que el DOM esté listo
        if (!quillInstance && quillRef.current) {
            const Font = Quill.import('formats/font');
            Font.whitelist = ['serif', 'sans-serif', 'monospace', 'times-new-roman', 'arial', 'tahoma', 'helvetica'];
            Quill.register(Font, true);
            Quill.register({
                'modules/tableUI': QuillTableUI.default
            }, true)

            const toolbarOptions = [
                ["bold", "italic", "underline", "strike"],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "+1" }, { indent: "-1" }],
                [{ align: [] }],
                [{ size: ["small", "large", "huge", false] }],
                ["image", "link", "video", "formula"],
                [{ color: [] }, { background: [] }],
                [{ font: ["arial", "helvetica", "serif", "sans-serif", "monospace", "times-new-roman", "tahoma"] }],
                ["code-block", "blockquote"]
            ];

            const quill = new Quill(quillRef.current, {
                modules: {
                    toolbar: toolbarOptions,
                    table: true,
                    tableUI: true,
                },
                theme: 'snow',
            });

            setQuillInstance(quill);
        }
    }, [quillInstance]);

    

    const createTable = () => {
        if (quillInstance) {
            const table = quillInstance.getModule('table');
            if (table) {
                table.insertTable(2, 2); // You can specify the number of rows and columns
            }
        }
    };

    useEffect(() => {
        if (quillInstance && examenHtmlContent) {
          quillInstance.root.innerHTML = examenHtmlContent;
        }
      }, [quillInstance, examenHtmlContent]);
      
      const saveExamenContent = () => {
        const updatedHtmlContent = quillInstance.root.innerHTML;
        // ...existing code to send updated content to server...
      };
      


    useEffect(() => {
        if (quillInstance) {
            quillInstance.on("text-change", () => {
                const html = quillInstance.root.innerHTML;
                setEditorContent(html);
                // Update nuevoForo.descripcion with the editor content
                setNuevoExamen((prevState) => ({
                    ...prevState,
                    descripcion: html,
                }));
            });
        }
    }, [quillInstance]);

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevoExamen.titulo && nuevoExamen.adjunto && nuevoExamen.puntos) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevoExamen.titulo, , nuevoExamen.adjunto, nuevoExamen.puntos]);

    return (
        <Fragment>
            <div>
                <Container className="my-5 p-3 bg-light border rounded">
                    <div className="col-12">
                        <h1>Editando Datos con Editor html</h1>
                    </div>
                    <form onSubmit={editandoExamen}>
                        <div className="input-group">
                            <span>Titulo de la examen: </span>
                            <input
                                id="titulo"
                                type="text"
                                className="popup-input"
                                placeholder="Escribe el nombre del Examen"
                                name="titulo"
                                maxLength={100}
                                value={nuevoExamen.titulo}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <span>Puntos:</span>
                            <select
                                id="puntos"
                                type="text"
                                className="popup-input"
                                placeholder="19"
                                name="puntos"
                                value={nuevoExamen.puntos}
                                onChange={handleChange}
                                required
                            >
                                {Array.from({ length: 21 }, (_, i) => (
                                    <option
                                        key={i}
                                        value={i}
                                        className={setOptionColor(i)}
                                    >
                                        {i.toString().padStart(2, '0')}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <hr /><div className="input-group">
                            <span>Fecha de entrega:</span>
                            <input
                                id="fecha_entrega"
                                type="date"
                                className="popup-input"
                                placeholder="2023-08-21"
                                name="fecha_entrega"
                                value={nuevoExamen.fecha_entrega}
                                onChange={handleChange}
                            />
                        </div>
                        <hr />
                        <div className="input-group">
                            <span>Seleccionar Modulo:</span>
                            <select
                                id="idModulos"
                                className="popup-input"
                                name="idModulos"
                                value={nuevoExamen.idModulos}
                                onChange={handleChange}
                                required
                            >
                                {/* Opción predeterminada del combobox */}
                                <option value="">Seleccionar Id Modulos</option>
                                {/* Mapear los idModulos disponibles en el estado */}
                                {idModulosOptions.map((idModulo) => (
                                    <option key={idModulo.idModulos} value={idModulo.idModulos}>
                                        {idModulo.nombre_modulo}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <hr />
                        <div className="input-group">
                            <span>Estado:</span>
                            <label className="switch">
                                <input
                                    id="estado"
                                    type="checkbox"
                                    name="estado"
                                    checked={nuevoExamen.estado}
                                    onChange={() =>
                                        setNuevoExamen((prevState) => ({
                                            ...prevState,
                                            estado: !prevState.estado,
                                        }))
                                    }
                                />
                                <span className="slider round">
                                </span>
                            </label>
                        </div>
                        <br />
                        <div>
                            <Button variant="info" onClick={handleShowHtmlModal}>
                                Cambiar vista a HTML
                            </Button>
                            {/* <Button variant="warning" onClick={createTable}>Crear tabla</Button> */}
                        </div>
                        <br></br>
                        <div className="align-items-center justify-content-between mb-3">
                            <div id="editando" ref={quillRef}>
                                <p><br /></p>
                            </div>
                            <br />
                        </div>
                        <hr style={{ marginTop: '1px' }} />
                        <div className="input-group">
                            <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                                {editarExamen ? "Guardar cambios" : "Actualizar datos del examen"}
                            </button>
                        </div>
                    </form>
                    <Modal show={showHtmlModal} onHide={handleCloseHtmlModal} size="lg"> {/* Set the size to large */}
                        <Modal.Header className="centered-title" closeButton>
                            <Modal.Title>Editor HTML</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <textarea
                                rows="10"
                                value={htmlCode}
                                onChange={(e) => setHtmlCode(e.target.value)}
                                className="custom-textarea"
                                spellCheck="false"
                            />
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <Button variant="danger" onClick={handleCloseHtmlModal}>
                                Cerrar
                            </Button>
                            <Button variant="success" onClick={() => {
                                updateQuillContent(htmlCode);
                                handleSaveHtmlChanges();
                            }}>
                                Guardar cambios
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </div>
        </Fragment>
    );
}

export default withRouter(ExamenUpdateHTML);