import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withRouter } from 'react-router-dom'
import { FaChevronDown, FaChevronUp, FaWindowClose, FaChevronRight } from 'react-icons/fa';
import { Container, Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import * as QuillTableUI from 'quill-table-ui'
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import DOMPurify from 'dompurify';
import CerrarSesion from "../../../../CerrarSesion.js"
import { reset } from 'react-tabs/lib/helpers/uuid.js';

function CursoForoDiscu(props) {

    const { idUser } = props;
    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }

    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idCurso } = props;
    const { idForo } = props;
    const [nombreClase, setNombreClase] = useState('');
    const cookies = new Cookies();
    const [idModulosOptions, setIdModulosOptions] = useState([]); // Estado para almacenar los idModulos disponibles
    const [foros, setForos] = useState([]);
    const [forosrespuesta, setForosRespuesta] = useState([]);
    const [forosrespuestas, setForosRespuestas] = useState([]);
    const [infoforosrespuesta, setInfoForosRespuesta] = useState([]);
    const [infosforosrespuestas, setInfosForosRespuestas] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [cursoSeleccionado, setForoSeleccionado] = useState(null);
    const [soloForoRespuesta, setsoloForo] = useState(true);
    const [editorContent, setEditorContent] = useState('');
    const [htmlCode, setHtmlCode] = useState(''); // HTML code content
    const [showHtmlModal, setShowHtmlModal] = useState(false); // To control the HTML modal   
    const [idforo, setIdForo] = useState(null);
    const quillRef = useRef(null);// Ref para el editor Quill
    const [quillInstance, setQuillInstance] = useState(null);//------------   
    const [editarForoRespuesta, seteditarForoRespuesta] = useState(false); // Popup Editar
    const [isContainerVisible, setIsContainerVisible] = useState(true);//------------   
    const [dropdownVisibility, setDropdownVisibility] = useState({});//------------
    const [isEditing, setIsEditing] = useState(false);//------------    
    const [totalResponses, setTotalResponses] = useState(0);// Assuming useState is imported from React
    const [idUsuariod, setIdUsuariod] = useState(null);//------------
    // Define the state for storing response information
    const [responseInfo, setResponseInfo] = useState({ idRespuesta: null, idUsuario: null });
    // Define the state to control the visibility of the modal
    const [showModal, setShowModal] = useState(false);


    // Toggle the dropdown visibility
    const toggleDropdown = (idForosRespuesta) => {
        setDropdownVisibility((prevState) => ({
            ...prevState,
            [idForosRespuesta]: !prevState[idForosRespuesta],
        }));
    };

    // Example CSS styles (you can create a separate CSS file for these styles)
    const forumStyles = {
        forumContainer: {
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: "10px"
        },
        forumThread: {
            padding: '10px',
            marginBottom: '10px',
            backgroundColor: 'white',
        },
        forumTitle: {
            marginBottom: '1rem',
            textAlign: 'center',
            fontSize: '2.5rem',
            color: '#f47735',
            fontWeight: 'bold',
            borderRadius: '4px',
            /* Slight rounding on the title for consistency */
            wordWrap: 'break-word',
            /* Asegura que las palabras se rompan si son muy largas */
            wordBreak: 'break-all',
            /* Permite romper y ajustar palabras largas en cualquier carácter para evitar desbordamiento */
            overflowWrap: 'break-word',
            /* Una alternativa a word-wrap para navegadores más nuevos */
        },
        forumDiscussionContainer: {
            display: 'flex',
            justifyContent: 'space-between', // This will space out your items on the same line
            alignItems: 'center', // This will vertically align items in the middle
            marginBottom: '10px', // Optional, for some space below the container
            borderBottom: '1px solid #eee', // Adds a subtle line to separate the header from the content
            paddingBottom: '10px', // Adds space between the header and the content
        },
        forumDiscussionContainerv2: {
            justifyContent: 'space-between', // This will space out your items on the same line
            alignItems: 'center', // This will vertically align items in the middle
            marginBottom: '10px', // Optional, for some space below the container
        },
        forumDescription: {
            display: 'block',
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            marginInlineStart: '0px',
            marginInlineEnd: '0px',
            fontSize: '1rem', // Sets a standard font-size for description
            color: '#555', // A softer color for reading
        },
        forumMainDescription: {
            display: 'block',
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            marginInlineStart: '0px',
            marginInlineEnd: '0px',
            fontSize: '1rem', // Sets a standard font-size for description
            color: '#555', // A softer color for reading            
            textAlign: 'justify',
        },
        forumDiscussionfyi: {
            fontsize: '12px',
            fontSize: '0.75rem',
            color: '#737373',
            textAlign: 'left',
        },
        forumDiscussionfyicoment: {
            fontSize: '30px',
            color: '#D55613',
            textAlign: 'left',
            fontWeight: 'bold', // This will make the text bold
        },
        forumDiscussionfyiright: {
            fontsize: '12px',
            fontSize: '0.75rem',
            color: '#737373',
            textAlign: 'right',
        },
        forumDiscussionpadding: {
            fontsize: '12px',
            fontSize: '0.75rem',
            color: '#737373',
            textAlign: 'right',
            paddingRight: '607px',
        },
        forumDiscussionpaddingv2: {
            fontsize: '12px',
            fontSize: '0.75rem',
            color: '#737373',
            textAlign: 'right',
            paddingRight: '719px',
        },
        forumDiscussioncreator: {
            fontSize: '18px',
            color: '#F47735',
            fontWeight: 'bold',
        },
        forumThreads: {
            border: '1px solid #ddd',
            padding: '10px',
            marginBottom: '10px',
            backgroundColor: 'white',
            marginLeft: 'auto', // Move the forum threads to the right
            maxWidth: '70%', // Set a maximum width for the chat-like design
        },
        forumContainers: {
            backgroundColor: '#f0f0f0',
            padding: '20px',
            marginLeft: 'auto', // Move the forum threads to the right
            maxWidth: '70%', // Set a maximum width for the chat-like design   
        },
        forumThreadRight: {
            border: '1px solid #ddd',
            padding: '10px',
            marginBottom: '10px',
            backgroundColor: 'white',
            marginLeft: 'auto',
            maxWidth: '70%',
        },
        forumThreadLeft: {
            border: '1px solid #ddd',
            padding: '10px',
            marginBottom: '10px',
            backgroundColor: 'white',
            marginRight: 'auto',
            maxWidth: '70%',
        },
        // Style for top-level responses
        // Style for top-level responses
        // Enhanced style for top-level responses
        forumThreadNormal: {
            border: '1px solid #e0e0e0',
            padding: '15px',
            marginBottom: '20px',
            backgroundColor: '#f8f8f8',
            borderLeft: '4px solid #D55613',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            transition: 'box-shadow 0.3s ease-in-out',
            ':hover': {
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                backgroundColor: '#fafafa', // Lighter background on hover to match child responses
            },
        },

        // Enhanced style for child responses
        forumThreadChild: {
            border: '1px solid #e8e8e8',
            padding: '10px 15px',
            marginBottom: '10px',
            backgroundColor: '#fafafa',
            marginLeft: '20px',
            fontStyle: 'italic',
            color: '#555',
            borderRadius: '5px',
            transition: 'background-color 0.3s ease-in-out', // Smooth background color transition
            ':hover': {
                backgroundColor: '#f8f8f8', // Slightly darker background on hover to indicate interactivity
            },
        },


        forumHtml: {
            lineHeight: '1.6', // improves readability
        },
    };

    const dropdownLinkStyle = {
        display: 'inline-block', // Required for padding and margins to work
        padding: '0.375rem 0.75rem', // Bootstrap's button padding
        fontSize: '1rem', // Bootstrap's font size for buttons
        fontWeight: '400', // Bootstrap's font weight for buttons
        lineHeight: '1.5', // Bootstrap's line height for buttons
        textAlign: 'center', // Center the text inside
        textDecoration: 'none', // Remove underline from links
        color: '#fff', // Bootstrap's button text color (white)
        backgroundColor: '#f38346', // Bootstrap's primary button background color (blue)
        border: '1px solid #f38346', // Bootstrap's primary button border
        borderRadius: '0.25rem', // Bootstrap's border radius for buttons
        cursor: 'pointer', // Change cursor on hover
        transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out', // Smooth transition for hover effects
    };

    const actionContainerStyle = {
        display: 'flex', // Use flexbox to align items in a row
        justifyContent: 'flex-end', // Align items to the end of the container
        alignItems: 'center', // Center items vertically
        gap: '10px', // Add some space between items
    };

    // Function to handle mouse enter and change color on hover
    const handleMouseEnter = (event) => {
        event.target.style.backgroundColor = '#c8641e'; // Darker shade for hover, matching dropdownLinkStyle
        event.target.style.borderColor = '#c8641e'; // Bootstrap's hover border color (darker blue)
    };

    // Function to handle mouse leave and reset color after hover
    const handleMouseLeave = (event) => {
        event.target.style.backgroundColor = '#f38346'; // Darker shade for hover, matching dropdownLinkStyle
        event.target.style.borderColor = '#f38346'; // Bootstrap's hover border color (darker blue)
    };

    const dropdownButtonStyle = {
        marginLeft: '400px', // Move the dropdown button to the right
        backgroundColor: 'transparent', // Makes the button transparent
        border: 'none', // Removes the border
        cursor: 'pointer', // Changes cursor on hover
        display: 'flex', // Uses flexbox for aligning icon
        alignItems: 'center', // Centers the icon vertically
        justifyContent: 'center', // Centers the icon horizontally
        padding: '5px', // Adds some padding around the icon
    };

    // Example dropdown content style
    const dropdownContentStyle = {
        display: 'flex', // This will align the children in a row
        flexDirection: 'row', // Align children horizontally
        justifyContent: 'center', // Center the items in the container
        alignItems: 'center', // Align items vertically in the center
    };



    const usuarioId = parseInt(idUser, 10);

    const toggleContainerVisibility = () => {
        setIsContainerVisible((prevVisibility) => !prevVisibility);
    };

    const [nuevoForoRespuesta, setNuevoForoRespuesta] = useState({
        idforo: idforo,
        fecha_registro: '',
        descripcion: '',
        idUsuario_padre: '',
    });

    const resetForm = () => {
        setNuevoForoRespuesta({
            fecha_registro: '',
            descripcion: '',
            idUsuario_padre: '',
        });
        seteditarForoRespuesta(false);
        // Restablecer otras variables relacionadas si las hay
    };

    const fetchForos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idForo: idForo };
            const result = await axios.post(baseUrl + "Foros/buscarForoSolo", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setForos(result.data);

                // Assuming that the 'IdForo' is a property in the 'result.data' object, you can set it here.
                if (result.data && result.data.length > 0) {
                    const idforo = result.data[0].idforo;

                    setIdForo(idforo);
                }
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchForosRespuestasAmbos = async () => {   //renderiza los que no y los que tienen idforo_padre
        try {
            const token = cookies.get('token');
            const requestBody = { idForo: idForo };
            const result = await axios.post(baseUrl + "Foros_respuesta/listarForos_respuesta", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Access the forosRespuestasPadre array and set it to the state variable.
                setForosRespuesta(result.data.forosRespuestasPadre);
                setInfoForosRespuesta(result.data.usuariosForoRespuestaPadre);
                // Access the forosRespuestasHijo object and set it to the state variable.
                setForosRespuestas(result.data.forosRespuestasHijo);
                setInfosForosRespuestas(result.data.usuariosForoRespuestaHijo);
            } else {
                // Handle an unsuccessful response if needed.
            }
        } catch (error) {
            console.error(error);
            // Handle errors from the backend request.
        }
    };
    const agregarForo = async (event) => {
        event.preventDefault(); // Cancelar el evento por defecto

        // Parse 'idforo' to an integer if it's not empty and is a valid number
        const idForoInt = nuevoForoRespuesta.idforo ? parseInt(nuevoForoRespuesta.idforo, 10) : null;
        if (isNaN(idForoInt)) {
            throw new Error('ID Foro debe ser un número válido.');
        }

        // Parse 'idUsuario_padre' to an integer if it's not empty and is a valid number
        const idUsuarioPadreInt = nuevoForoRespuesta.idUsuario_padre ? parseInt(nuevoForoRespuesta.idUsuario_padre, 10) : null;
        if (isNaN(idUsuarioPadreInt)) {
            throw new Error('ID Usuario Padre debe ser un número válido.');
        }


        const updatedContent = quillInstance.root.innerHTML;
        const updatePayload = {
            ...nuevoForoRespuesta,
            idforo: idForoInt, // Use the parsed integer for 'idforo'
            idUsuario_padre: idUsuarioPadreInt, // Use the parsed integer for 'idUsuario_padre'
            descripcion: updatedContent,
            // Include other fields as required by your API
        };
        try {
            if (editarForoRespuesta) {
                // EDITAR TAREA
                //
                // Establecer los valores del fororespuesta seleccionado al formulario popup
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevoForoRespuesta.fecha_registro ||
                    !nuevoForoRespuesta.descripcion
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                const response = await axios.post(
                    baseUrl + 'Foros_respuesta/editarForos_respuesta',
                    updatePayload,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Realizar alguna acción después de registrar el fororespuesta exitosamente
                // Por ejemplo, cerrar el popup y volver a cargar la lista de foros
                if (response.status === 201 || response.status === 201) {
                    let timerInterval;
                    Swal.fire({
                        icon: 'success',
                        title: 'Cambio exitoso',
                        html: 'Contenido Actualizado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                        timer: 3000, // countdown duration in milliseconds
                        timerProgressBar: true,
                        didOpen: () => {
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer();
                                const b = content ? content.querySelector('b') : null;
                                if (b) {
                                    // Update the text to show the countdown in seconds
                                    b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                }
                            }, 100); // Update every 100 milliseconds
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        }
                    }).then((result) => {
                        // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                        if (result.dismiss === Swal.DismissReason.timer) {
                            console.log('I was closed by the timer');
                        }
                        window.location.href = '/menu';
                    });
                    fetchForos();
                    fetchForosRespuestasAmbos();
                    resetForm();
                }
            } else {
                // AGREGAR TAREA
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO                
                const token = cookies.get('token');
                if (soloForoRespuesta) {

                    // Ensure that idUsuario_padre is parsed as an integer or set to null if not required
                    const idUsuarioPadreInt = nuevoForoRespuesta.idUsuario_padre ? parseInt(nuevoForoRespuesta.idUsuario_padre, 10) : null;

                    // If idUsuario_padre is required and the parsed value is NaN, throw an error
                    if (nuevoForoRespuesta.idUsuario_padre && isNaN(idUsuarioPadreInt)) {
                        throw new Error('ID Usuario Padre debe ser un número válido.');
                    }

                    const payloadForAgregar = {
                        ...nuevoForoRespuesta,
                        idforo: props.idForo, // Assuming this is the correct forum ID
                        fecha_registro: new Date().toISOString().split('T')[0], // Use the current date
                        descripcion: quillInstance.root.innerHTML, // The content from the Quill editor
                        idUsuario_padre: nuevoForoRespuesta.idUsuario_padre ? parseInt(nuevoForoRespuesta.idUsuario_padre, 10) : null, // Include the parsed integer or null
                        // Exclude idUsuario from the payload
                        // idForos_respuesta is not needed here because this is for adding a new response
                    };
                    // Call the API to add the new forum response
                    const response = await axios.post(
                        baseUrl + 'Foros_respuesta/registrarForos_respuesta',
                        payloadForAgregar,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el fororespuesta exitosamente
                    // cerrar el popup y volver a cargar la lista de foros
                    if (response.status === 201 || response.status === 201) {
                        let timerInterval;
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            html: 'Contenido Registrado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                            timer: 3000, // countdown duration in milliseconds
                            timerProgressBar: true,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer();
                                    const b = content ? content.querySelector('b') : null;
                                    if (b) {
                                        // Update the text to show the countdown in seconds
                                        b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                    }
                                }, 100); // Update every 100 milliseconds
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            }
                        }).then((result) => {
                            // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('I was closed by the timer');
                            }
                            window.location.href = '/menu';
                        });
                        fetchForos();
                        fetchForosRespuestasAmbos();
                        resetForm();
                    }
                }
            }
        } catch (error) {
            // MANEJO DE ERRORES

            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };

    const eliminarForo = async (idForos_respuesta) => {
        Swal.fire({
            title: '¿Estás seguro de que deseas ELIMINAR este Foro?',
            text: 'El modulo y su contenido no se podrán recuperar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const request = {
                        idForos_respuesta: idForos_respuesta
                    };
                    const token = cookies.get('token');
                    // eslint-disable-next-line
                    const response2 = await axios.post(baseUrl + 'Foros_respuesta/eliminarForoRespuesta', request, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    // Mostrar Sweet Alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Foro eliminado correctamente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    fetchForos();
                    fetchForosRespuestasAmbos();

                } catch (error) {
                    console.error(error);
                    // Manejar el error de la solicitud al backend
                }
            } else {
                setForoSeleccionado(null); // Restablecer a null
            }
        });
    };

    const handleCloseHtmlModal = () => {
        setShowHtmlModal(false);
    };

    const handleShowHtmlModal = () => {
        setHtmlCode(editorContent);
        setShowHtmlModal(true);
    };

    const updateQuillContent = (html) => {
        if (quillInstance) {
            quillInstance.root.innerHTML = html;
            setEditorContent(html);
        }
    };

    const handleSaveHtmlChanges = () => {
        setEditorContent(htmlCode); // Update the content immediately in the rich text editor
        handleCloseHtmlModal();
    };

    const createTable = () => {
        if (quillInstance) {
            const table = quillInstance.getModule('table');
            if (table) {
                table.insertTable(2, 2); // You can specify the number of rows and columns
            }
        }
    };

    const handleEdit = () => {
        // Update the state for editing
        setIsEditing(true);
    };

    // Function for handling adding
    const handleAdd = (htmlContent) => {
        // Update the state for adding
        setIsEditing(false);

        // Update the description field for adding
        setNuevoForoRespuesta((prevState) => ({
            ...prevState,
            descripcion: htmlContent,
        }));
    };

    const cancelarRerespuesta = () => {
        setShowModal(false);
    };

    const cancelarEdicion = () => {
        // Check if the quillInstance is available
        if (quillInstance) {
            // Set the content of the editor to an empty string or the default value you wish
            quillInstance.setText('');
            resetForm();
        }
        //
        // Set the state to indicate we are in edit mode
        seteditarForoRespuesta(false);
    }

    const abrireditarForo = (fororespuesta, idForosRespuesta, fechaRegistro) => {
        // Set the state to indicate we are in edit mode
        seteditarForoRespuesta(true);

        // Ensure the date is in the correct format if necessary
        const formattedFechaRegistro = fechaRegistro.split('T')[0]; // If it's in ISO format

        // Set the state with the response details to be edited
        setNuevoForoRespuesta({
            ...nuevoForoRespuesta,
            descripcion: fororespuesta.descripcion,
            fecha_registro: formattedFechaRegistro, // Make sure this name matches the state
            idforo: props.idForo.toString(), // Use the prop `idForo` here
            idForos_respuesta: idForosRespuesta, // Also set the response ID as a string if needed
        });


        // If using a modal to edit the HTML directly, you might want to update its state as well
        setHtmlCode(fororespuesta.descripcion);
    };

    const fetchIdModulos = async () => {
        try {
            const token = cookies.get('token');
            const result = await axios.get(baseUrl + "Modulos", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Extraer los idModulos de la respuesta y almacenarlos en el estado
                setIdModulosOptions(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    }

    // This function opens the modal and stores the necessary IDs
    const handleResponderClick = (idForosRespuesta, idUsuarioPadre) => {
        // Store the IDs in the state
        setResponseInfo({ idForosRespuesta, idUsuarioPadre });
        // Open the modal with the rich text editor
        setShowModal(true);
    };

    // This function is triggered when the submit button in the modal is clicked
    const submitReply = async () => {
        const content = quillRef.current.querySelector('.ql-editor').innerHTML;
        // Check if the content is only the default empty state
        if (content === '<p><br></p>' || content.trim() === '') {
            // Alert the user that they are trying to send an empty response
            Swal.fire({
                icon: 'warning',
                title: 'Respuesta vacía',
                text: 'No estás enviando nada. Por favor, escribe una respuesta.',
            });
            return; // Abort the function if the content is empty
        }

        const sanitizedContent = DOMPurify.sanitize(content);

        // Use the stored IDs from the state
        const { idForosRespuesta, idUsuario } = responseInfo;

        const responsePayload = {
            idforo: props.idForo,
            idUsuario_padre: idForosRespuesta,
            descripcion: sanitizedContent,
        };
        try {
            const token = cookies.get('token');
            const response = await axios.post(
                baseUrl + 'Foros_respuesta/registrarForos_respuesta', // Ensure this is the correct endpoint
                responsePayload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                let timerInterval;
                setShowModal(false);
                // Show confirmation alert
                Swal.fire({
                    icon: 'success',
                    title: 'Respuesta agregada!',
                    html: 'Respuesta agregada Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                    timer: 3000, // countdown duration in milliseconds
                    timerProgressBar: true,
                    didOpen: () => {
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer();
                            const b = content ? content.querySelector('b') : null;
                            if (b) {
                                // Update the text to show the countdown in seconds
                                b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                            }
                        }, 100); // Update every 100 milliseconds
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result) => {
                    // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('I was closed by the timer');
                    }
                    window.location.href = '/menu';
                });
            }
        } catch (error) {
            // Handle errors and keep the modal open for the user to correct their reply
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se pudo enviar la respuesta. Intente de nuevo.',
            });
        }
    };

    useEffect(() => {
        const fetchNombreCurso = async () => {
            try {
                const token = cookies.get("token");
                const requestBody = { idCurso: idCurso };
                const result = await axios.post(
                    baseUrl + "cursos/buscarCurso",
                    requestBody,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (result.status === 200) {
                    setNombreClase(result.data);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };

        fetchForos();
        fetchNombreCurso();


    }, [idCurso]);

    useEffect(() => {
        if (isContainerVisible) {
            if (!quillInstance && quillRef.current) {
                const Font = Quill.import('formats/font');
                Font.whitelist = ['serif', 'sans-serif', 'monospace', 'times-new-roman', 'arial', 'tahoma', 'helvetica'];
                Quill.register(Font, true);
                Quill.register({
                    'modules/tableUI': QuillTableUI.default
                }, true)

                const toolbarOptions = [
                    ["bold", "italic", "underline", "strike"],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "+1" }, { indent: "-1" }],
                    [{ align: [] }],
                    [{ size: ["small", "large", "huge", false] }],
                    ["image", "link", "video", "formula"],
                    [{ color: [] }, { background: [] }],
                    [{ font: ["arial", "helvetica", "serif", "sans-serif", "monospace", "times-new-roman", "tahoma"] }],
                    ["code-block", "blockquote"]
                ];

                const quill = new Quill(quillRef.current, {
                    modules: {
                        toolbar: toolbarOptions,
                        table: true,
                        tableUI: true,
                    },
                    theme: 'snow',
                });

                setQuillInstance(quill);
            }
        } else if (quillInstance) {
            // If the container is hidden and Quill is initialized, destroy it
            quillInstance.off('text-change');
            quillInstance.disable();
            setQuillInstance(null); // Update the state variable
        }
    }, [isContainerVisible]);

    useEffect(() => {

        let quillInstance;

        if (showModal && quillRef.current) {
            const Font = Quill.import('formats/font');
            Font.whitelist = ['serif', 'sans-serif', 'monospace', 'times-new-roman', 'arial', 'tahoma', 'helvetica'];
            Quill.register(Font, true);
            Quill.register({
                'modules/tableUI': QuillTableUI.default
            }, true)

            const toolbarOptions = [
                ["bold", "italic", "underline", "strike"],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "+1" }, { indent: "-1" }],
                [{ align: [] }],
                [{ size: ["small", "large", "huge", false] }],
                ["image", "link", "video", "formula"],
                [{ color: [] }, { background: [] }],
                [{ font: ["arial", "helvetica", "serif", "sans-serif", "monospace", "times-new-roman", "tahoma"] }],
                ["code-block", "blockquote"]
            ];

            quillInstance = new Quill(quillRef.current, {
                modules: {
                    toolbar: toolbarOptions,
                    table: true,
                    tableUI: true,
                },
                theme: 'snow',
            });

            quillInstance.on('text-change', () => {
                const html = quillInstance.root.innerHTML;
                setHtmlCode(html); // Update the state for the HTML textarea
            });
        }
        return () => {
            if (quillInstance) {
                quillInstance.off('text-change');
                quillInstance.disable();
                quillInstance = null;
            }
        };
    }, [showModal, quillInstance, quillRef]);

    const handleHtmlChange = (e) => {
        const html = e.target.value;
        setHtmlCode(html); // Update the state to reflect changes in the textarea
        if (quillInstance) {
            quillInstance.root.innerHTML = html; // Update Quill's content
        }
    };



    // Assuming you have a reference to Quill editor instance (quillInstance)
    useEffect(() => {
        if (quillInstance && nuevoForoRespuesta.descripcion) {
            const currentContent = quillInstance.root.innerHTML;
            // Check if the content is the same to prevent infinite loop
            if (currentContent !== nuevoForoRespuesta.descripcion) {
                // Set the new content
                quillInstance.clipboard.dangerouslyPasteHTML(nuevoForoRespuesta.descripcion);
                // Move the caret to the end of the editor
                const range = quillInstance.getLength();
                quillInstance.setSelection(range, Quill.sources.SILENT);
            }
        }
    }, [quillInstance, nuevoForoRespuesta.descripcion]); // Dependencies array to avoid infinite loop

    useEffect(() => {
        if (quillInstance) {
            quillInstance.on("text-change", () => {
                const html = quillInstance.root.innerHTML;
                setEditorContent(html);

                // Decide whether to call handleEdit or handleAdd based on your use case
                if (isEditing) {
                    handleEdit(html);
                } else {
                    handleAdd(html);
                }
            });
        }
    }, [quillInstance]);

    // Define the recursive renderReplies function
    const renderReplies = (respuesta, level = 0) => {
        // Extract user information for the current reply
        const infoRespuesta = infosforosrespuestas[respuesta.idUsuario];

        // Calculate padding based on the nesting level
        const paddingStyle = level > 0 ? { paddingLeft: '50px' } : {};

        return (
            <div key={respuesta.idForos_respuesta} style={{ ...forumStyles.forumThreadNormal, ...paddingStyle }}>
                <div style={forumStyles.forumDiscussionContainer}>
                    {infoRespuesta && (
                        <div id="forumDiscussionPadding">
                            <span style={forumStyles.forumDiscussionfyiright}>Creado Por : </span>
                            <span style={forumStyles.forumDiscussioncreator}>{infoRespuesta.nombres} {infoRespuesta.apellidos}</span>
                        </div>
                    )}
                    <div style={forumStyles.forumDiscussionfyiright}>
                        {formatDate(respuesta.fecha_registro)}
                    </div>

                </div>

                <div style={forumStyles.forumHtml} dangerouslySetInnerHTML={{ __html: respuesta.descripcion }}></div>

                {/* Dropdown button and other elements can go here as well */}
                <div style={actionContainerStyle}>
                    {/* Dropdown button */}
                    {parseInt(idUsuariod, 10) === parseInt(respuesta.idUsuario, 10) && (
                        <div className="dropdown">
                            <button className="dropdown-btn" style={dropdownButtonStyle} onClick={() => toggleDropdown(respuesta.idForos_respuesta)}>
                                {dropdownVisibility[respuesta.idForos_respuesta] ? (
                                    <span><FaChevronUp /> Cerrar acciones</span>
                                ) : (
                                    <span><FaChevronDown /> Mostrar acciones </span>
                                )}
                            </button>
                            {dropdownVisibility[respuesta.idForos_respuesta] && (
                                <div className="dropdown-content" style={dropdownContentStyle}>
                                    <Button variant="warning" style={{ marginRight: '8px' }} onClick={() => abrireditarForo(respuesta, respuesta.idForos_respuesta, respuesta.fecha_registro)}>Editar</Button>
                                    <Button variant="danger" onClick={() => eliminarForo(respuesta.idForos_respuesta)}>Eliminar</Button>
                                </div>
                            )}
                        </div>
                    )}

                    {/* 'Responder' link aligned with the dropdown button */}
                    {infoRespuesta && (
                        <a href="#" style={dropdownLinkStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                            onClick={() => handleResponderClick(respuesta.idForos_respuesta, infoRespuesta.idUsuario)}>
                            Responder
                        </a>
                    )}
                </div>

                {/* Recursive rendering for child replies */}
                {forosrespuestas[respuesta.idForos_respuesta] && (
                    <div>
                        {forosrespuestas[respuesta.idForos_respuesta].map(childRespuesta => renderReplies(childRespuesta, level + 1))}
                    </div>
                )}
            </div>
        );
    };

    useEffect(() => {
        // Update nuevoForoRespuesta whenever idforo changes
        setNuevoForoRespuesta((prevNuevoForoRespuesta) => ({
            ...prevNuevoForoRespuesta,
            idforo: idforo,
        }));
    }, [idforo]);

    useEffect(() => {
        // Define an async function within the useEffect
        const fetchData = async () => {
            // Assuming you have a state to manage loading

            await fetchIdModulos();
            await fetchForos();
            await fetchForosRespuestasAmbos(); // Without IDFORO_PADRE
        };

        fetchData(); // Call the async function

        // Note: If `nuevoForoRespuesta` changes and triggers this effect,
        // you may want to cancel any ongoing API requests from the previous effect run.
    }, [nuevoForoRespuesta]); // Only re-run the effect if `nuevoForoRespuesta` changes


    useEffect(() => {
        // Count `forosrespuesta`
        const forosrespuestaCount = forosrespuesta.length;

        // Count `forosrespuestas`
        let forosRespuestasHijoCount = 0;
        Object.keys(forosrespuestas).forEach(key => {
            forosRespuestasHijoCount += forosrespuestas[key].length;
        });

        // Update the total count
        setTotalResponses(forosrespuestaCount + forosRespuestasHijoCount);
    }, [forosrespuesta, forosrespuestas]); // Dependencies array to re-run the effect when data changes

    useEffect(() => {
        const token = cookies.get('token');
        if (token) {
            const decodedToken = jwt_decode(token);
            const idUsuarioFromToken = decodedToken.nameid;
            setIdUsuariod(idUsuarioFromToken);
        } else {
            console.log('No token found!');
        }
    }, []);

    return (
        <Fragment>
            <hr />
            <br />
            <Container style={forumStyles.forumContainer}>
                <div key={foros.idForo} style={forumStyles.forumThread}>
                    <h1 style={forumStyles.forumTitle}>{foros.titulo}</h1>
                    <div style={forumStyles.forumDiscussionContainer}>
                        <div style={forumStyles.forumDiscussionfyi}></div>
                        <div style={forumStyles.forumDiscussionfyiright}>
                            {formatDate(foros.fecha_inicio)}
                        </div>
                    </div>
                    <div style={forumStyles.forumMainDescription} dangerouslySetInnerHTML={{ __html: foros.descripcion }}></div>
                </div>
                <br />
                <div style={forumStyles.forumDiscussionContainerv2}>
                    <hr className='ocultarhr' />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={forumStyles.forumDiscussionfyicoment}>Comentarios {totalResponses}</div>
                        <Button
                            variant="btn-outline-primary"
                            style={{
                                borderColor: '#FFF',
                                color: '#FFF',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                textAlign: 'right',
                                marginLeft: 'auto'
                            }}
                            onClick={toggleContainerVisibility}
                        >
                            {isContainerVisible ? <FaChevronDown color="#F47735" /> : <FaChevronUp color="#F47735" />}
                        </Button>
                        <hr className='ocultarhr' />
                    </div>
                </div>

                {/* //-------------------------------------------------------------------------------------------------------- */}
                {isContainerVisible && (
                    <div>
                        <form onSubmit={agregarForo}>
                            <hr />
                            <div>
                                <h2>{editarForoRespuesta ? "Editando:" : "Respondiendo:"}</h2>
                                <br />
                                {/* Conditionally render the Abort button */}
                                {editarForoRespuesta && (
                                    <>
                                        <Button variant="danger" style={{ marginLeft: '8px' }} onClick={cancelarEdicion}>
                                            Cancelar Edición
                                        </Button>
                                        <br />
                                        <br />
                                    </>
                                )}
                                {usuarioId === 4 || usuarioId === 5 ? (
                                    <>
                                        <Button variant="info" style={{ marginRight: '8px' }} onClick={handleShowHtmlModal}>
                                            Cambiar vista a HTML
                                        </Button>
                                        {/* <Button variant="warning" onClick={createTable}>Crear tabla</Button> */}

                                    </>
                                ) : null}
                            </div>
                            <br></br>
                            <div className="align-items-center justify-content-between mb-3">
                                <div id="editando" ref={quillRef}>
                                    <p><br /></p>
                                </div>
                                <br />
                            </div>
                            <hr style={{ marginTop: '1px' }} />
                            <div className="input-group">
                                <button type="submit" className={`btn btn-block mybtn tx-tfm ${editarForoRespuesta ? 'btn-guardar' : 'naranjabtn'}`}>
                                    {editarForoRespuesta ? "Guardar cambios" : "Agregar Respuesta"}
                                </button>
                            </div>
                            <hr className='ocultarhr' />
                        </form>
                        <Modal show={showHtmlModal} onHide={handleCloseHtmlModal} size="lg"> {/* Set the size to large */}
                            <Modal.Header className="centered-title" closeButton>
                                <Modal.Title>Editor HTML</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <textarea
                                    rows="10"
                                    value={htmlCode}
                                    onChange={(e) => setHtmlCode(e.target.value)}
                                    className="custom-textarea"
                                    spellCheck="false"
                                />
                            </Modal.Body>
                            <Modal.Footer className="justify-content-center">
                                <Button variant="danger" onClick={handleCloseHtmlModal}>
                                    Cerrar
                                </Button>
                                <Button variant="success" onClick={() => {
                                    updateQuillContent(htmlCode);
                                    handleSaveHtmlChanges();
                                }}>
                                    Guardar cambios
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <br />
                        {/* //------------------------------------------------------RESPUESTAS-------------------------------------------------- */}
                        {forosrespuesta.map(respuesta => renderReplies(respuesta))}
                        {/* //-------------------------------------------------------------------------------------------------------- */}
                    </div>
                )}
            </Container>
            <Modal show={showModal} onHide={cancelarRerespuesta} backdrop="static" keyboard={false} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Respondiendo:</Modal.Title>
                    <button type="button" className="btn-close" onClick={cancelarRerespuesta} aria-label="Close"><FaWindowClose color="#c82333" onClick={cancelarRerespuesta} style={{ cursor: 'pointer' }} /></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="align-items-center justify-content-between mb-3">
                        <div id="editor" ref={quillRef}>
                            <p><br /></p>
                        </div>
                    </div>
                    <hr />
                    <div className="editor-container">
                        <div className="align-items-center justify-content-between mb-3">
                            <div id="editor" ref={quillRef}>
                                <p><br /></p>
                            </div>
                        </div>
                        <hr />
                        {/* HTML Editor */}
                        <div className="html-editor">
                            <textarea
                                value={htmlCode}
                                onChange={(e) => setHtmlCode(e.target.value)}
                                className="custom-textareaV2"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={submitReply}>
                        Enviar Respuesta
                    </Button>
                </Modal.Footer>
            </Modal>


            <br />
        </Fragment>
    );
}

export default withRouter(CursoForoDiscu);