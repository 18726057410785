import React, { Fragment, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withRouter } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { FaPlus, FaMinus, FaPencilAlt, FaTrash, FaSearch, FaWindowClose } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function AdminCursos(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;

    const cookies = new Cookies();
    const [cursos, setCursos] = useState([]);
    const [cursosdocentes, setCursosdocentes] = useState([]);
    const [cursosestudiantes, setCursosestudiantes] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
    const [docentesEncontrados, setdocentesEncontrados] = useState([]);
    const [docenteSeleccionado, setDocenteSeleccionado] = useState([]);
    const [estudianteSeleccionado, setEstudianteSeleccionado] = useState([]);
    const [checkboxesSeleccionados, setCheckboxesSeleccionados] = useState({});
    const [nuevoCursoDocente, setNuevoCursoDocente] = useState([]);
    const [nuevoCursoEstudiante, setNuevoCursoEstudiante] = useState([]);
    const [soloCurso, setSoloCurso] = useState(true);
    const [conDocente, setConDocente] = useState(false);
    const [conEstudiante, setConEstudiante] = useState(false);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const [mostrarPestanaEstudiantes, setMostrarPestanaEstudiantes] = useState(false);
    const [mostrarPestanaDocentes, setMostrarPestanaDocentes] = useState(false);
    const [selectedEstudiantes, setSelectedEstudiantes] = useState(new Set());
    const [selectedIds, setSelectedIds] = useState(new Set());


    const [nuevoCurso, setNuevoCurso] = useState({
        idCurso: '',
        nom_curso: '',
        descripcion: '',
        fecha_inicio: '',
        fecha_fin: '',
        estado: false
    });

    //---------------------------------------Agregando datos----------------------------------------------

    // Capturando datos para el nuevoCurso, nuevoCursoDocente y nuevoCursoEstudiante.
    const cursoNuevo = {
        Cursos: {
            nom_curso: nuevoCurso.nom_curso,
            descripcion: nuevoCurso.descripcion,
            estado: nuevoCurso.estado,
            fecha_fin: nuevoCurso.fecha_fin,
            fecha_inicio: nuevoCurso.fecha_inicio
        },
        Cursos_docente: nuevoCursoDocente.map((id) => parseInt(id)),
        Cursos_estudiante: nuevoCursoEstudiante.map((id) => parseInt(id))
    };

    // Capturando datos para el nuevoCurso.
    const cursoNuevo1 = {
        nom_curso: nuevoCurso.nom_curso,
        descripcion: nuevoCurso.descripcion,
        estado: nuevoCurso.estado,
        fecha_fin: nuevoCurso.fecha_fin,
        fecha_inicio: nuevoCurso.fecha_inicio
    };

    // Capturando datos para el nuevoCurso y nuevoCursoDocente.
    const CursoNuevo2 = {
        Cursos: {
            nom_curso: nuevoCurso.nom_curso,
            descripcion: nuevoCurso.descripcion,
            estado: nuevoCurso.estado,
            fecha_fin: nuevoCurso.fecha_fin,
            fecha_inicio: nuevoCurso.fecha_inicio
        },
        Cursos_docente: nuevoCursoDocente.map((id) => parseInt(id))
    };

    // Capturando datos para el nuevoCurso y nuevoCursoEstudiante.
    const cursoNuevo3 = {
        Cursos: {
            nom_curso: nuevoCurso.nom_curso,
            descripcion: nuevoCurso.descripcion,
            estado: nuevoCurso.estado,
            fecha_fin: nuevoCurso.fecha_fin,
            fecha_inicio: nuevoCurso.fecha_inicio
        },
        Cursos_estudiante: nuevoCursoEstudiante.map((id) => parseInt(id))
    };

    //---------------------------------------------------------------------------------------
    //---------------------------------------Editando datos----------------------------------

    // Editando datos para el nuevoCurso, nuevoCursoDocente y nuevoCursoEstudiante.
    const cursoEditar = {
        Cursos: {
            idCurso: nuevoCurso.idCurso,
            nom_curso: nuevoCurso.nom_curso,
            descripcion: nuevoCurso.descripcion,
            estado: nuevoCurso.estado,
            fecha_fin: nuevoCurso.fecha_fin,
            fecha_inicio: nuevoCurso.fecha_inicio
        },
        Cursos_docente: nuevoCursoDocente.map((id) => parseInt(id)),
        Cursos_estudiante: nuevoCursoEstudiante.map((id) => parseInt(id))
    };


    // Editando datos para el nuevoCurso.
    const cursoEditar1 = {
        idCurso: nuevoCurso.idCurso,
        nom_curso: nuevoCurso.nom_curso,
        descripcion: nuevoCurso.descripcion,
        estado: nuevoCurso.estado,
        fecha_fin: nuevoCurso.fecha_fin,
        fecha_inicio: nuevoCurso.fecha_inicio
    };

    // Editando datos para el nuevoCurso y nuevoCursoDocente.
    const cursoEditar2 = {
        Cursos: {
            idCurso: nuevoCurso.idCurso,
            nom_curso: nuevoCurso.nom_curso,
            descripcion: nuevoCurso.descripcion,
            estado: nuevoCurso.estado,
            fecha_fin: nuevoCurso.fecha_fin,
            fecha_inicio: nuevoCurso.fecha_inicio
        },
        Cursos_docente: nuevoCursoDocente.map((id) => parseInt(id))
    };

    // Editando datos para el nuevoCurso y nuevoCursoEstudiante.
    const cursoEditar3 = {
        Cursos: {
            idCurso: nuevoCurso.idCurso,
            nom_curso: nuevoCurso.nom_curso,
            descripcion: nuevoCurso.descripcion,
            estado: nuevoCurso.estado,
            fecha_fin: nuevoCurso.fecha_fin,
            fecha_inicio: nuevoCurso.fecha_inicio
        },
        Cursos_estudiante: nuevoCursoEstudiante.map((id) => parseInt(id))
    };

    //---------------------------------------------------------------------------------------
    const resetForm = () => {
        setNuevoCurso({
            nom_curso: '',
            descripcion: '',
            fecha_inicio: '',
            fecha_fin: '',
            estado: false
        });
        setNuevoCursoDocente([]);
        setDocenteSeleccionado([]);

        setEstudianteSeleccionado([]);
        setNuevoCursoEstudiante([]);

        setCheckboxesSeleccionados({});
        // Restablecer otras variables relacionadas si las hay
    };


    //---------------------CODIGO PENDIENTE DE SELECCIONAR ESTUDIANTES----------------------------
    // Estado para almacenar todos los Docentes
    const [docentes, setDocentes] = useState([]);

    // Estado para almacenar todos los Estudiantes
    const [estudiantes, setEstudiantes] = useState([]);

    // Estado para cargar solo 8 docentes por página
    const [currentPageDocentes, setCurrentPageDocentes] = useState(1);
    const [currentPageEstudiantes, setCurrentPageEstudiantes] = useState(1);
    const docentesPerPage = 8;
    const estudiantesPerPage = 8;

    // Cargar solo 8 docentes por página
    const indexOfLastDocente = currentPageDocentes * docentesPerPage;
    const indexOfFirstDocente = indexOfLastDocente - docentesPerPage;

    const indexOfLastEstudiante = currentPageEstudiantes * estudiantesPerPage;
    const indexOfFirstEstudiante = indexOfLastEstudiante - estudiantesPerPage;

    const currentDocentes = docentes.slice(indexOfFirstDocente, indexOfLastDocente);
    const currentEstudiantes = docentes.slice(indexOfFirstEstudiante, indexOfLastEstudiante);

    const totalPages = Math.ceil(docentes.length / docentesPerPage);
    const totalPagesE = Math.ceil(estudiantes.length / estudiantesPerPage);

    const handlePageChangeDocentes = (newPage) => {
        setCurrentPageDocentes(newPage);
    };

    const handlePageChangeEstudiantes = (newPage) => {
        setCurrentPageEstudiantes(newPage);
    };

    // Estados para los filtros de nombre, apellido y documento
    const [filtroNombre, setFiltroNombre] = useState('');
    const [filtroApellido, setFiltroApellido] = useState('');
    const [filtroDocumento, setFiltroDocumento] = useState('');

    // Función para manejar la selección de un docentes por su ID
    const handleSeleccionarDocente = (id) => {
        // Actualiza el estado de isSelected del docente y cambia el estado del checkbox seleccionado
        setDocentes((prevState) =>
            prevState.map((docente) =>
                docente.idColaborador === id
                    ? { ...docente, isSelected: !docente.isSelected }
                    : docente
            )
        );

        // Actualiza el estado de los checkboxes
        setCheckboxesSeleccionados((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));

    };

    const handleSeleccionarEstudiante = (idColaborador) => {
        setEstudiantes(prevEstudiantes =>
            prevEstudiantes.map(estudiante =>
                estudiante.idColaborador === idColaborador
                    ? { ...estudiante, isSelected: !estudiante.isSelected }
                    : estudiante
            )
        );

        setSelectedIds(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(idColaborador)) {
                newSelected.delete(idColaborador);
            } else {
                newSelected.add(idColaborador);
            }
            return newSelected;
        });
    };

    const isChecked = (id) => {
        return checkboxesSeleccionados[id] === true;
    };

    // Función para obtener los docentes desde el backend
    const fetchDocentes = async () => {
        const token = cookies.get('token');
        const result = await axios.get(baseUrl + "colaboradores/docentes", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        // Inicializa la propiedad isSelected en false para cada docente
        const docentesConEstado = result.data.map((docente) => ({
            ...docente,
            isSelected: false,
        }));

        setDocentes(docentesConEstado);
    };

    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 80; // Tamaño de página fijo o ajustable según necesidad

    useEffect(() => {
        fetchEstudiantes(pageNumber, pageSize);
    }, [pageNumber]); // Revisar solo los cambios en el número de página

    const fetchEstudiantes = async (pageNumber, pageSize) => {
        const token = cookies.get('token');
        try {
            const result = await axios.get(baseUrl + `colaboradores/estudiantes?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            // Combinar los datos recibidos con los seleccionados previamente
            const estudiantesConEstado = result.data.map((estudiante) => ({
                ...estudiante,
                isSelected: selectedIds.has(estudiante.idColaborador),
            }));

            setEstudiantes(estudiantesConEstado);
        } catch (error) {
            console.error('Error fetching estudiantes:', error);
        }
    };

    const goToNextPage = () => {
        setPageNumber(pageNumber + 1);
    };

    const goToPreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    // Funciones para manejar los filtros de nombre, apellido y documento
    const handleFiltroNombre = (e) => {
        const filtro = e.target.value;
        setFiltroNombre(filtro); // Actualiza el filtro de nombre
        setCurrentPageDocentes(1);
    };

    const handleFiltroApellido = (e) => {
        const filtro = e.target.value;
        setFiltroApellido(filtro); // Actualiza el filtro de apellido
        setCurrentPageDocentes(1);
    };

    const handleFiltroDocumento = (e) => {
        const filtro = e.target.value;
        setFiltroDocumento(filtro); // Actualiza el filtro de documento
        setCurrentPageDocentes(1);
    };

    const docentesFiltrados = docentes.filter((docente) =>
        docente.nombres.toLowerCase().includes(filtroNombre.toLowerCase()) &&
        docente.apellidos.toLowerCase().includes(filtroApellido.toLowerCase()) &&
        docente.nro_documento.includes(filtroDocumento)
    );

    const estudiantesFiltrados = estudiantes.filter((estudiante) =>
        estudiante.nombres.toLowerCase().includes(filtroNombre.toLowerCase()) &&
        estudiante.apellidos.toLowerCase().includes(filtroApellido.toLowerCase()) &&
        estudiante.nro_documento.includes(filtroDocumento)
    );

    const handleEnrolarDocentes = () => {

        const docentesSeleccionados = docentes.filter((docente) => docente.isSelected);
        const docenteIds = docentesSeleccionados.map((docente) => docente.idColaborador);
        setDocenteSeleccionado(docenteIds);
        setNuevoCursoDocente(docenteIds);

        if (docenteSeleccionado.length > 0 || nuevoCursoDocente.lenght > 0) {
            setSoloCurso(false);
            setConDocente(true);
        } else if (docenteSeleccionado.length < 0 || nuevoCursoDocente.lenght < 0) {
            setConDocente(false);
        }

        // Cierra el popup o realiza otras acciones necesarias
        setShowInnerPopup(false);
    };

    const handleEnrolarEstudiantes = () => {

        const estudiantesSeleccionados = estudiantes.filter((estudiante) => estudiante.isSelected);
        const estudianteIds = estudiantesSeleccionados.map((estudiante) => estudiante.idColaborador);
        setEstudianteSeleccionado(estudianteIds);
        setNuevoCursoEstudiante(estudianteIds);

        if (estudianteSeleccionado.length > 0 || nuevoCursoEstudiante.length > 0) {
            setSoloCurso(false);
            setConEstudiante(true);
        } else if (estudianteSeleccionado.length > 0 || nuevoCursoEstudiante.length > 0) {
            setConEstudiante(false);
        }
        // Cierra el popup o realiza otras acciones necesarias
        setShowInnerPopupE(false);
    };

    //-------------------------------------------------------------------------------------------

    const togglePopup = () => {
        setEditarCurso(false)
        resetForm()
        setShowPopup(!showPopup);
    };

    //Abrir segundo popup
    const [showInnerPopup, setShowInnerPopup] = useState(false);

    // Función para mantener la selección de docentes al abrir el popup
    const mantenerSeleccionD = () => {
        // Recorre los IDs de los docentes seleccionados almacenados en docenteSeleccionado
        docenteSeleccionado.forEach((id) => {
            // Verifica si el ID coincide con algún docente en docentes
            const docenteCoincidente = docentes.find((docente) => docente.idColaborador === id);
            if (docenteCoincidente) {
                // Si se encontró un docente coincidente, establece isSelected en true
                setDocentes((prevState) =>
                    prevState.map((docente) =>
                        docente.idColaborador === id
                            ? { ...docente, isSelected: true }
                            : docente
                    )
                );
            }
        });
    };

    // Popup docentes

    const openInnerPopup = () => {
        // Llama a la función para mantener la selección antes de abrir el popup
        mantenerSeleccionD();

        // Establece la página actual en 1
        handlePageChangeDocentes(1);

        setShowInnerPopup(true)
    };

    const closeInnerPopup = () => {
        // Actualiza el estado de todos los docentes para establecer isSelected en false
        const updatedDocentes = docentes.map((docente) => ({
            ...docente,
            isSelected: false,
        }));

        setConDocente(false);

        setDocentes(updatedDocentes); // Actualiza el estado de docentes
        setNuevoCursoDocente([]);
        setDocenteSeleccionado([]);

        setCheckboxesSeleccionados({});

        setShowInnerPopup(false);
    };

    // Popup Estudiantes

    const openInnerPopupE = () => {
        // Llama a la función para mantener la selección antes de abrir el popup
        mantenerSeleccionE();

        // Establece la página actual en 1
        handlePageChangeEstudiantes(1);

        setShowInnerPopupE(true)
    };

    const closeInnerPopupE = () => {

        // Actualiza el estado de todos los estudiantes para establecer isSelected en false
        const updatedEstudiantes = estudiantes.map((estudiante) => ({
            ...estudiante,
            isSelected: false,
        }));

        setConEstudiante(false);

        setEstudiantes(updatedEstudiantes); // Actualiza el estado de estudiantes

        setEstudianteSeleccionado([]);
        setNuevoCursoEstudiante([]);

        setCheckboxesSeleccionados({});

        setShowInnerPopupE(false);
    };

    //Abrir tercer popup
    const [showInnerPopupE, setShowInnerPopupE] = useState(false);

    // Función para mantener la selección de estudiantes al abrir el popup
    const mantenerSeleccionE = () => {
        // Recorre los IDs de los estudiantes seleccionados almacenados en estudianteSeleccionado
        estudianteSeleccionado.forEach((id) => {
            // Verifica si el ID coincide con algún estudiante en estudiante
            const estudianteCoincidente = estudiantes.find((estudiante) => estudiante.idColaborador === id);
            if (estudianteCoincidente) {
                // Si se encontró un estudiante coincidente, establece isSelected en true
                setEstudiantes((prevState) =>
                    prevState.map((estudiante) =>
                        estudiante.idColaborador === id
                            ? { ...estudiante, isSelected: true }
                            : estudiante
                    )
                );
            }
        });
    };

    //

    // Esta función verifica las condiciones y actualiza soloCurso en consecuencia
    const verificarCondiciones = () => {
        if (!conEstudiante && !conDocente) {
            setSoloCurso(true);
        } else {
            setSoloCurso(false);
        }
    };

    const fetchCursos = async () => {
        try {
            const token = cookies.get('token');
            const result = await axios.get(baseUrl + "cursos/menuCursos", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setCursosdocentes(result.data.cursosdocentes);
                setCursosestudiantes(result.data.cursosestudiantes);
                setCursos(result.data.cursosPanel);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    // Función para extraer el identificador de un vídeo de YouTube a partir de una URL
    function getYouTubeVideoId(url) {
        // Definición de expresiones regulares para coincidir con distintos formatos de URL de YouTube
        const patterns = [
            /(?:https:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/,
            /(?:https:\/\/)?(?:www\.)?youtube\.com\/embed\/([^?]+)/,
            /(?:https:\/\/)?youtu.be\/([^?]+)/
        ];

        for (const pattern of patterns) {
            const match = url.match(pattern);
            if (match) {
                return match[1];
            }
        }

        // Devuelve null si no se encuentra ninguna coincidencia
        return null;
    }

    // Función para extraer un ID de vídeo de Google Drive a partir de una URL
    function extractGoogleDriveVideoId(url) {
        // Expresión regular para el formato de URL de Google Drive
        const googleDrivePattern = /https:\/\/drive\.google\.com\/file\/d\/([^/]+)\/view/;

        const googleDriveMatch = url.match(googleDrivePattern);
        if (googleDriveMatch) {
            return googleDriveMatch[1];
        }

        return null; // Devuelve null si la URL no coincide con ningún formato conocido
    }


    // Agregar nuevo curso:
    const handleChange = (event) => {
        const { name, value } = event.target;
        const gugolid = extractGoogleDriveVideoId(value);
        const videoId = getYouTubeVideoId(value);
        // Update the nuevoCurso state with the extracted values
        // Update the state accordingly
        setNuevoCurso((prevState) => ({
            ...prevState,
            [name]: name === "enlace" ? videoId : value,
            imagen_curso: name === "imagen_curso" ? gugolid : nuevoCurso.imagen_curso,
        }));
    };


    const agregarCurso = async (event) => {
        event.preventDefault(); // Cancelar el evento por defecto
        try {
            if (editarCurso) {
                // EDITAR CURSO
                //
                // Establecer los valores del curso seleccionado al formulario popup
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevoCurso.idCurso ||
                    !nuevoCurso.nom_curso ||
                    !nuevoCurso.descripcion ||
                    !nuevoCurso.fecha_inicio ||
                    !nuevoCurso.fecha_fin
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                if (soloCurso) {
                    // Llamar a la API para agregar solo un curso nuevo
                    const response = await axios.post(
                        baseUrl + 'cursos/editarCurso1',
                        cursoEditar1,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el curso exitosamente
                    // cerrar el popup y volver a cargar la lista de cursos
                    if (response.status === 201 || response.status === 200) {
                        let timerInterval;
                        Swal.fire({
                            icon: 'success',
                            title: 'Actualización exitosa',
                            html: 'Curso actualizado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                            timer: 5000, // countdown duration in milliseconds
                            timerProgressBar: true,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer();
                                    const b = content ? content.querySelector('b') : null;
                                    if (b) {
                                        // Update the text to show the countdown in seconds
                                        b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                    }
                                }, 100); // Update every 100 milliseconds
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            }
                        }).then((result) => {
                            // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('I was closed by the timer');
                            }
                            window.location.href = '/menu';
                        });
                        togglePopup();
                        fetchCursos();
                        resetForm();
                    }
                } else if (conDocente && !conEstudiante) {
                    // Llamar a la API para agregar un curso y docentes nuevo
                    const response = await axios.post(
                        baseUrl + 'cursos/editarCurso2',
                        cursoEditar2,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el curso exitosamente
                    // cerrar el popup y volver a cargar la lista de cursos
                    if (response.status === 201 || response.status === 200) {
                        let timerInterval;
                        Swal.fire({
                            icon: 'success',
                            title: 'Actualización exitosa',
                            html: 'Curso actualizado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                            timer: 5000, // countdown duration in milliseconds
                            timerProgressBar: true,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer();
                                    const b = content ? content.querySelector('b') : null;
                                    if (b) {
                                        // Update the text to show the countdown in seconds
                                        b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                    }
                                }, 100); // Update every 100 milliseconds
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            }
                        }).then((result) => {
                            // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('I was closed by the timer');
                            }
                            window.location.href = '/menu';
                        });
                        togglePopup();
                        fetchCursos();
                        resetForm();
                    }
                } else if (!conDocente && conEstudiante) {
                    // Llamar a la API para agregar un curso y estudiantes nuevo
                    const response = await axios.post(
                        baseUrl + 'cursos/editarCurso3',
                        cursoEditar3,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el curso exitosamente
                    // cerrar el popup y volver a cargar la lista de cursos
                    if (response.status === 201 || response.status === 200) {
                        let timerInterval;
                        Swal.fire({
                            icon: 'success',
                            title: 'Actualización exitosa',
                            html: 'Curso actualizado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                            timer: 5000, // countdown duration in milliseconds
                            timerProgressBar: true,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer();
                                    const b = content ? content.querySelector('b') : null;
                                    if (b) {
                                        // Update the text to show the countdown in seconds
                                        b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                    }
                                }, 100); // Update every 100 milliseconds
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            }
                        }).then((result) => {
                            // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('I was closed by the timer');
                            }
                            window.location.href = '/menu';
                        });
                        togglePopup();
                        fetchCursos();
                        resetForm();
                    }
                } else {
                    const response = await axios.post(
                        baseUrl + 'cursos/editarCurso',
                        cursoEditar,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el curso exitosamente
                    // Por ejemplo, cerrar el popup y volver a cargar la lista de cursos
                    if (response.status === 201 || response.status === 200) {
                        let timerInterval;
                        Swal.fire({
                            icon: 'success',
                            title: 'Actualización exitosa',
                            html: 'Curso actualizado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                            timer: 5000, // countdown duration in milliseconds
                            timerProgressBar: true,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer();
                                    const b = content ? content.querySelector('b') : null;
                                    if (b) {
                                        // Update the text to show the countdown in seconds
                                        b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                    }
                                }, 100); // Update every 100 milliseconds
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            }
                        }).then((result) => {
                            // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('I was closed by the timer');
                            }
                            window.location.href = '/menu';
                        });
                        togglePopup();
                        fetchCursos();
                        resetForm();
                    }
                }
            } else {
                // AGREGAR CURSO
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevoCurso.nom_curso ||
                    !nuevoCurso.descripcion ||
                    !nuevoCurso.fecha_inicio ||
                    !nuevoCurso.fecha_fin
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                if (soloCurso) {
                    // Llamar a la API para agregar solo un curso nuevo
                    const response = await axios.post(
                        baseUrl + 'cursos/registrarCurso1',
                        cursoNuevo1,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el curso exitosamente
                    // cerrar el popup y volver a cargar la lista de cursos
                    if (response.status === 201 || response.status === 200) {
                        let timerInterval;
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            html: 'Curso Creado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                            timer: 5000, // countdown duration in milliseconds
                            timerProgressBar: true,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer();
                                    const b = content ? content.querySelector('b') : null;
                                    if (b) {
                                        // Update the text to show the countdown in seconds
                                        b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                    }
                                }, 100); // Update every 100 milliseconds
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            }
                        }).then((result) => {
                            // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('I was closed by the timer');
                            }
                            window.location.href = '/menu';
                        });
                        togglePopup();
                        fetchCursos();
                        resetForm();
                    }
                } else if (conDocente && !conEstudiante) {
                    // Llamar a la API para agregar un curso y docentes nuevo
                    const response = await axios.post(
                        baseUrl + 'cursos/cursoNuevo2',
                        CursoNuevo2,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el curso exitosamente
                    // cerrar el popup y volver a cargar la lista de cursos
                    if (response.status === 201 || response.status === 200) {
                        let timerInterval;
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            html: 'Curso Creado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                            timer: 5000, // countdown duration in milliseconds
                            timerProgressBar: true,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer();
                                    const b = content ? content.querySelector('b') : null;
                                    if (b) {
                                        // Update the text to show the countdown in seconds
                                        b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                    }
                                }, 100); // Update every 100 milliseconds
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            }
                        }).then((result) => {
                            // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('I was closed by the timer');
                            }
                            window.location.href = '/menu';
                        });
                        togglePopup();
                        fetchCursos();
                        resetForm();
                    }
                } else if (!conDocente && conEstudiante) {
                    // Llamar a la API para agregar un curso y estudiantes nuevo
                    const response = await axios.post(
                        baseUrl + 'cursos/registrarCurso3',
                        cursoNuevo3,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el curso exitosamente
                    // cerrar el popup y volver a cargar la lista de cursos
                    if (response.status === 201 || response.status === 200) {
                        let timerInterval;
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            html: 'Curso Creado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                            timer: 5000, // countdown duration in milliseconds
                            timerProgressBar: true,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer();
                                    const b = content ? content.querySelector('b') : null;
                                    if (b) {
                                        // Update the text to show the countdown in seconds
                                        b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                    }
                                }, 100); // Update every 100 milliseconds
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            }
                        }).then((result) => {
                            // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('I was closed by the timer');
                            }
                            window.location.href = '/menu';
                        });
                        togglePopup();
                        fetchCursos();
                        resetForm();
                    }
                } else {
                    // Llamar a la API para agregar un curso, docentes y estudiantes
                    const response = await axios.post(
                        baseUrl + 'cursos/registrarCurso',
                        cursoNuevo,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el curso exitosamente
                    // cerrar el popup y volver a cargar la lista de cursos
                    if (response.status === 201 || response.status === 200) {
                        let timerInterval;
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            html: 'Curso Creado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                            timer: 5000, // countdown duration in milliseconds
                            timerProgressBar: true,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getHtmlContainer();
                                    const b = content ? content.querySelector('b') : null;
                                    if (b) {
                                        // Update the text to show the countdown in seconds
                                        b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                    }
                                }, 100); // Update every 100 milliseconds
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            }
                        }).then((result) => {
                            // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('I was closed by the timer');
                            }
                            window.location.href = '/menu';
                        });
                    }
                    togglePopup();
                    fetchCursos();
                    resetForm();
                }
            }
        } catch (error) {
            // MANEJO DE ERRORES
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };

    const eliminarCurso = async (idCurso) => {
        Swal.fire({
            title: '¿Estás seguro de que deseas ELIMINAR este curso?',
            text: 'El curso y su contenido no se podrán recuperar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const request = {
                        idCurso: idCurso
                    };
                    const token = cookies.get('token');
                    const response2 = await axios.post(baseUrl + 'cursos/eliminarCurso', request, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    // Mostrar Sweet Alert
                    let timerInterval;
                    Swal.fire({
                        icon: 'success',
                        html: 'Curso eliminado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                        timer: 5000, // countdown duration in milliseconds
                        timerProgressBar: true,
                        didOpen: () => {
                            timerInterval = setInterval(() => {
                                const content = Swal.getHtmlContainer();
                                const b = content ? content.querySelector('b') : null;
                                if (b) {
                                    // Update the text to show the countdown in seconds
                                    b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                                }
                            }, 100); // Update every 100 milliseconds
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        }
                    }).then((result) => {
                        // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                        if (result.dismiss === Swal.DismissReason.timer) {
                            console.log('I was closed by the timer');
                        }
                        window.location.href = '/menu';
                    });
                    fetchCursos();
                } catch (error) {
                    console.error(error);
                    // Manejar el error de la solicitud al backend
                }
            } else {
                setCursoSeleccionado(null); // Restablecer a null
            }
        });
    };

    //

    // Función para formatear una fecha y hora a una cadena de fecha
    function formatDateTimeToDateString(dateTime) {
        const date = new Date(dateTime);
        return date.toISOString().split('T')[0]; // Obtiene la parte de la fecha
    }

    // Popup Editar
    const [editarCurso, setEditarCurso] = useState(false);

    const abrirEditarCurso = async (curso) => {

        setShowPopup(!showPopup);
        setCheckboxesSeleccionados({});
        setNuevoCursoDocente([]);
        setNuevoCursoEstudiante([]);
        setEditarCurso(true);
        //setShowPopup(true);

        const idCurso = {
            idCurso: curso.idCurso
        }

        const token = cookies.get('token');
        const response = await axios.post(
            baseUrl + 'cursos/infoEditCurso',
            idCurso,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.status === 200) {
            const cursoInfo = response.data;
            const cursosEstudiantes = cursoInfo.cursosEstudiantes;
            const cursosDocentes = cursoInfo.cursosDocentes;

            if (cursosEstudiantes !== undefined) {
                if (cursosEstudiantes.length > 0 || cursosEstudiantes === null) {
                    setSoloCurso(false);
                    setConEstudiante(true);
                    // Recorre los IDs de los estudiantes seleccionados almacenados en Cursos_estudiante
                    cursosEstudiantes.forEach((id) => {
                        // Verifica si el ID coincide con algún estudiante en estudiantes
                        const estudianteCoincidente = estudiantes.find((estudiante) => estudiante.idColaborador === id);
                        if (estudianteCoincidente) {
                            // Si se encontró un estudiante coincidente, establece isSelected en true
                            setEstudiantes((prevState) =>
                                prevState.map((estudiante) =>
                                    estudiante.idColaborador === id
                                        ? { ...estudiante, isSelected: true }
                                        : estudiante
                                )
                            );

                            // Actualiza el estado de estudianteSeleccionado
                            setEstudianteSeleccionado((prevState) => {
                                if (!prevState.includes(id)) {
                                    return [...prevState, id]; // Agrega el ID si no estaba seleccionado
                                }
                                return prevState;
                            });

                            // Actualiza el estado de nuevoCursoEstudiante
                            setNuevoCursoEstudiante((prevState) => {
                                if (!prevState.includes(id)) {
                                    return [...prevState, id]; // Agrega el ID si no estaba seleccionado
                                }
                                return prevState;
                            });
                        }
                    });
                }
            }

            if (cursosDocentes !== undefined) {
                if (cursosDocentes.length > 0 || cursosDocentes === null) {
                    setSoloCurso(false);
                    setConDocente(true);
                    cursosDocentes.forEach((id) => {
                        // Verifica si el ID coincide con algún docente en docentes
                        const docenteCoincidente = docentes.find((docente) => docente.idColaborador === id);
                        if (docenteCoincidente) {
                            // Si se encontró un docente coincidente, establece isSelected en true
                            setDocentes((prevState) =>
                                prevState.map((docente) =>
                                    docente.idColaborador === id
                                        ? { ...docente, isSelected: true }
                                        : docente
                                )
                            );

                            // Actualiza el estado de docenteSeleccionado
                            setDocenteSeleccionado((prevState) => {
                                if (!prevState.includes(id)) {
                                    return [...prevState, id]; // Agrega el ID si no estaba seleccionado
                                }
                                return prevState;
                            });

                            // Actualiza el estado de nuevoCursoDocente
                            setNuevoCursoDocente((prevState) => {
                                if (!prevState.includes(id)) {
                                    return [...prevState, id]; // Agrega el ID si no estaba seleccionado
                                }
                                return prevState;
                            });
                        }
                    });
                }
            }

            // Formatea las fechas
            const fecha_inicio = formatDateTimeToDateString(cursoInfo.curso.fecha_inicio);
            const fecha_fin = formatDateTimeToDateString(cursoInfo.curso.fecha_fin);

            // Establecer la información del curso
            setNuevoCurso({
                idCurso: cursoInfo.curso.idCurso,
                nom_curso: cursoInfo.curso.nom_curso,
                descripcion: cursoInfo.curso.descripcion,
                estado: cursoInfo.curso.estado,
                fecha_fin: fecha_fin,
                fecha_inicio: fecha_inicio,
                Cursos_docente: nuevoCursoDocente.map((id) => parseInt(id)),
                Cursos_estudiante: nuevoCursoEstudiante.map((id) => parseInt(id))
            });
        }
    };

    useEffect(() => {
        // Este efecto se ejecuta cuando cambian las siguientes dependencias: docenteSeleccionado, nuevoCursoDocente, estudianteSeleccionado, nuevoCursoEstudiante

        // Verifica si hay docentes seleccionados o en el nuevo curso
        const hayDocentesSeleccionados = docenteSeleccionado.length > 0 || nuevoCursoDocente.length > 0;

        // Verifica si hay estudiantes seleccionados o en el nuevo curso
        const hayEstudiantesSeleccionados = estudianteSeleccionado.length > 0 || nuevoCursoEstudiante.length > 0;

        // Actualiza los estados de acuerdo a la condición
        if (hayDocentesSeleccionados && hayEstudiantesSeleccionados) {
            setSoloCurso(false);
            setConDocente(true);
            setConEstudiante(true);
        } else if (hayDocentesSeleccionados) {
            setSoloCurso(false);
            setConDocente(true);
            setConEstudiante(false);
        } else if (hayEstudiantesSeleccionados) {
            setSoloCurso(false);
            setConDocente(false);
            setConEstudiante(true);
        } else {
            setSoloCurso(true);
            setConDocente(false);
            setConEstudiante(false);
        }
    }, [docenteSeleccionado, nuevoCursoDocente, estudianteSeleccionado, nuevoCursoEstudiante]);

    useEffect(() => {
        fetchCursos();
        fetchEstudiantes();
        fetchDocentes();




        verificarCondiciones();
    }, [conEstudiante, conDocente]);
    // 

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevoCurso.nom_curso && nuevoCurso.descripcion && nuevoCurso.fecha_inicio && nuevoCurso.fecha_fin && nuevoCurso.enlace && nuevoCursoDocente.length > 0 && nuevoCursoEstudiante.length > 0) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevoCurso.nom_curso, nuevoCurso.descripcion, nuevoCurso.fecha_inicio, nuevoCurso.fecha_fin, nuevoCurso.enlace, nuevoCursoDocente.length, nuevoCursoEstudiante.length]);

    // Paginación y limitación de mapeo de cursos

    const [currentPageCursos, setCurrentPageCursos] = useState(1);
    const coursesPorPage = 5; // Cantidad de cursos por página

    // Función para ir a la página anterior
    const previousPage = () => {
        if (currentPageCursos > 1) {
            setCurrentPageCursos(currentPageCursos - 1);
        }
    };

    // Función para ir a la página siguiente
    const nextPage = () => {
        const totalPages = Math.ceil(cursos.length / coursesPorPage);
        if (currentPageCursos < totalPages) {
            setCurrentPageCursos(currentPageCursos + 1);
        }
    };

    const indexOfLastCoursePage = currentPageCursos * coursesPorPage;
    const indexOfFirstCoursePage = indexOfLastCoursePage - coursesPorPage;
    const currentCoursesA = cursos.slice(indexOfFirstCoursePage, indexOfLastCoursePage);

    // Generar los botones de paginación
    const pageNumbersA = [];
    for (let i = 1; i <= Math.ceil(cursos.length / coursesPorPage); i++) {
        pageNumbersA.push(i);
    }

    return (
        <Fragment>
            <Container style={{ marginTop: '17px' }}>
                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-3">
                    <div className="col-md-9 mb-2 mb-md-0">
                        <h1 style={{ marginBottom: '-10px' }}>Lista de usuarios-cursos</h1>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center align-items-center">
                        <Button variant="success custom-orange-btn" onClick={togglePopup}>
                            <FaPlus /> Agregar nuevo curso
                        </Button>
                    </div>

                </div>


                <div className="table-responsive">  {/* ESTO ALTERA LA VISION EN ESCRITIORIO HAY QUE 
                VER QUE HACER PARA QUE NO ALTERE LA VISTA EN ESCRITORIO */}
                    {currentCoursesA.length > 0 ? (
                        <Table hover className="custom-table ">
                            <thead>
                                <tr>
                                    <th className="align-middle">NOMBRE</th>
                                    <th className="align-middle">INICIO</th>
                                    <th className="align-middle">FIN</th>
                                    <th className="align-middle">DOCENTES A CARGO</th>
                                    <th className="align-middle">ESTUDIANTES REGISTRADOS</th>
                                    <th className="align-middle">PUBLICADO</th>
                                    <th className="align-middle">ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentCoursesA.map((curso, index) => (
                                    <tr key={curso.idCurso}>
                                        <td className="custom-td align-middle truncate-text2">
                                            {curso.nom_curso}
                                        </td>
                                        <td className="custom-td align-middle truncate-date2">
                                            {new Date(curso.fecha_inicio).toLocaleDateString()}
                                        </td>
                                        <td className="custom-td align-middle truncate-date2">
                                            {new Date(curso.fecha_fin).toLocaleDateString()}
                                        </td>
                                        <td className="custom-td align-middle truncate-docentes2">
                                            {cursosdocentes[index]}
                                        </td>
                                        <td className="custom-td align-middle truncate-docentes2">
                                            {cursosestudiantes[index]}
                                        </td>
                                        <td style={{ width: '98px', height: '40px' }}>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: curso.estado ? '#D55613' : '',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: '1px solid #D55613',
                                                }}
                                            >
                                                <span style={{ color: curso.estado ? 'white' : 'black' }}>
                                                    {curso.estado ? 'Si' : 'No'}
                                                </span>
                                            </div>
                                        </td>

                                        <td>
                                            <Button variant="warning" onClick={() => abrirEditarCurso(curso)}>
                                                <FaPencilAlt />
                                            </Button>
                                            <Button variant="danger" onClick={() => eliminarCurso(curso.idCurso)} style={{ marginTop: '5px' }}>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <h3 className="nohaycursoslista">No hay cursos para mostrar</h3>
                    )}
                </div>

                {/* Paginación */}
                <nav aria-label="Page navigation example" className="fixed-pagination2">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {pageNumbersA.map((number) => (
                            <li key={number} className={`page-item ${currentPageCursos === number ? "active" : ""}`}>
                                <a className="page-link" href="#" onClick={() => setCurrentPageCursos(number)}>
                                    {number}
                                </a>
                            </li>
                        ))}
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </Container>
            <CSSTransition
                in={showPopup}
                timeout={300}
                classNames="popup-transition"
                unmountOnExit
            >
                <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} className="popup-contenta">
                    <div className="popup-wrapper">
                        <div className="popup-contenta">
                            <div>
                                <div className="popup-header">
                                    <div className="popup-title">
                                        {editarCurso ? "Editar Curso" : "Agregar Curso"}
                                    </div>
                                    <div className="popup-close-button">
                                        <FaWindowClose color="#c82333" className="invert-icon" onClick={togglePopup} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                                <hr />
                                <div className="popup-message">
                                    Por favor ingrese los siguientes datos:
                                </div>
                                <br />
                                <form onSubmit={agregarCurso}>
                                    <input
                                        id="idCurso"
                                        type="hidden"
                                        name="idCurso"
                                        value={nuevoCurso.idCurso}
                                    />
                                    <div className="input-group">
                                        <span>Nombre del curso: </span>
                                        <input
                                            id="nom_curso"
                                            type="text"
                                            className="popup-input"
                                            placeholder="Escribe el nombre del curso"
                                            name="nom_curso"
                                            maxLength={45}
                                            value={nuevoCurso.nom_curso}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-group">
                                        <span>Descripción del curso: </span>
                                        <input
                                            id="descripcion"
                                            type="text"
                                            className="popup-input"
                                            placeholder="Escribe una breve descripción del curso (Máx 120.)"
                                            name="descripcion"
                                            value={nuevoCurso.descripcion}
                                            onChange={handleChange}
                                            maxLength={250}
                                            required
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-group" style={{ width: "100%" }}>
                                                <span>Fecha de inicio:</span>
                                                <input
                                                    id="fecha_inicio"
                                                    type="date"
                                                    className="popup-input"
                                                    placeholder="Fecha de inicio"
                                                    name="fecha_inicio"
                                                    value={nuevoCurso.fecha_inicio}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-group" style={{ width: "100%" }}>
                                                <span>Fecha de fin:</span>
                                                <input
                                                    id="fecha_fin"
                                                    type="date"
                                                    className="popup-input"
                                                    placeholder="Fecha de fin"
                                                    name="fecha_fin"
                                                    value={nuevoCurso.fecha_fin}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        </div>
                                    </div>
                                    <div className="input-group" style={{ marginBottom: '12px' }}>
                                        <span>Estado:</span>
                                        <label className="switch" style={{ marginTop: '5px', marginBottom: '0px', marginLeft: '5px' }}>
                                            <input
                                                id="estado"
                                                type="checkbox"
                                                name="estado"
                                                checked={nuevoCurso.estado}
                                                onChange={() =>
                                                    setNuevoCurso((prevState) => ({
                                                        ...prevState,
                                                        estado: !prevState.estado,
                                                    }))
                                                }
                                            />
                                            <span className="slider round">
                                            </span>
                                        </label>
                                    </div>
                                    <div className="input-group">
                                        <button onClick={(e) => { openInnerPopup(e); e.preventDefault(); }} className="btn btn-block mybtn btn-success tx-tfm naranjabtn" style={{ width: '200px' }}>
                                            Enrolar docentes
                                        </button>

                                        <button onClick={(e) => { openInnerPopupE(e); e.preventDefault(); }} className="btn btn-block mybtn btn-success tx-tfm naranjabtn" style={{ width: '200px', marginTop: '0px' }}>
                                            Enrolar estudiantes
                                        </button>
                                    </div>
                                    <hr />
                                    <div className="input-group">
                                        <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                                            {editarCurso ? "Guardar cambios" : "Crear nuevo curso"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Popup>
            </CSSTransition>

            {showInnerPopup && (
                <CSSTransition
                    in={showInnerPopup}
                    timeout={300}
                    classNames="popup-transition"
                    unmountOnExit
                >
                    <Popup open={showInnerPopup} closeOnDocumentClick={false} closeOnEscape={false} className="inner-popup-contenta">
                        {/* Contenido del segundo popup */}
                        <div style={{ height: '630px' }}>
                            <div>
                                <div className="popup-header">
                                    <div className="popup-title">
                                        {editarCurso ? "Editar Docente" : "Agregar Docente"}
                                    </div>
                                    <div className="popup-close-button">
                                        <FaWindowClose color="#c82333" className="invert-icon" onClick={closeInnerPopup} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                                <hr />
                                <div className="filters inputs-container">
                                    <input
                                        type="text"
                                        className="filtro-input"
                                        value={filtroNombre}
                                        onChange={handleFiltroNombre}
                                        placeholder="Filtrar por nombre"
                                    />
                                    <input
                                        type="text"
                                        className="filtro-input"
                                        value={filtroApellido}
                                        onChange={handleFiltroApellido}
                                        placeholder="Filtrar por apellido"
                                    />
                                    <input
                                        type="text"
                                        className="filtro-input"
                                        value={filtroDocumento}
                                        onChange={handleFiltroDocumento}
                                        placeholder="Filtrar por documento"
                                    />
                                </div>
                                <table className="tabla-alumnos">
                                    <thead>
                                        <tr>
                                            <th className="checkbox-cell">Seleccionar</th>
                                            <th>Nombre</th>
                                            <th>Apellido</th>
                                            <th>Documento</th>
                                        </tr>
                                    </thead>
                                    {estudiantesFiltrados.length > 0 ? (
                                        <tbody>
                                            {estudiantesFiltrados.slice(indexOfFirstEstudiante, indexOfLastEstudiante).map((estudiante) => (
                                                <tr key={estudiante.idColaborador}>
                                                    <td className="checkbox-cell">
                                                        <div className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                className="mycheck"
                                                                id={estudiante.idColaborador}
                                                                checked={estudiante.isSelected}
                                                                onChange={() => handleSeleccionarEstudiante(estudiante.idColaborador)}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>{estudiante.nombres}</td>
                                                    <td>{estudiante.apellidos}</td>
                                                    <td>{estudiante.nro_documento}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="4" style={{ textAlign: 'center', marginTop: '20px' }}>
                                                    No hay estudiantes para mostrar
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>

                                <div>
                                    <nav aria-label="Page navigation example" style={{ marginTop: '15px' }}>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPageDocentes === 1 ? 'disabled' : ''}`} style={{ padding: 0 }}>
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChangeDocentes(currentPageDocentes - 1)}
                                                    disabled={currentPageDocentes === 1}
                                                    aria-label="Previous"
                                                >
                                                    <span aria-hidden="true">&laquo;</span>
                                                </button>
                                            </li>
                                            {[...Array(totalPages)].map((_, index) => (
                                                <li key={index} className={`page-item ${currentPageDocentes === index + 1 ? 'active' : ''}`} style={{ padding: 0 }}>
                                                    <button
                                                        className="page-link"
                                                        onClick={() => handlePageChangeDocentes(index + 1)}
                                                    >
                                                        {index + 1}
                                                    </button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPageDocentes === totalPages ? 'disabled' : ''}`} style={{ padding: 0 }}>
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChangeDocentes(currentPageDocentes + 1)}
                                                    disabled={currentPageDocentes === totalPages}
                                                    aria-label="Next"
                                                >
                                                    <span aria-hidden="true">&raquo;</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div className="input-group" style={{ margin: '15px' }}>
                                <button onClick={handleEnrolarDocentes} className="btn btn-block mybtn btn-success tx-tfm naranjabtn" style={{ width: '200px', marginTop: '5px' }}>Enrolar docentes</button>
                            </div>
                        </div>
                    </Popup>
                </CSSTransition>
            )}

            {setShowInnerPopupE && (
                <CSSTransition
                    in={showInnerPopupE}
                    timeout={300}
                    classNames="popup-transition"
                    unmountOnExit
                >
                    <Popup open={showInnerPopupE} closeOnDocumentClick={false} closeOnEscape={false} className="inner-popup-contenta">
                        {/* Contenido del tercer popup */}
                        <div style={{ height: '630px' }}>
                            <div>
                                <div className="popup-header">
                                    <div className="popup-title">
                                        {editarCurso ? "Editar Estudiante" : "Agregar Estudiante"}
                                    </div>
                                    <div className="popup-close-button">
                                        <FaWindowClose color="#c82333" className="invert-icon" onClick={closeInnerPopupE} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                                <hr />
                                <div className="filters inputs-container">
                                    <input
                                        type="text"
                                        className="filtro-input"
                                        value={filtroNombre}
                                        onChange={handleFiltroNombre}
                                        placeholder="Filtrar por nombre"
                                    />
                                    <input
                                        type="text"
                                        className="filtro-input"
                                        value={filtroApellido}
                                        onChange={handleFiltroApellido}
                                        placeholder="Filtrar por apellido"
                                    />
                                    <input
                                        type="text"
                                        className="filtro-input"
                                        value={filtroDocumento}
                                        onChange={handleFiltroDocumento}
                                        placeholder="Filtrar por documento"
                                    />
                                </div>

                                <table className="tabla-alumnos">
                                    <thead>
                                        <tr>
                                            <th className="checkbox-cell">Seleccionar</th>
                                            <th>Nombre</th>
                                            <th>Apellido</th>
                                            <th>Documento</th>
                                        </tr>
                                    </thead>
                                    {estudiantesFiltrados.length > 0 ? (
                                        <tbody>
                                            {estudiantesFiltrados.slice(indexOfFirstEstudiante, indexOfLastEstudiante).map((estudiante) => (
                                                <tr key={estudiante.idColaborador}>
                                                    <td className="checkbox-cell">
                                                        <div className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                className="mycheck"
                                                                id={estudiante.idColaborador}
                                                                checked={estudiante.isSelected}
                                                                onChange={() => handleSeleccionarEstudiante(estudiante.idColaborador)}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>{estudiante.nombres}</td>
                                                    <td>{estudiante.apellidos}</td>
                                                    <td>{estudiante.nro_documento}</td>
                                                </tr>
                                            ))}
                                            {/* Pagination buttons */}
                                            <div className="pagination-buttons-cursos">
                                                {/* Renderizar la lista de estudiantes */}
                                                {/* Agregar botones para ir a la página siguiente y anterior */}
                                                <button onClick={goToPreviousPage} disabled={pageNumber === 1}>Anterior</button>
                                                <button onClick={goToNextPage} className="next-button">Siguiente</button>
                                            </div>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <td colSpan="4" style={{ textAlign: 'center', marginTop: '20px' }}>
                                                    No hay estudiantes para mostrar
                                                </td>
                                            </tr>
                                        </tbody>
                                    )}
                                </table>
                                <div>
                                    <nav aria-label="Page navigation example" style={{ marginTop: '15px' }}>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPageEstudiantes === 1 ? 'disabled' : ''}`} style={{ padding: 0 }}>
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChangeEstudiantes(currentPageEstudiantes - 1)}
                                                    disabled={currentPageEstudiantes === 1}
                                                    aria-label="Previous"
                                                >
                                                    <span aria-hidden="true">&laquo;</span>
                                                </button>
                                            </li>
                                            {[...Array(totalPagesE)].map((_, index) => (
                                                <li key={index} className={`page-item ${currentPageEstudiantes === index + 1 ? 'active' : ''}`} style={{ padding: 0 }}>
                                                    <button
                                                        className="page-link"
                                                        onClick={() => handlePageChangeEstudiantes(index + 1)}
                                                    >
                                                        {index + 1}
                                                    </button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPageEstudiantes === totalPagesE ? 'disabled' : ''}`} style={{ padding: 0 }}>
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChangeEstudiantes(currentPageEstudiantes + 1)}
                                                    disabled={currentPageEstudiantes === totalPagesE}
                                                    aria-label="Next"
                                                >
                                                    <span aria-hidden="true">&raquo;</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div className="input-group" style={{ margin: '15px' }}>
                                <button onClick={handleEnrolarEstudiantes} className="btn btn-block mybtn btn-success tx-tfm naranjabtn" style={{ width: '200px', marginTop: '5px' }}>Enrolar estudiantes</button>
                            </div>
                        </div>
                    </Popup>
                </CSSTransition>
            )}

        </Fragment>
    );
}

export default withRouter(AdminCursos);