import React, { Fragment, useEffect, useState, useRef, Component } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "universal-cookie";
import axios from "axios";
// import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../css/Styles.css";
import {
  FaUserCircle,
  FaGoogle,
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaGithub,
} from "react-icons/fa";
// import "../../../css/Styles.css";
import Images from "../../img/index.js";
import Cursos from "./Admin/Menus/Cursos";
import Administracion from "./Admin/Menus/Administracion";
import jwt_decode from "jwt-decode";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import SubCurso from "./Admin/Menus/SubCursos/SubCurso";
import tableroImage from "../../img/hoja.png";
import cursosImage from "../../img/maestro.png";
import administracionImage from "../../img/admin.png";
import expandedImage from "../../img/izq.png";
import collapsedImage from "../../img/der.png";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { url } from "../../config";
import "./Admin/Menu.css";
import SessionManager from "../SessionManagement.js";
import CerrarSesion from "../CerrarSesion.js";
import md5 from "md5";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import mobileLogo from "../../img/mobile_white.png";  // Ruta de la imagen para móviles
import desktopLogo from "../../img/logo_white.png";        // Ruta de la imagen para escritorio
import { slide as BurgerMenu } from 'react-burger-menu';


function CambiarCl(props) {
  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  }

  const baseUrl = url;
  const [mostrarContenido, setMostrarContenido] = useState(true);
  // Se define la variable cursos
  const [cursos, setCursos] = useState([]);
  const [cursosdocentes, setCursosdocentes] = useState([]);
  const [selectedCurso, setSelectedCurso] = useState(null);

  const cookies = new Cookies();

  const [userName, setUserName] = useState("");
  const [userRol, setUserRol] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [userIdRoles, setUserIdRoles] = useState([]);
  const [userMenus, setUserMenus] = useState([]);
  const [rutas, setRutas] = useState({});

  const [activeOption, setActiveOption] = useState("TABLERO");
  const [contenido, setcontenido] = useState(null);

  const [showParentDropdown, setShowParentDropdown] = useState(false);
  const [showChildDropdown, setShowChildDropdown] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const parentDropdownRef = useRef();

  const closeParentDropdown = () => {
    // Close the parent dropdown after a certain time
    const timeout = setTimeout(() => {
      setShowParentDropdown(false);
    }, 15000); // Automatically close after 15 seconds

    // Clear the timeout when the parent dropdown is opened
    return () => clearTimeout(timeout);
  };

  const closeDropdownOnOutsideClick = () => {
    // Close the dropdown if the user clicks outside
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  };

  const handleClickOutside = (event) => {
    if (
      parentDropdownRef.current &&
      !parentDropdownRef.current.contains(event.target)
    ) {
      setShowParentDropdown(false);
    }
  };

  const toggleSidebarWidth = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const toggleParentDropdown = () => {
    setShowParentDropdown(!showParentDropdown);
    setShowChildDropdown(false); // Ocultar el menú Child
  };

  const toggleChildDropdown = () => {
    setShowChildDropdown(!showChildDropdown);
  };

  const handleMenuClick = (opcion, vista) => {
    // Remover la propiedad "selected" del enlace "TABLERO" si se selecciona otra opción
    if (opcion !== "TABLERO") {
      setActiveOption(opcion);
      setcontenido(vista);
      const tableroLink = document.querySelector("li.selectedes a");
      if (tableroLink) {
        tableroLink.classList.remove("selected");
      }
    }
  };

  const cambiarRol = async (id) => {
    try {
      const request = {
        idRol: parseInt(id),
      };
      const token = cookies.get("token");
      const resultado = await axios.post(
        baseUrl + "usuario_rol/cambiarRol",
        request,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      props.history.push("./");
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let contenido;

    switch (activeOption) {
      case "TABLERO":
        contenido = null;
        break;
      case "CURSOS":
        contenido = <Cursos />;
        break;
      case "Administración":
        contenido = <Administracion />;
        break;
      default:
        contenido = <Administracion />;
    }

    setcontenido(contenido);
  }, [activeOption]);
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    if (!cookies.get("token")) {
      props.history.push("./");
    }

    // Recorrer la tabla cursos para mostrar en el menú

    const fetchCursos = async () => {
      try {
        const token = cookies.get("token");
        const result = await axios.get(baseUrl + "cursos/panel", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.data.length > 0) {
          // Realizar los procesos de manera normal
          setCursos(result.data);
        } else {
          // Realizar alguna acción en caso de que la respuesta esté vacía
        }
      } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
      }
    };

    // Obtener datos del usuario e información pertinente
    const fetchUserInfo = async () => {
      try {
        const token = cookies.get("token");
        const response = await axios.get(baseUrl + "usuarios/userdata", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setUserName(userData.nombres);
        setUserRol(userData.rol);
        setUserRoles(userData.roles);
        setUserIdRoles(userData.rolesId);
        setUserMenus(userData.menus);
        setRutas(userData.rutas);
      } catch (error) {
        console.error(error);
        // Maneja el error de la solicitud al backend
      }
    };
    fetchUserInfo();
    fetchCursos();
  }, []);
  //}, [cursos]);   //<------------------------------------------------------------DEBE ESTAR----------------------------------------------------

  const [value, onChange] = useState(new Date());

  const redirectToSubCurso = async (idCurso) => {
    try {
      setSelectedCurso(idCurso);
    } catch (error) {
      console.error(error);
      // Manejar el error de la redirección
    }
  };

  useEffect(() => {
    if (selectedCurso === null) {
      setMostrarContenido(true);
    }
  }, [selectedCurso]);

  const renderSubCurso = () => {
    return selectedCurso ? <SubCurso idCurso={selectedCurso} /> : null;
  };

  const [form, setForm] = useState({
    nro_documento: "",
    password: "",
    nueva_password: "",
  });

  // Capturando datos para el cambio de contraseña
  const cambiar = {
    nro_documento: form.nro_documento,
    password: md5(form.password),
    nueva_password: md5(form.nueva_password),
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  // Evitando el ingreso de letras en el campo nro_documento
  const [disabled, setDisableInput] = useState(false);
  const inputRef = useRef(null);

  const handleN = (e) => {
    if (e.charCode <= 57 && e.charCode >= 48) {
    } else {
      setDisableInput(true);
      inputRef.current.blur();
      setTimeout(enableInput, 100);
    }
  };
  const enableInput = () => {
    setDisableInput(false);
    inputRef.current.focus();
  };

  // Mostrar y ocultar contraseña
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Cambiar contraseña
  const cambiarPassword = async () => {
    try {
      // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
      if (!form.nro_documento || !form.password || !form.nueva_password) {
        throw new Error("Por favor, complete todos los campos.");
      }
      // VALIDAR QUE LAS CONTRASEÑAS NO SEAN IGUALES
      if (form.password === form.nueva_password) {
        throw new Error(
          "Su nueva contraseña no pueden coincidir con la anterior."
        );
      }
      //
      //
      // Cambiar contraseña
      const token = cookies.get("token");
      const response = await axios.post(
        baseUrl + "usuarios/cambiarPassword/",
        cambiar,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //
      //
      // Mensaje de éxito
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Contraseña actualizada",
          text: "todo listo!",
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
        props.history.push("/menu"); // Redirecciona a la vista de menu
      }
    } catch (error) {
      // MANEJO DE ERRORES
      if (error.message === "Por favor, complete todos los campos.") {
        Swal.fire({
          icon: "error",
          title: "Campos incompletos!",
          text: error.message,
        });
      } else if (
        error.message === "Tu contraseña no puede ser igual a la anterior."
      ) {
        Swal.fire({
          icon: "error",
          title: "Datos incorrectos",
          text: error.message,
        });
      } else if (error.response && error.response.status === 400) {
        if (
          error.response.data.mensaje ===
          "El número de documento que ha ingresado es incorrecto."
        ) {
          Swal.fire({
            icon: "error",
            title: "Número de documento incorrecto",
            text: error.response.data.mensaje,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Lo sentimos!",
            text: error.response.data,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Lo sentimos!",
          text: error.message,
        });
      }
    }
  };

  useEffect(() => {
    if (!cookies.get("token")) {
      props.history.push("./");
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <style>
          {`
                body {
                    font-family: Arial, sans-serif;
                    background-image: url(${Images.planta});
                    background-repeat: no-repeat;
                    background-position: top;
                    background-attachment: fixed;
                    padding-top: 4.2rem;
                    padding-bottom: 4.2rem;  
                    background-size: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;                    
                }
                .form-container {
                    max-width: 700px; /* Increase the maximum width of the form container */
                    margin: 0 auto; /* Center the form container horizontally */
                    padding: 2rem; /* Add some padding to the form container */
                    background-color: #fff; /* Change the background color of the form container */
                    border-radius: 10px; /* Add some rounded corners to the form container */
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow to the form container */
                }
                
                .navbar {
                    position: fixed;
                    background-color: #f07435 !important;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: 1;
                }

                h1 {
                    color: white;
                    padding-top: 35px;
                }
                
                form {
                    max-width: 400px;
                    margin: 0 auto;
                    padding: 20px;
                    background-color: #fff;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    box-shadow: 0 0 10px rgba(0,0,0,.1);
                }
                
                input[type="text"],
                input[type="email"],
                input[type="password"] {
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 20px;
                    border: none;
                    border-radius: 5px;
                    background-color: #f2f2f2;
                    font-size: 16px;
                }
                
                button {
                    background-color: #003c71;
                    color: #fff;
                    padding: 10px 20px;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    font-size: 16px;
                }
                    @media only screen and (max-width: 768px) {
                    /* Hide the sidebar */
                    .sidebar {
                        display: none;
                    }
                    
                    .main-content {
                        padding-left: 20px;
                    }

                    /* Adjust the main content to take up full width */
                    .content {
                        width: 100%;
                    }
                    
                    /* Adjust the navbar to stack vertically */
                    .navbar-nav {
                        flex-direction: column;
                    }
                    
                    /* Hide the user dropdown menu */
                    .user-side{
                        display: none;
                    }
                    
                    /* Show the user name on the navbar */
                    .user-name {
                        display: inline-block;
                        margin-right: 10px;
                    }

                    .navbar-collapse {
                        background-color: #f07435;
                        z-index: 999;
                        position: absolute;
                        top: 60px;
                        width: 100%;
                        left: 0;
                    }

                    .dropdown-menu{
                        width: 354px !important;
                        text-align-last:center !important;
                        }

                    .navbar-nav .dropdown-menu {
                        position: absolute !important;
                        float: none;
                        }
                    }       
                    
                    .btn-gris{
                        background-color: #57575A !important;
                        color: white !important;
                      }
                      
                    @media only screen and (max-width: 767px) {
                        .form-container {
                          position: static !important;
                          margin-top: 2rem;
                        }
                        .dropdown-menu {
                            width: 413px !important;
                            text-align-last: center !important;
                        }
                        
                      }
                      
                    
                `}
        </style>
      </Helmet>
      <div className="bodyy2">
        {/* Navbar */}
        <nav className="navbar navayuuchi navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="/menu" style={{ marginTop: '11px', marginBottom: '12px', marginLeft: '35px' }}>
            <img
              className="lblank"
              src={window.innerWidth <= 768 ? mobileLogo : desktopLogo}
              width={window.innerWidth <= 768 ? "55" : "172"}  // Establecer el ancho según el tipo de dispositivo
              height="55"
              alt="Logo"
            />
          </a>
          <div className={`d-lg-none ml-auto ${scrolling ? 'scrolling' : ''}`}>
            <BurgerMenu right isOpen={showNav} onStateChange={(state) => setShowNav(state.isOpen)}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaUserCircle style={{ marginRight: "15px" }} />
                <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>{userName}</span>
                  <span style={{ fontSize: "14px", color: "gray", display: "block" }}>
                    Rol:
                    <button
                      className="btn btn-gris dropdown-toggle"
                      type="button"
                      id="userDropdown"
                      onClick={toggleChildDropdown}
                      style={{
                        fontSize: "14px",
                        color: "inherit",
                        marginLeft: "5px",
                        padding: "0",
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {userRol}
                    </button>
                    <ul
                      className={`perfiles-dropdown-menu dropdown-menu${showChildDropdown ? " show" : ""}`}
                      aria-labelledby="userDropdown"
                      style={{ marginTop: "10px", marginLeft: "-5px" }}
                      onMouseEnter={() => setShowChildDropdown(true)}
                      onMouseLeave={() => setShowChildDropdown(false)}
                    >
                      <li className="perfiles-dropdown-header">Perfiles</li>
                      {userRoles.map((role, index) => (
                        <li key={role.idRol}>
                          <Link
                            className={`perfiles-dropdown-item ${role === userRol ? "disabled" : ""}`}
                            onClick={() => cambiarRol(userIdRoles[index])}
                            style={{
                              pointerEvents: role === userRol ? "none" : "auto",
                              textDecoration: "underline",
                              fontSize: "14px",
                              marginTop: "5px",
                            }}
                          >
                            {role}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </span>


                  <Link to="/cambiar_clave" style={{ fontSize: "14px", color: "inherit", textDecoration: "underline" }}>
                    Cambiar contraseña
                  </Link>
                  <span
                    style={{ fontSize: "14px", cursor: "pointer", color: "inherit", textDecoration: "underline" }}
                    onClick={() => cerrarSesion()}
                  >
                    Cerrar sesión
                  </span>
                </div>
              </div>
              <hr />
              <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "flex-start", margin: "0 0", textAlign: "left" }}>
                <a id="inicio" className="color-night menu-item nav-link" href="/menu" style={{ display: "block", width: "100%" }}>
                  INICIO
                </a>
              </div>
              {/* <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "flex-start", margin: 0, textAlign: "left" }}>
                <a className="color-night nav-link " href="/Nosotros" style={{ display: "block", width: "100%" }}>
                  NOSOTROS
                </a>
              </div>
              <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "flex-start", margin: 0, textAlign: "left" }}>
                <a className="color-night nav-link " href="#" style={{ display: "block", width: "100%" }}>
                  NOTIFICACIONES
                </a>
              </div> */}

              <hr />
              {userMenus.map((menu, index) => (
                <div key={index} className="menu-item-container">
                  {menu === 'TABLERO' ? (
                    <Link
                      to="/menu"
                      onClick={() => {
                        // Manejar clic específico para "Tablero"
                        setActiveOption('TABLERO');
                        setcontenido(null);
                        setSelectedCurso(null);
                      }}
                      className={`menu-item ${activeOption === menu ? "selecteds" : ""}${sidebarCollapsed ? " collapsed" : ""}`}
                      style={{ display: "flex", color: "#606265", alignItems: "flex-start", margin: "0 0 15px", flexDirection: "column", justifyContent: "flex-start", textAlign: "left" }}
                    >
                      {sidebarCollapsed ? (
                        <img src={tableroImage} alt="TABLERO" />
                      ) : (
                        menu
                      )}
                    </Link>
                  ) : (
                    <span
                      onClick={() => {
                        if (activeOption !== menu) {
                          handleMenuClick(menu, rutas[index]);
                        }
                      }}
                      className={`menu-item ${activeOption === menu ? "selecteds" : ""}${sidebarCollapsed ? " collapsed" : ""}`}
                      style={{ display: "flex", color: "#606265", alignItems: "flex-start", flexDirection: "column", margin: "0 0 15px", justifyContent: "flex-start", textAlign: "left" }}
                    >
                      {sidebarCollapsed ? (
                        <img src={menu === "CURSOS" ? cursosImage : administracionImage} alt={menu} />
                      ) : (
                        menu
                      )}
                    </span>
                  )}
                </div>
              ))}
            </BurgerMenu>
          </div>
          {/*
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setShowNav(!showNav)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        */}

          <div className={`navbar-collapse d-none d-lg-flex ${showNav ? " show" : ""}`}>
            <ul className="navbar-nav mr-auto" style={{ marginLeft: '-32px' }}>
              <li className="nav-item active moving custom-list-item brillo">
                <a className="nav-link blanco bold" href="/menu">
                  INICIO
                </a>
              </li>
              {/* <li className="nav-item active moving custom-list-item brillo">
                <Link className="nav-link blanco bold" to="/Nosotros">
                  NOSOTROS
                </Link>
              </li>
              <li className="nav-item active moving custom-list-item brillo">
                <a className="nav-link blanco bold" href="/menu">
                  NOTIFICACIONES
                </a>
              </li> */}
            </ul>
            <div className="navbar-nav ml-auto position-relative">
              <img
                className="oblank"
                src={require("../../img/logo_white.png")}
                width="150"
                height="50"
                style={{ marginRight: '41px' }}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className="btn btn-gris dropdown-toggle w-100"
                  type="button"
                  id="userDropdown"
                  onClick={toggleParentDropdown}
                >
                  {/* Icono de perfil
                <FaUserCircle style={{ marginRight: "5px" }} />
                */}
                  {userName}
                </button>
              </div>
              <ul
                ref={parentDropdownRef}
                className={`dropdown-menu dropdown-menu-right userName-dropdown${showParentDropdown ? " show" : ""
                  }`}
                aria-labelledby="userDropdown"
                style={{ marginRight: "20px" }}
                onMouseEnter={() => {
                  setShowParentDropdown(true);
                  closeParentDropdown(); // Close after 15 seconds
                }}
                onMouseLeave={() => setShowParentDropdown(false)}
              >
                <li>
                  <button
                    className="btn btn-gris dropdown-toggle w-100"
                    type="button"
                    id="userDropdown"
                    onClick={toggleChildDropdown}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "22px !important",
                      marginRight: "20px !important",
                    }}
                  >
                    {userRol}
                  </button>
                  <ul
                    className={`perfiles-dropdown-menu dropdown-menu${showChildDropdown ? " show" : ""
                      }`}
                    aria-labelledby="userDropdown"
                    onMouseEnter={() => setShowChildDropdown(true)}
                    onMouseLeave={() => setShowChildDropdown(false)}
                  >
                    <li className="perfiles-dropdown-header">Perfiles</li>
                    {userRoles.map((role, index) => (
                      <li key={role.idRol}>
                        <Link
                          className={`perfiles-dropdown-item ${role === userRol ? "disabled" : ""
                            }`}
                          onClick={() => cambiarRol(userIdRoles[index])}
                          style={{
                            pointerEvents: role === userRol ? "none" : "auto",
                          }}
                        >
                          {role}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <Link className="dropdown-item" to="/cambiar_clave">
                    Cambiar contraseña
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => cerrarSesion()}>
                    Cerrar sesión
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h1 className="text-center mb-4">Cambiar Contraseña</h1>
            <div>
              <Form className="form-container">
                <Form.Group>
                  <Form.Label>Nro de documento</Form.Label>
                  <Form.Control
                    id="nro_documento"
                    name="nro_documento"
                    type="text"
                    placeholder="Ingresa tu numero de documento"
                    ref={inputRef}
                    disabled={disabled}
                    maxlength="10"
                    onChange={handleChange}
                    onKeyPress={handleN}
                    required
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Contraseña actual</Form.Label>
                  <div className="form-group password-input">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Control
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        maxlength="15"
                        placeholder="Tu contraseña actual"
                        onChange={handleChange}
                        required
                        style={{ flexGrow: 1 }}
                      />
                      <submit
                        onClick={togglePasswordVisibility}
                        style={{ top: "18px", transform: "translateY(-50%)" }}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </submit>
                    </div>
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Contraseña nueva</Form.Label>
                  <div className="form-group password-input">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Control
                        id="nueva_password"
                        name="nueva_password"
                        type={showPassword ? "text" : "password"}
                        maxlength="15"
                        placeholder="Ingresa tu nueva contraseña"
                        onChange={handleChange}
                        required
                      />
                      <submit
                        onClick={togglePasswordVisibility}
                        style={{ top: "18px", transform: "translateY(-50%)" }}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </submit>
                    </div>
                  </div>
                </Form.Group>
                <submit
                  className="naranjabtn btn btn-block mybtn btn-success tx-tfm d-block w-100 mt-3"
                  variant="primary"
                  onClick={cambiarPassword}
                >
                  Guardar cambios
                </submit>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default CambiarCl;
