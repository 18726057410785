import React, { Fragment, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withRouter } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { FaPlus, FaChevronDown, FaEye, FaComment, FaPencilAlt, FaTrash, FaSearch, FaChevronRight, FaWindowClose } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function CursoModuloCrud(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;

    const { idCurso } = props;
    const cookies = new Cookies();
    const [cursos, setCursos] = useState([]);
    const [cursosdocentes, setCursosdocentes] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
    const [docentesEncontrados, setdocentesEncontrados] = useState([]);
    const [docenteSeleccionado, setdocenteSeleccionado] = useState([]);
    const [estudianteSeleccionado, setestudianteSeleccionado] = useState([]);
    const [checkboxesSeleccionados, setCheckboxesSeleccionados] = useState({});
    const [nuevoCursoDocente, setNuevoCursoDocente] = useState([]);
    const [nuevoCursoEstudiante, setNuevoCursoEstudiante] = useState([]);
    const [soloCurso, setSoloCurso] = useState(true);
    const [conDocente, setConDocente] = useState(false);
    const [conEstudiante, setConEstudiante] = useState(false);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const [mostrarPestanaEstudiantes, setMostrarPestanaEstudiantes] = useState(false);
    const [mostrarPestanaDocentes, setMostrarPestanaDocentes] = useState(false);


    const [nuevoModulo, setNuevoModulo] = useState({
        nombre_modulo: '',
        enlace_sesion: '',
        enlace_informativo: '',
        estado: false,
        idCurso: props.idCurso,
    });

    // Capturando datos para el nuevoModulo.
    const ModuloNuevo = {
        nombre_modulo: nuevoModulo.nombre_modulo,
        estado: nuevoModulo.estado,
        enlace_informativo: nuevoModulo.enlace_informativo,
        enlace_sesion: nuevoModulo.enlace_sesion,
        idCurso: parseInt(props.idCurso),
    };
    const resetForm = () => {
        setNuevoModulo({
            nombre_modulo: '',
            enlace_sesion: '',
            enlace_informativo: '',
            estado: false,
        });
        setNuevoCursoDocente([]);
        setNuevoCursoEstudiante([]);
        setCheckboxesSeleccionados({});
        // Restablecer otras variables relacionadas si las hay
    };

    const fetchCursos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setCursos(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    // Agregar nuevo Modulo:
    const handleChange = (event) => {
        const { name, value } = event.target;
        setNuevoModulo((prevState) => ({
            ...prevState,
            [name]: name === 'idCurso' ? parseInt(value) : value,

        }));
    };

    const agregarCurso = async (event) => {
        event.preventDefault(); // Cancelar el evento por defecto
        try {
            if (editarModulo) {
                // EDITAR CURSO
                //
                // Establecer los valores del curso seleccionado al formulario popup
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevoModulo.nombre_modulo ||
                    !nuevoModulo.enlace_informativo ||
                    !nuevoModulo.enlace_sesion
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                const response = await axios.post(
                    baseUrl + 'modulos/editarModulo',
                    nuevoModulo,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Realizar alguna acción después de registrar el curso exitosamente
                // Por ejemplo, cerrar el popup y volver a cargar la lista de cursos
                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Cambio exitoso',
                        text: 'Módulo actualizado correctamente!',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    closePopup();
                    fetchCursos();
                    resetForm();
                }
            } else {
                // AGREGAR CURSO
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevoModulo.nombre_modulo ||
                    !nuevoModulo.enlace_sesion ||
                    !nuevoModulo.enlace_informativo
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                // Llamar a la API para agregar un curso, docentes y estudiantes
                const response = await axios.post(
                    baseUrl + 'modulos/registrarModulo',
                    ModuloNuevo,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Realizar alguna acción después de registrar el curso exitosamente
                // cerrar el popup y volver a cargar la lista de cursos
                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Registro exitoso',
                        text: 'Curso creado correctamente!',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    closePopup();
                    fetchCursos();
                    resetForm();
                }
            }
        } catch (error) {
            // MANEJO DE ERRORES
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };

    const eliminarCurso = async (idModulos) => {
        Swal.fire({
            title: '¿Estás seguro de que deseas ELIMINAR este módulo?',
            text: 'El módulo y su contenido no se podrán recuperar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const request = {
                        idModulos: idModulos
                    };
                    const token = cookies.get('token');
                    const response2 = await axios.post(baseUrl + 'modulos/eliminarModulo', request, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    // Mostrar Sweet Alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Curso eliminado correctamente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    fetchCursos();

                } catch (error) {
                    console.error(error);
                    // Manejar el error de la solicitud al backend
                }
            } else {
                setCursoSeleccionado(null); // Restablecer a null
            }
        });
    };

    //

    // Popup Editar
    const [editarModulo, setEditarModulo] = useState(false);

    const abrirEditarCurso = (curso) => {
        setEditarModulo(true);
        setShowPopup(true);

        // Asignar los valores del curso seleccionado al objeto nuevoModulo
        setNuevoModulo({
            idModulos: curso.idModulos,
            nombre_modulo: curso.nombre_modulo,
            enlace_informativo: curso.enlace_informativo,
            enlace_sesion: curso.enlace_sesion,
            idCurso: curso.idCurso,
            estado: curso.estado
        });
    };

    const togglePopup = () => {
        resetForm();
        setEditarModulo(false);
        setShowPopup(true);
        return true;
    };

    const closePopup = () => {
        setEditarModulo(false);
        setShowPopup(false);
        return true;
    };

    useEffect(() => {
        fetchCursos();
    }, []);
    // 

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevoModulo.nombre_modulo && nuevoModulo.enlace_sesion && nuevoModulo.enlace_informativo) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevoModulo.nombre_modulo, nuevoModulo.enlace_sesion, nuevoModulo.enlace_informativo]);

    // Paginación y limitación de mapeo de cursos

    const [currentPageCursos, setCurrentPageCursos] = useState(1);
    const coursesPorPage = 4; // Cantidad de cursos por página

    // Función para ir a la página anterior
    const previousPage = () => {
        if (currentPageCursos > 1) {
            setCurrentPageCursos(currentPageCursos - 1);
        }
    };

    // Función para ir a la página siguiente
    const nextPage = () => {
        const totalPages = Math.ceil(cursos.length / coursesPorPage);
        if (currentPageCursos < totalPages) {
            setCurrentPageCursos(currentPageCursos + 1);
        }
    };

    const indexOfLastCoursePage = currentPageCursos * coursesPorPage;
    const indexOfFirstCoursePage = indexOfLastCoursePage - coursesPorPage;
    const currentCoursesA = cursos.slice(indexOfFirstCoursePage, indexOfLastCoursePage);

    // Generar los botones de paginación
    const pageNumbersA = [];
    for (let i = 1; i <= Math.ceil(cursos.length / coursesPorPage); i++) {
        pageNumbersA.push(i);
    }

    return (
        <Fragment>
            <Container>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="col-12">
                        <h1>Lista de Módulo para el curso</h1>
                        <Button variant="success" onClick={togglePopup}>
                            Agregar nuevo Módulo <FaPlus />
                        </Button>
                    </div>
                </div>
                <div className="table-responsive">
                {currentCoursesA.length > 0 ? (
                    <Table hover className="custom-table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Enlace Informativo</th>
                                <th>Enlace de Sesion</th>
                                <th>Publicado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentCoursesA.map((curso, index) => (
                                <tr key={curso.idModulos}>
                                    <td className="truncate-text-tabla-modulos-titulo align-middle" >{curso.nombre_modulo}</td>
                                    <td className="truncate-text-tabla-modulos-link align-middle">{curso.enlace_informativo}</td>
                                    <td className="truncate-text-tabla-modulos-link align-middle">{curso.enlace_sesion}</td>
                                    <td style={{ width: '98px', height: '40px' }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: curso.estado ? '#D55613' : '',
                                                borderRadius: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid #D55613',
                                            }}
                                        >
                                            <span style={{ color: curso.estado ? 'white' : 'black' }}>
                                                {curso.estado ? 'Si' : 'No'}
                                            </span>
                                        </div>
                                    </td>

                                    <td>
                                        <Button variant="warning" onClick={() => abrirEditarCurso(curso)}>
                                            <FaPencilAlt />
                                        </Button>{' '}
                                        <Button variant="danger" onClick={() => eliminarCurso(curso.idModulos)}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <h3 className="nohaycursoslista">No hay Modulos para mostrar</h3>
                )}
                </div>
                {/* Paginación */}
                <nav aria-label="Page navigation example" className="fixed-pagination2">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {pageNumbersA.map((number) => (
                            <li key={number} className={`page-item ${currentPageCursos === number ? "active" : ""}`}>
                                <a className="page-link" href="#" onClick={() => setCurrentPageCursos(number)}>
                                    {number}
                                </a>
                            </li>
                        ))}
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </Container>
            <CSSTransition
                in={showPopup}
                timeout={300}
                classNames="popup-transition"
                unmountOnExit
            >
                <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} className="popup-contenta">
                    <div className="popup-wrapper">
                        <div className="popup-contenta">
                            <div>
                                <div className="popup-header">
                                    <div className="popup-title">
                                        {editarModulo ? "Editar Módulo" : "Agregar Módulo"}
                                    </div>
                                    <div className="popup-close-button">
                                        <FaWindowClose color="#c82333" className="invert-icon" onClick={closePopup} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                                <div className="popup-message">
                                    Por favor ingrese los siguientes datos:
                                </div>
                                <hr />
                                <form onSubmit={agregarCurso}>
                                    <div className="input-group">
                                        <span>Nombre del Módulo: </span>
                                        <input
                                            id="nombre_modulo"
                                            type="text"
                                            className="popup-input"
                                            maxLength={100}
                                            placeholder="Escribe el nombre del curso"
                                            name="nombre_modulo"
                                            value={nuevoModulo.nombre_modulo}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-group">
                                        <span>Enlace Informativo:</span>
                                        <input
                                            id="enlace_informativo"
                                            type="text"
                                            className="popup-input"
                                            placeholder="www.youtube.com"
                                            maxLength={250}
                                            name="enlace_informativo"
                                            value={nuevoModulo.enlace_informativo}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="input-group">
                                        <span>Enlace a la sesión:</span>
                                        <input
                                            id="enlace_sesion"
                                            type="text"
                                            className="popup-input"
                                            maxLength={250}
                                            placeholder="www.googledrive.com"
                                            name="enlace_sesion"
                                            value={nuevoModulo.enlace_sesion}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <hr />
                                    <div className="input-group" style={{ marginBottom: '12px' }}>
                                        <span>Estado:</span>
                                        <label className="switch" style={{ marginTop: '5px', marginBottom: '0px', marginLeft: '5px' }}>
                                            <input
                                                id="estado"
                                                type="checkbox"
                                                name="estado"
                                                checked={nuevoModulo.estado}
                                                onChange={() =>
                                                    setNuevoModulo((prevState) => ({
                                                        ...prevState,
                                                        estado: !prevState.estado,
                                                    }))
                                                }
                                            />
                                            <span className="slider round">
                                            </span>
                                        </label>
                                    </div>
                                    <hr style={{ marginTop: '1px' }} />
                                    <div className="input-group">
                                        <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                                            {editarModulo ? "Guardar cambios" : "Crear nuevo módulo"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Popup>
            </CSSTransition >
        </Fragment >
    );
}

export default withRouter(CursoModuloCrud);