import React, { Fragment, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { FaChevronRight, FaClipboard, FaThList } from 'react-icons/fa';
import { withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import { Container, Form, Button, Alert, Breadcrumb, Card} from 'react-bootstrap';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
import { url } from "../../../../../config.js";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"
import TareaRespuestas from "../SubCursos/TareaRespuestas.js";
import { DateTime } from 'luxon';


function CursoTareaEnvio(props) {
    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idCurso } = props;
    const { idTarea } = props;
    const { idUser } = props;
    const [nombreClase, setNombreClase] = useState('');
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [nombretareas, setNombreTareas] = useState([]);
    const [homeworkLink, setHomeworkLink] = useState('');// Initialize the state variable for user ID
    const [idUsuario, setIdUsuario] = useState(null);
    const [tareanombre, setTareaNombre] = useState('');
    const [tareaapellido, setTareaApellido] = useState('');
    const [tarearespuesta, setTareaRespuesta] = useState([]);
    const [esDeshabilitado, setEsDeshabilitado] = useState(false);
    const [mostrartareasnotas, setmostrartareasnotas] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submit action
        // Get the current date and time
        const currentDateTime = new Date().toISOString().slice(0, 19).replace('T', ' ');

        // Create an object with the current form data
        const tareaData = {
            idTarea: props.idTarea,
            idUsuario: idUsuario,
            enlace_respuesta: homeworkLink,
            fecha_registro: currentDateTime, // For example, to set the current date and time
        };

        // Call agregarTarea and pass the form data directly
        agregarTarea(tareaData);
    };


    // This will update the link state when you paste from clipboard or type in the input
    const handleLinkChange = (e) => {
        setHomeworkLink(e.target.value);
    };

    // Cerrar sesión


    const cookies = new Cookies();

    const fetchTarea = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idTarea: idTarea };
            const result = await axios.post(baseUrl + "tareas/buscarTareaSola", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setNombreTareas(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const [nuevaTarea, setNuevaTarea] = useState({
        idTarea: props.idTarea,
        idUsuario: '',
        enlace_respuesta: homeworkLink,
        fecha_registro: '',
    });

    // When you need to send the data:
    const fechahora = new Date().toISOString();

    // Capturando datos para el nuevaTarea.
    const ModuloTarea = {
        idTarea: parseInt(nuevaTarea.idTarea),
        idUsuario: parseInt(idUsuario),
        enlace_respuesta: homeworkLink,
    };

    const resetForm = () => {
        setNuevaTarea({
            idTarea: '',
            idUsuario: '',
            enlace_respuesta: '',
            fecha_registro: '',
        });
    };

    // Agregar nuevo Modulo:
    const handleChange = (event) => {
        const { name, value } = event.target;

        // Convertir el valor a entero solo si el campo es 'idModulos' o 'nota'
        const newValue = name === 'idTarea' || name === 'idUsuario' ? parseInt(value) : value;

        setNuevaTarea((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    const agregarTarea = async () => {
        try {
            // AGREGAR TAREA
            //
            // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
            const token = cookies.get('token');
            // Llamar a la API para agregar un tarea, docentes y estudiantes
            const response = await axios.post(
                baseUrl + 'Tareas_respuesta/registrarTareas_respuesta',
                ModuloTarea,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // Realizar alguna acción después de registrar el tarea exitosamente
            // cerrar el popup y volver a cargar la lista de tareas
            if (response.status === 201 || response.status === 200) {
                let timerInterval;
                Swal.fire({
                    icon: 'success',
                    title: 'Registro exitoso',
                    html: 'Tarea creado correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                    timer: 5000, // countdown duration in milliseconds
                    timerProgressBar: true,
                    didOpen: () => {
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer();
                            const b = content ? content.querySelector('b') : null;
                            if (b) {
                                // Update the text to show the countdown in seconds
                                b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                            }
                        }, 100); // Update every 100 milliseconds
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result) => {
                    // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('I was closed by the timer');
                    }
                    window.location.href = '/menu';
                });
            }
        } catch (error) {
            // MANEJO DE ERRORES
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };

    const esInformacionCompleta = () => {
        return tareanombre && tareaapellido &&
            tarearespuesta && tarearespuesta.fecha_registro &&
            tarearespuesta.enlace_respuesta !== undefined; // Asegúrate de que enlace_respuesta no sea undefined
    };


    const usuarioId = parseInt(idUser, 10);

    const handleMostrarRespuestas = () => {
        setmostrartareasnotas(true);
    };

    useEffect(() => {
        const fetchNombreCurso = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idCurso: idCurso };
                const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    setNombreClase(result.data);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };

        const fetchTareaRespuesta = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idTarea: idTarea };
                const result = await axios.post(baseUrl + "Tareas_respuesta/listarTareas_respuesta", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    const datos = result.data;
                    setTareaNombre(datos.nombres);
                    setTareaApellido(datos.apellidos);
                    setTareaRespuesta(datos.tareas_respuesta);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };


        fetchNombreCurso();
        fetchTarea();
        fetchTareaRespuesta();
    }, []);

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    }

    const formatDateEntregado = (dateString) => {
        // Convertir la fecha y hora del servidor a un objeto DateTime de Luxon
        const serverDateTime = DateTime.fromISO(dateString, { zone: 'UTC' }); // Especificar la zona horaria del servidor (UTC)
    
        // Obtener la fecha y hora local del cliente
        const localDateTime = serverDateTime.toLocal();
    
        // Formatear la fecha y hora local según tus preferencias
        const formattedDate = localDateTime.setLocale('es').toFormat("dd 'de' LLLL 'del' yyyy 'a las' HH:mm");
    
        return formattedDate;
    };

    function pasteFromClipboard() {
        navigator.clipboard.readText()
            .then(clipboardData => {
                setHomeworkLink(clipboardData);
            })
            .catch(error => {
                console.error("Failed to read clipboard contents: ", error);
            });
    }

    useEffect(() => {
        const token = cookies.get('token');
        if (token) {
            const decodedToken = jwt_decode(token);
            const idUsuarioFromToken = decodedToken.nameid;
            // Set the user ID using the correct setter function
            setIdUsuario(idUsuarioFromToken);
        } else {
            console.log('No token found!');
        }
    }, []);

    useEffect(() => {
        const fechaEntrega = nombretareas.fecha_entrega ? new Date(nombretareas.fecha_entrega) : null;
        const fechaActual = new Date();

        // Combinar ambas condiciones para establecer esDeshabilitado
        const esDespuesDeFechaEntrega = fechaEntrega && fechaEntrega < fechaActual;
        const tieneCalificacion = tarearespuesta.calificacion != null;

        // Establecer esDeshabilitado en true si alguna de las condiciones es verdadera
        setEsDeshabilitado(esDespuesDeFechaEntrega || tieneCalificacion);
    }, [nombretareas, tarearespuesta.calificacion]);
    
    return (
        <div>
            <Fragment>
                {mostrartareasnotas ? (
                    <TareaRespuestas idCurso={idCurso} idTarea={idTarea} />
                ) : (
                    <div className='container'>
                        <Container className="my-5 p-3 bg-light border rounded">

                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                {usuarioId === 4 || usuarioId === 5 ? (
                                    <Button className="lavandabu direction-button" onClick={handleMostrarRespuestas} style={{ textAlign: 'left' }}>
                                        Ver Respuestas  <FaThList className="fa-icon"/>
                                    </Button>
                                ) : null}
                            </div>

                            <hr />
                            <div className="text-center my-4">
                                <h1 className="curso-tarea-envio-title">{nombretareas.titulo}</h1>
                                <hr />
                                <div className="TareaContainer">
                                    <div><strong>Fecha de entrega: </strong>{formatDate(nombretareas.fecha_entrega)}</div>
                                    <div><strong>Puntos: </strong>{nombretareas.nota}</div>
                                </div>

                                <hr />
                                <div className="curso-tarea-envio-description" dangerouslySetInnerHTML={{ __html: nombretareas.descripcion }}></div>
                                <hr />
                                {esInformacionCompleta() && (
                                    <>
                                        <div className='div-contenedor'>
                                            <p className='truncate-tarea-nombre' style={{ textAlign: 'left', margin: 0 }}><strong>Nombre: </strong> {tareanombre} {tareaapellido}</p>
                                            <p style={{ textAlign: 'left', margin: 0 }}><strong>Fecha Enviada: </strong>{formatDateEntregado(tarearespuesta.fecha_registro)}</p>
                                        </div>
                                        <div className='div-contenedor'>
                                            <p className='truncate-tarea-link' style={{ textAlign: 'left', margin: 0 }}>
                                                <strong>Enlace: </strong>
                                                <a href={tarearespuesta.enlace_respuesta} target="_blank" rel="noopener noreferrer">
                                                    {tarearespuesta.enlace_respuesta}
                                                </a>
                                            </p>
                                        </div>
                                        <div className='div-contenedor'>
                                            <p style={{ textAlign: 'left', margin: 0 }}>
                                                <strong>Calificación: </strong>
                                                {tarearespuesta.calificacion == null ? 'Sin calificar' : tarearespuesta.calificacion}
                                            </p>
                                        </div>
                                        <hr className='lineamoduloct' />
                                    </>
                                )}

                            </div>
                            <Form onSubmit={handleSubmit} className="curso-tarea-envio-form" disabled={esDeshabilitado}>
                                {/* ... other form elements ... */}
                                <Form.Group controlId="trabajoInput" className="input-group">
                                    <Form.Control
                                        type="text"
                                        placeholder="Pegue el enlace de su trabajo aquí"
                                        value={homeworkLink}
                                        maxLength={250}
                                        onChange={handleLinkChange}
                                        className="text-center curso-tarea-envio-input"
                                        disabled={esDeshabilitado} // Deshabilitar basado en el estado
                                        required
                                    />
                                    <Button variant="secondary" onClick={pasteFromClipboard} className="btn-paste" disabled={esDeshabilitado}>
                                        <FaClipboard />
                                    </Button>
                                </Form.Group>
                                <Button variant="primary" type="submit" className="curso-tarea-envio-submit" disabled={esDeshabilitado}>Enviar Trabajo</Button>
                            </Form>
                        </Container>
                    </div>
                )}
            </Fragment>
        </div>
    );
}

export default withRouter(CursoTareaEnvio);