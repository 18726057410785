import React, { Fragment, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Container, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { FaPlus, FaMinus, FaPencilAlt, FaTrash, FaSearch, FaWindowClose } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Swal from 'sweetalert2';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function AdminDocentes(props) {
    SessionManager(props);
    
      const cerrarSesion = () =>{
        CerrarSesion(props);
      }
     // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    // 
    // Cerrar sesión
    
    const [docentes, setDocentes] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [nroDocumento, setNroDocumento] = useState('');
    const [colaboradoresEncontrados, setColaboradoresEncontrados] = useState('');
    const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState(null);

    const cookies = new Cookies();

    const fetchDocentes = async () => {
        const token = cookies.get('token');
        const result = await axios.get(baseUrl + "colaboradores/docentes", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        setDocentes(result.data);
    };

    useEffect(() => {
        fetchDocentes();

        

        

    }, []);

    const abrirPopup = () => {
        setColaboradoresEncontrados([]);
        setNroDocumento('');
        setColaboradorSeleccionado(null);
        setShowPopup(true);
    };

    const cerrarPopup = () => {
        setShowPopup(false);
        setColaboradoresEncontrados([]);
        setNroDocumento('');
    };

    const data = {
        nro_documento: nroDocumento
    };

    const data2 = {
        idColaborador: colaboradorSeleccionado
    };

    const token = cookies.get('token');
    const decodedToken = jwt_decode(token);
    const idUsuario = decodedToken.nameid;

    const buscarColaboradores = async () => {
        try {
            const token = cookies.get('token');
            const result = await axios.post(baseUrl + 'colaboradores/buscarColaboradores', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            setColaboradoresEncontrados(result.data);
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const agregarDocentes = async () => {
        // Verificación del idUsuario del colaborador
        if (colaboradoresEncontrados.idUsuario == idUsuario) {
            // Mostrar Sweet Alert
            Swal.fire({
                icon: 'error',
                title: 'No puedes realizar esta acción',
                showConfirmButton: true,
                confirmButtonText: 'Ok'
            });
            // Cerrar el popup y actualizar la lista de docentes
            cerrarPopup();
            fetchDocentes();
        } else {
            try {
                const token = cookies.get('token');
                const response = await axios.post(baseUrl + 'colaboradores/agregarDocentes', data2, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (response.status === 201) {
                    // Mostrar Sweet Alert de éxito
                    Swal.fire({
                        icon: 'success',
                        title: 'Nuevo docente agregado correctamente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                }
            } catch (error) {
                // Si la solicitud devuelve un error, maneja el mensaje personalizado
                if (error.response && error.response.status === 400 && error.response.data === "El colaborador ya es un profesor!") {
                    // Mostrar Sweet Alert de error específico
                    Swal.fire({
                        icon: 'error',
                        title: 'El colaborador ya es un profesor!',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                } else {
                    // Mostrar Sweet Alert genérico para otros errores
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al agregar el docente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                }
            } finally {
                // Cerrar el popup y actualizar la lista de docentes
                cerrarPopup();
                fetchDocentes();
            }
        }
    };

    const restituirDocentes = async (idColaborador) => {
        Swal.fire({
            title: '¿Estás seguro de que deseas restituir a este docente?',
            text: 'El docente ahora será un estudiante',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data2 = {
                        idColaborador: idColaborador
                    };
                    const token = cookies.get('token');
                    const response2 = await axios.post(baseUrl + 'colaboradores/restituirDocentes', data2, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    // Mostrar Sweet Alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Docente restituido correctamente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    fetchDocentes();

                } catch (error) {
                    console.error(error);
                    // Manejar el error de la solicitud al backend
                }
            } else {
                setColaboradorSeleccionado(null); // Restablecer a null
            }
        });
    };

    // Paginación y limitación de mapeo de docentes

    const [currentPage, setCurrentPage] = useState(1);
    const coursesPerPage = 9; // Cantidad de cursos por página

    // Función para ir a la página anterior
    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Función para ir a la página siguiente
    const nextPage = () => {
        const totalPages = Math.ceil(docentes.length / coursesPerPage);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const indexOfLastCourse = currentPage * coursesPerPage;
    const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
    const currentCourses = docentes.slice(indexOfFirstCourse, indexOfLastCourse);

    // Generar los botones de paginación
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(docentes.length / coursesPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <Fragment>
            <Container style={{marginTop: '17px'}}>
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-3">
                    <div className="col-md-9 mb-2 mb-md-0">
                        <h1 style={{ marginBottom: '-10px' }}>Lista de docentes</h1>
                        </div>
                    <div className="col-md-3 d-flex justify-content-center align-items-center">
                        <Button variant="success custom-orange-btn" onClick={abrirPopup}>
                            <FaPlus />Agregar nuevo docente
                        </Button>
                    </div>
                </div>

                {currentCourses.length > 0 ? (
                    <Table hover className="custom-table">
                        <thead>
                            <tr>
                                <th>NOMBRE</th>
                                <th>APELLIDOS</th>
                                <th>NÚMERO DE DOCUMENTO</th>
                                <th>ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentCourses.map((docentes) => (
                                <tr key={docentes.idColaborador}>
                                    <td>{docentes.nombres}</td>
                                    <td>{docentes.apellidos}</td>
                                    <td>{docentes.nro_documento}</td>
                                    <td>
                                        <Button variant="danger" onClick={() => restituirDocentes(docentes.idColaborador)}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <h3 className="nohaycursoslista">No hay docentes para mostrar</h3>
                )}
                {/* Paginación */}
                <nav aria-label="Page navigation example" className="fixed-pagination">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {pageNumbers.map((number) => (
                            <li key={number} className={`page-item ${currentPage === number ? "active" : ""}`}>
                                <a className="page-link" href="#" onClick={() => setCurrentPage(number)}>
                                    {number}
                                </a>
                            </li>
                        ))}
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </Container>
            <CSSTransition
                in={showPopup}
                timeout={300}
                classNames="popup-transition"
                unmountOnExit
            >
                <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} onClose={cerrarPopup}
                >
                    <div className="popup-contenta">
                        <div className="popup-header">
                            <div className="popup-title">
                                Seleccionar Profesor
                            </div>
                            <div className="popup-close-button">
                                <FaWindowClose color="#c82333" className="invert-icon" onClick={cerrarPopup} style={{ cursor: 'pointer' }} />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="popup-message">Porfavor ingrese el número de documento para asignarle el rol de docente:</div>
                        <br></br>
                        <form>
                            <div className="input-group">
                                <input
                                    id="nro_documento"
                                    type="text"
                                    maxLength="10"
                                    className="popup-inputs"
                                    placeholder="Ingresa tu documento de identidad"
                                    name="nro_documento"
                                    value={nroDocumento}
                                    onChange={(e) => setNroDocumento(e.target.value)}
                                    required
                                />
                                <button className="btn btn-primary" type="button" onClick={buscarColaboradores}>
                                    <FaSearch style={{ marginRight: '5px' }} />
                                </button>
                            </div>
                            <hr></hr>
                            <div className="popup-message">
                                {Object.keys(colaboradoresEncontrados).length > 0 ? (
                                    <ul>
                                        <li key={colaboradoresEncontrados.idColaborador}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={colaboradorSeleccionado === colaboradoresEncontrados.idColaborador}
                                                    onChange={() => setColaboradorSeleccionado(colaboradoresEncontrados.idColaborador)}
                                                    style={{ margin: '5px' }}
                                                />
                                                {colaboradoresEncontrados.nombres} {colaboradoresEncontrados.apellidos} - {colaboradoresEncontrados.nro_documento}
                                            </label>
                                        </li>
                                    </ul>
                                ) : (
                                    <p>No se encontraron colaboradores.</p>
                                )}
                            </div>
                            <hr></hr>
                            <button className="btn btn-block mybtn btn-success tx-tfm naranjabtn" type="button" onClick={agregarDocentes}>
                                Agregar nuevo docente
                            </button>
                        </form>
                    </div>
                </Popup>
            </CSSTransition>
        </Fragment>
    );
}

export default withRouter(AdminDocentes);