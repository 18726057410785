import React, { Fragment, useEffect, useState, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { withRouter } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { FaPlus, FaEye, FaPencilAlt, FaTrash, FaFile, FaWindowClose, FaArrowCircleLeft } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Button, Modal } from 'react-bootstrap';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import * as QuillTableUI from 'quill-table-ui'
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import Popup from "reactjs-popup";
import Swal from "sweetalert2";
import "reactjs-popup/dist/index.css";
import "react-tabs/style/react-tabs.css";
import ExamenPreguntas from "./CursoExamenes/ExamenPreguntas";
import ExamenVPrevia from "./CursoExamenes/ExamenVPrevia";
import { CSSTransition } from "react-transition-group";
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function CursoExamenCrud(props) {

  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  }
  const baseUrl = url;
  const { idExamen } = props;
  const { idCurso } = props;
  const cookies = new Cookies();
  const [idModulosOptions, setIdModulosOptions] = useState([]);
  const [idexamen, setIdexamen] = useState(null);
  const [notidexamen, setnotIdexamen] = useState(null);
  const [idcurso, setIdcurso] = useState(null);
  const [mostrarCrud, setMostrarCrud] = useState(false);
  const [mostrarVistaPrevia, setMostrarVistaPrevia] = useState(false);
  const [mostrarExamenPregunta, setMostrarExamenPregunta] = useState(false);
  const [showCrud, setShowCrud] = useState(true);
  const [nombreClase, setNombreClase] = useState("");
  const [examenes, setCursos] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedExamen, setSelectedExamen] = useState(null);
  const [selectedPrevia, setSelectedPrevia] = useState(null);
  const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
  const [soloCurso, setSoloCurso] = useState(true);
  const [validacionCompleta, setValidacionCompleta] = useState(false);
  const quillRef = useRef(null);// Ref para el editor Quill
  const [quillInstance, setQuillInstance] = useState(null);//------------  
  const [showModal, setShowModal] = useState(false);
  const [htmlCode, setHtmlCode] = useState(''); // HTML code content
  const [editorContent, setEditorContent] = useState(''); // Content in rich text format
  const [showHtmlModal, setShowHtmlModal] = useState(false); // To control the HTML modal 

  const [nuevoExamen, setNuevoExamen] = useState({
    titulo: "",
    descripcion: "",
    adjunto: "",
    puntos: "",
    estado: false,
    fecha_entrega: '',
    idModulos: "",
  });

  // Capturando datos para el nuevoExamen.
  const ModuloExamen = {
    titulo: nuevoExamen.titulo,
    descripcion: nuevoExamen.descripcion,
    estado: nuevoExamen.estado,
    puntos: parseInt(nuevoExamen.puntos),
    adjunto: nuevoExamen.adjunto,
    fecha_entrega: nuevoExamen.fecha_entrega,
    idModulos: parseInt(nuevoExamen.idModulos),
  };

  const resetForm = () => {
    setNuevoExamen({
      titulo: "",
      descripcion: "",
      adjunto: "",
      puntos: "",
      fecha_entrega: "",
      estado: false,
    });
  };

  const fetchCursos = async () => {
    try {
      const token = cookies.get("token");
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(
        baseUrl + "examen/listarExamenesMenu",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setCursos(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  // Agregar nuevo Modulo:
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Convertir el valor a entero solo si el campo es 'idModulos' o 'nota'
    const newValue =
      name === "idModulos" || name === "puntos" ? parseInt(value) : value;

    setNuevoExamen((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const agregarExamen = async (event) => {
    event.preventDefault(); // Cancelar el evento por defecto
    const examenData = {
      ...nuevoExamen,
      descripcion: htmlCode // Aquí usas la variable de estado que contiene el HTML actualizado
    };

    try {
      if (editarExamen) {
        // EDITAR Examen
        //
        // Establecer los valores del examen seleccionado al formulario popup
        //
        // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
        if (
          !nuevoExamen.titulo ||
          !nuevoExamen.puntos ||
          !nuevoExamen.idModulos
        ) {
          throw new Error("Por favor, complete todos los campos.");
        }

        // Verificar si la propiedad hora_inicio está definida, de lo contrario asignar "00:00:00"
        if (!nuevoExamen.hora_inicio) {
          nuevoExamen.hora_inicio = "00:00:00";
        }

        // Verificar si la propiedad hora_fin está definida, de lo contrario asignar "00:00:00"
        if (!nuevoExamen.hora_fin) {
          nuevoExamen.hora_fin = "00:00:00";
        }

        const token = cookies.get("token");
        const response = await axios.post(
          baseUrl + "examen/editarExamen",
          examenData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Realizar alguna acción después de registrar el examen exitosamente
        // Por ejemplo, cerrar el popup y volver a cargar la lista de examenes
        if (response.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Cambio exitoso",
            text: "Modulo actualizado correctamente!",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
          closePopup();
          fetchCursos();
          resetForm();
        }
      } else {
        // AGREGAR Examen
        //
        // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO

        const ModulonewExamen = {
          ...nuevoExamen,
          descripcion: htmlCode // Aquí usas la variable de estado que contiene el HTML actualizado
        };

        if (
          !nuevoExamen.titulo ||
          !nuevoExamen.puntos ||
          !nuevoExamen.idModulos
        ) {
          throw new Error("Por favor, complete todos los campos.");
        }
        // Verificar si la propiedad hora_inicio está definida, de lo contrario asignar "00:00:00"
        if (!nuevoExamen.hora_inicio) {
          nuevoExamen.hora_inicio = "00:00:00";
        }
        // Verificar si la propiedad hora_fin está definida, de lo contrario asignar "00:00:00"
        if (!nuevoExamen.hora_fin) {
          nuevoExamen.hora_fin = "00:00:00";
        }
        const token = cookies.get("token");
        if (soloCurso) {

          // Llamar a la API para agregar un examen, docentes y estudiantes
          const response = await axios.post(
            baseUrl + "examen/registrarExamen",
            ModulonewExamen,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // Realizar alguna acción después de registrar el examen exitosamente
          // cerrar el popup y volver a cargar la lista de examenes
          if (response.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Registro exitoso",
              text: "Examen creado correctamente!",
              showConfirmButton: true,
              confirmButtonText: "Ok",
            });
            closePopup();
            fetchCursos();
            resetForm();
          }
        }
      }
    } catch (error) {
      // MANEJO DE ERRORES
      if (error.message === "Por favor, complete todos los campos.") {
        Swal.fire({
          icon: "error",
          title: "Campos incompletos!",
          text: error.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Lo sentimos!",
          text: error.message,
        });
      }
    }
  };

  const eliminarExamen = async (idExamen) => {
    Swal.fire({
      title: "¿Estás seguro de que deseas ELIMINAR este examen?",
      text: "El examen y su contenido no se podrán recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const request = {
            idExamen: idExamen,
          };
          const token = cookies.get("token");
          const response2 = await axios.post(
            baseUrl + "examen/eliminarExamen",
            request,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Mostrar Sweet Alert
          Swal.fire({
            icon: "success",
            title: "Examen eliminado correctamente",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
          fetchCursos();
        } catch (error) {
          console.error(error);
          // Manejar el error de la solicitud al backend
        }
      } else {
        setCursoSeleccionado(null); // Restablecer a null
      }
    });
  };

  //

  // Popup Editar
  const [editarExamen, setEditarExamen] = useState(false);

  const abrirEditarExamen = (examen) => {
    setEditarExamen(true);
    setShowPopup(true);

    // Asignar los valores del examen seleccionado al objeto nuevoExamen
    setNuevoExamen({
      idExamen: examen.idExamen,
      titulo: examen.titulo,
      descripcion: examen.descripcion,
      puntos: parseInt(examen.puntos),
      adjunto: examen.adjunto,
      idModulos: examen.idModulos,
      estado: examen.estado,
    });
  };

  const fetchIdModulos = async () => {
    try {
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.status === 200) {
        // Extraer los idModulos de la respuesta y almacenarlos en el estado
        setIdModulosOptions(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };


  useEffect(() => {
    const fetchNombreCurso = async () => {
      try {
        const token = cookies.get("token");
        const requestBody = { idCurso: idCurso };
        const result = await axios.post(
          baseUrl + "cursos/buscarCurso",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.status === 200) {
          setNombreClase(result.data);
        } else {
          // Realizar alguna acción en caso de que la respuesta no sea exitosa
        }
      } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
      }
    };

    fetchNombreCurso();
    fetchIdModulos();
    fetchCursos();
  }, [nuevoExamen]);
  //

  useEffect(() => {
    // Realiza la validación cuando se actualicen los valores relevantes
    if (
      nuevoExamen.titulo &&
      nuevoExamen.descripcion &&
      nuevoExamen.adjunto &&
      nuevoExamen.puntos
    ) {
      setValidacionCompleta(true);
    } else {
      setValidacionCompleta(false);
    }
  }, [
    nuevoExamen.titulo,
    nuevoExamen.descripcion,
    nuevoExamen.adjunto,
    nuevoExamen.puntos,
  ]);

  // Paginación y limitación de mapeo de cursos

  const [currentPageCursos, setCurrentPageCursos] = useState(1);
  const coursesPorPage = 4; // Cantidad de examenes por página

  // Función para ir a la página anterior
  const previousPage = () => {
    if (currentPageCursos > 1) {
      setCurrentPageCursos(currentPageCursos - 1);
    }
  };

  // Función para ir a la página siguiente
  const nextPage = () => {
    const totalPages = Math.ceil(examenes.length / coursesPorPage);
    if (currentPageCursos < totalPages) {
      setCurrentPageCursos(currentPageCursos + 1);
    }
  };

  const indexOfLastCoursePage = currentPageCursos * coursesPorPage;
  const indexOfFirstCoursePage = indexOfLastCoursePage - coursesPorPage;
  const currentCoursesA = examenes.slice(indexOfFirstCoursePage, indexOfLastCoursePage);

  // Generar los botones de paginación
  const pageNumbersA = [];
  for (let i = 1; i <= Math.ceil(examenes.length / coursesPorPage); i++) {
    pageNumbersA.push(i);
  }

  // Utiliza useRef para mantener una referencia consistente a la instancia de Quill
  const quillInstanceRef = useRef(null);

  const updateQuillContent = (html) => {
    if (quillInstanceRef.current) {
      quillInstanceRef.current.clipboard.dangerouslyPasteHTML(html);
    }
  };

  const handleSaveHtmlChanges = () => {
    if (quillInstanceRef.current) {
      const html = quillInstanceRef.current.root.innerHTML;
      setEditorContent(html); // Actualiza el estado con el contenido actual del editor Quill
      setNuevoExamen(prevState => ({ ...prevState, descripcion: html })); // Asegúrate de que se actualiza el estado de la tarea
    }
    handleCloseHtmlModal();
  };

  const handleCloseHtmlModal = () => {
    setShowHtmlModal(false);
  };
  const handleShowHtmlModal = () => {
    setHtmlCode(htmlCode);
    setShowHtmlModal(true);
  };


  const togglePopup = () => {
    resetForm();
    setEditarExamen(false);
    setShowPopup(true);
    return true;
  };

  const closePopup = () => {
    setEditarExamen(false);
    setShowPopup(false);
    return true;
  };

  useEffect(() => {

    let quillInstance;

    if (showPopup && quillRef.current && !quillInstanceRef.current) {
      const Font = Quill.import('formats/font');
      Font.whitelist = ['serif', 'sans-serif', 'monospace', 'times-new-roman', 'arial', 'tahoma', 'helvetica'];
      Quill.register(Font, true);
      Quill.register({
        'modules/tableUI': QuillTableUI.default
      }, true)

      const toolbarOptions = [
        ["bold", "italic", "underline", "strike"],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "+1" }, { indent: "-1" }],
        [{ align: [] }],
        [{ size: ["small", "large", "huge", false] }],
        ["image", "link", "video", "formula"],
        [{ color: [] }, { background: [] }],
        [{ font: ["arial", "helvetica", "serif", "sans-serif", "monospace", "times-new-roman", "tahoma"] }],
        ["code-block", "blockquote"]
      ];

      quillInstanceRef.current = new Quill(quillRef.current, {
        modules: {
          toolbar: toolbarOptions,
          table: true,
          tableUI: true,
        },
        theme: 'snow',


      });

      quillInstanceRef.current.on('text-change', () => {
        const html = quillInstanceRef.current.root.innerHTML;
        setHtmlCode(html); // Asegúrate de que esta función actualiza el estado correctamente
      });

    }
    return () => {
      if (quillInstanceRef.current) {
        quillInstanceRef.current.off('text-change');
        quillInstanceRef.current.disable();
        quillInstanceRef.current = null;
      }
    };
  }, [showPopup]);

  useEffect(() => {
    if (quillInstance) {
      quillInstance.on("text-change", () => {
        const html = quillInstance.root.innerHTML;
        setEditorContent(html);
        // Update nuevoExamen.descripcion with the editor content
        setNuevoExamen((prevState) => ({
          ...prevState,
          descripcion: html,
        }));
      });
    }
  }, [quillInstance]);

  useEffect(() => {
    if (showPopup && quillInstanceRef.current) {
      // Actualizar contenido HTML solo cuando showPopup es true y Quill está inicializado
      const currentContent = quillInstanceRef.current.root.innerHTML;
      if (currentContent !== nuevoExamen.descripcion) {
        quillInstanceRef.current.clipboard.dangerouslyPasteHTML(nuevoExamen.descripcion);
        // Opcional: Mover el cursor al final del editor
      }
    }
  }, [showPopup, nuevoExamen.descripcion]);

  const setOptionColor = (optionValue) => {
    if (optionValue >= 0 && optionValue <= 12) {
      return 'option-red';
    } else if (optionValue >= 13 && optionValue <= 20) {
      return 'option-green';
    }
  };

  const handleVistaPrevia = (idCurso, idExamen) => {
    setIdcurso(idCurso);
    setIdexamen(idExamen);
    setSelectedPrevia(idExamen); // Asegúrate de actualizar el examen seleccionado para la vista previa
    setMostrarVistaPrevia(true);
    setMostrarExamenPregunta(false);
  };

  const handleocultarVistaPrevia = () => {
    setMostrarVistaPrevia(false);
  };

  const handleExamenPreguntas = (idCurso, idExamen) => {
    setIdcurso(idCurso);
    setnotIdexamen(idExamen); // Asegúrate de actualizar el examen seleccionado para las preguntas
    setMostrarExamenPregunta(true);
    setMostrarVistaPrevia(false);
  };

  useEffect(() => {
  }, [mostrarExamenPregunta]);


  const handleocultarExamenPreguntas = () => {
    setMostrarExamenPregunta(false);
  };

  return (
    <Fragment>
      {!selectedExamen && showCrud && (
        <div>
          {/* Si estamos en la vista del editor */}
          {mostrarVistaPrevia && (
            <div className="align-right-container">
              <Button className="cielobu direction-button" onClick={handleocultarVistaPrevia}>
                Volver a la lista de exámenes <FaArrowCircleLeft className="fa-icon" />
              </Button>
            </div>
          )}
          {mostrarExamenPregunta && (
            <div className="align-right-container">
              <Button className="cielobu direction-button" onClick={handleocultarExamenPreguntas}>
                Volver a la lista de exámenes<FaArrowCircleLeft className="fa-icon" />
              </Button>
            </div>
          )}
          {mostrarVistaPrevia ? (
            <ExamenVPrevia idCurso={idCurso} idExamen={selectedPrevia} />
          ) : mostrarExamenPregunta ? (
            <ExamenPreguntas idCurso={idCurso} idExamen={notidexamen} />
          ) : (
            <Container>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="col-12">
                  <h1>Lista de Exámenes</h1>
                  {/* <Button variant="success" onClick={togglePopup}>
                    Agregar nuevo Examen <FaPlus />
                  </Button> <------------------------------------------CORREGIR POP-UP--------------------------------------------- */}
                </div>
              </div>

              <div className="table-responsive">
                {currentCoursesA.length > 0 ? (
                  <Table hover className="custom-table-exam">
                    <thead>
                      <tr>
                        <th className="hide-column">ID del Modulo Asignado</th>
                        <th>Titulo</th>
                        <th>Descripción</th>
                        <th>Puntos</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                        <th>Preevisualizacion</th>
                        <th>Ir a las Preguntas del examen</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentCoursesA.map((examen, index) => (
                        <tr key={examen.idExamen}>
                          <td className="hide-column align-middle">{examen.idModulos}</td>
                          <td className="truncate-text-tabla-modulos-tarea-crud align-middle">{examen.titulo}</td>
                          <td className="truncate-text-tabla-modulos-tarea-crud align-middle">{examen.descripcion}</td>
                          <td className="align-middle">{examen.puntos}</td>
                          <td style={{ width: "98px", height: "40px" }}>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: examen.estado ? "#D55613" : "",
                                borderRadius: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "1px solid #D55613",
                              }}
                            >
                              <span
                                style={{ color: examen.estado ? "white" : "black" }}
                              >
                                {examen.estado ? "Si" : "No"}
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              variant="warning"
                              onClick={() => abrirEditarExamen(examen)}
                            >
                              <FaPencilAlt />
                            </Button>{" "}
                            <Button
                              variant="danger"
                              onClick={() => eliminarExamen(examen.idExamen)}
                            >
                              <FaTrash />
                            </Button>
                          </td>
                          <td>
                            <Button
                              className="btn-block"
                              variant="primary"
                              onClick={() => handleVistaPrevia(examen.idCurso, examen.idExamen)}
                            >
                              <FaEye />
                            </Button>
                          </td>
                          <td>
                            <Button
                              className="btn-block"
                              variant="success"
                              onClick={() => handleExamenPreguntas(examen.idCurso, examen.idExamen)}
                            >
                              <FaFile />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <h3 className="nohaycursoslista">No hay Cursos para mostrar</h3>
                )}
              </div>
              {/* Paginación */}
              <nav aria-label="Page navigation example" className="fixed-pagination2">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                  {pageNumbersA.map((number) => (
                    <li key={number} className={`page-item ${currentPageCursos === number ? "active" : ""}`}>
                      <a className="page-link" href="#" onClick={() => setCurrentPageCursos(number)}>
                        {number}
                      </a>
                    </li>
                  ))}
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </Container>
          )}
        </div>
      )}

      <CSSTransition
        in={showPopup}
        timeout={300}
        classNames="popup-transition"
        unmountOnExit
      >
        <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} className="popup-contenta" contentStyle={{ maxWidth: '1075px' }} >
          <div className="popup-wrapper tamano-popup">
            <div className="popup-contenta">
              <div>
                <div className="popup-header">
                  <div className="popup-title">
                    {editarExamen ? "Editar Examen" : "Agregar Examen"}
                  </div>
                  <div className="popup-close-button">
                    <FaWindowClose color="#c82333" className="invert-icon" onClick={closePopup} style={{ cursor: 'pointer' }} />
                  </div>
                </div>
                <div className="popup-message">
                  Por favor ingrese los siguientes datos:
                </div>
                <hr />
                <form onSubmit={agregarExamen}>
                  <div className="input-group">
                    <span>Titulo de la examen: </span>
                    <input
                      id="titulo"
                      type="text"
                      className="popup-input"
                      placeholder="Escribe el nombre del Examen"
                      name="titulo"
                      value={nuevoExamen.titulo}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <Button variant="info" style={{ marginRight: '8px' }} onClick={handleShowHtmlModal}>
                    Cambiar vista a HTML
                  </Button>
                  <div className="input-group">
                    <span>Descripción: </span>
                    <div className="align-items-center justify-content-between mb-3">
                      <div id="editor" ref={quillRef}>
                        <p><br /></p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="input-group">
                    <span>Puntos:</span>
                    <select
                      id="puntos"
                      type="text"
                      className="popup-input"
                      placeholder="19"
                      name="puntos"
                      value={nuevoExamen.puntos}
                      onChange={handleChange}
                      required
                    >
                      {Array.from({ length: 21 }, (_, i) => (
                        <option
                          key={i}
                          value={i}
                          className={setOptionColor(i)}
                        >
                          {i.toString().padStart(2, '0')}
                        </option>
                      ))}
                    </select>
                  </div>
                  <hr />
                  <div className="input-group">
                    <span>Fecha de entrega:</span>
                    <input
                      id="fecha_entrega"
                      type="date"
                      className="popup-input"
                      placeholder="2023-08-21"
                      name="fecha_entrega"
                      value={nuevoExamen.fecha_entrega}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <hr />
                  <div className="input-group">
                    <span>Seleccionar Modulos:</span>
                    <select
                      id="idModulos"
                      className="popup-input"
                      name="idModulos"
                      value={nuevoExamen.idModulos}
                      onChange={handleChange}
                      required
                    >
                      {/* Opción predeterminada del combobox */}
                      <option value="">Seleccionar Id Modulos</option>
                      {/* Mapear los idModulos disponibles en el estado */}
                      {idModulosOptions.map((idModulo) => (
                        <option
                          key={idModulo.idModulos}
                          value={idModulo.idModulos}
                        >
                          {idModulo.nombre_modulo}
                        </option>
                      ))}
                    </select>
                  </div>
                  <hr />
                  <div className="input-group" style={{ marginBottom: '12px' }}>
                    <span>Estado:</span>
                    <label className="switch" style={{ marginTop: '5px', marginBottom: '0px', marginLeft: '5px' }}>
                      <input
                        id="estado"
                        type="checkbox"
                        name="estado"
                        checked={nuevoExamen.estado}
                        onChange={() =>
                          setNuevoExamen((prevState) => ({
                            ...prevState,
                            estado: !prevState.estado,
                          }))
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <hr style={{ marginTop: "1px" }} />
                  <div className="input-group">
                    <button
                      type="submit"
                      className="btn btn-block mybtn btn-success tx-tfm naranjabtn"
                    >
                      {editarExamen
                        ? "Guardar cambios"
                        : "Crear nuevo examen"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Popup>
      </CSSTransition>
      <Modal show={showHtmlModal} backdrop="static" keyboard={false} onHide={handleCloseHtmlModal} size="lg"> {/* Set the size to large */}
        <Modal.Header className="centered-title" closeButton>
          <Modal.Title>Editor HTML</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows="10"
            value={htmlCode} // El contenido HTML se muestra en el textarea
            onChange={(e) => setHtmlCode(e.target.value)}
            className="custom-textarea"
            spellCheck="false"
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="danger" onClick={handleCloseHtmlModal}>
            Cerrar
          </Button>
          <Button variant="success" onClick={() => {
            updateQuillContent(htmlCode);
            handleSaveHtmlChanges();
          }}>
            Guardar cambios
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}

export default withRouter(CursoExamenCrud);
