import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withRouter } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { FaPlus, FaMinus, FaPencilAlt, FaTrash, FaWindowClose } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Modal } from 'react-bootstrap';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import * as QuillTableUI from 'quill-table-ui'
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"
function CursoForoCrud(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    const { idCurso } = props;
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const cookies = new Cookies();
    // Estado para almacenar los idModulos disponibles
    const [idModulosOptions, setIdModulosOptions] = useState([]);
    const [tareas, setForos] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [cursoSeleccionado, setForoSeleccionado] = useState(null);
    const [checkboxesSeleccionados, setCheckboxesSeleccionados] = useState({});
    const [nuevoCursoDocente, setNuevoCursoDocente] = useState([]);
    const [nuevoCursoEstudiante, setNuevoCursoEstudiante] = useState([]);
    const [soloForo, setsoloForo] = useState(true);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const { idModulos } = props;
    const [cursos, setCursos] = useState([]);
    const quillRef = useRef(null);// Ref para el editor Quill
    const [quillInstance, setQuillInstance] = useState(null);//------------  
    const [showModal, setShowModal] = useState(false);
    const [htmlCode, setHtmlCode] = useState(''); // HTML code content    
    const [editorContent, setEditorContent] = useState(''); // Content in rich text format
    const [showHtmlModal, setShowHtmlModal] = useState(false); // To control the HTML modal


    const [nuevoForo, setNuevoForo] = useState({
        titulo: '',
        descripcion: '',
        fecha_inicio: '',
        fecha_fin: '',
        estado: false,
        idModulos: '',
    });

    // Capturando datos para el nuevoForo.
    const ModuloForo = {
        titulo: nuevoForo.titulo,
        descripcion: nuevoForo.descripcion,
        fecha_inicio: nuevoForo.fecha_inicio,
        fecha_fin: nuevoForo.fecha_fin,
        estado: nuevoForo.estado,
        idModulos: parseInt(nuevoForo.idModulos),
    };
    const resetForm = () => {
        setNuevoForo({
            titulo: '',
            descripcion: '',
            fecha_inicio: '',
            fecha_fin: '',
            estado: false,
            idModulos: '',
        });
    };



    const fetchForos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "Foros/listarForosMenu", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setForos(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    // Agregar nuevo Modulo:
    const handleChange = (event) => {
        const { name, value } = event.target;

        // Convertir el valor a entero solo si el campo es 'idModulos'
        const newValue = name === 'idModulos' ? parseInt(value) : value;

        setNuevoForo((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    const agregarForo = async (event) => {
        event.preventDefault(); // Cancelar el evento por defecto

        const foroData = {
            ...nuevoForo,
            descripcion: htmlCode // Aquí usas la variable de estado que contiene el HTML actualizado
        };

        try {
            if (editarForo) {
                // EDITAR TAREA
                //
                // Establecer los valores del foro seleccionado al formulario popup
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevoForo.titulo ||
                    !nuevoForo.fecha_inicio ||
                    !nuevoForo.fecha_fin ||
                    !nuevoForo.idModulos
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                const response = await axios.post(
                    baseUrl + 'Foros/editarForo',
                    foroData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Realizar alguna acción después de registrar el foro exitosamente
                // Por ejemplo, cerrar el popup y volver a cargar la lista de tareas
                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Cambio exitoso',
                        text: 'Foro actualizado correctamente!',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    closePopup();
                    fetchForos();
                    resetForm();
                }
            } else {
                // AGREGAR TAREA
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                const ModulonewForo = {
                    ...nuevoForo,
                    descripcion: htmlCode // Aquí usas la variable de estado que contiene el HTML actualizado
                };
                if (
                    !nuevoForo.titulo ||
                    !nuevoForo.fecha_inicio ||
                    !nuevoForo.fecha_fin ||
                    !nuevoForo.idModulos
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                if (soloForo) {

                    // Llamar a la API para agregar un foro, docentes y estudiantes
                    const response = await axios.post(
                        baseUrl + 'Foros/registrarForo',
                        ModulonewForo,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el foro exitosamente
                    // cerrar el popup y volver a cargar la lista de tareas
                    if (response.status === 201) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            text: 'Foro creado correctamente!',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                        closePopup();
                        fetchForos();
                        resetForm();
                    }
                }
            }
        } catch (error) {
            // MANEJO DE ERRORES
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };


    const eliminarForo = async (idForo) => {
        Swal.fire({
            title: '¿Estás seguro de que deseas ELIMINAR este Foro?',
            text: 'El modulo y su contenido no se podrán recuperar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const request = {
                        idForo: idForo
                    };
                    const token = cookies.get('token');
                    const response2 = await axios.post(baseUrl + 'Foros/eliminarForo', request, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    // Mostrar Sweet Alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Foro eliminado correctamente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    fetchForos();

                } catch (error) {
                    console.error(error);
                    // Manejar el error de la solicitud al backend
                }
            } else {
                setForoSeleccionado(null); // Restablecer a null
            }
        });
    };

    //

    // Popup Editar
    const [editarForo, seteditarForo] = useState(false);

    const abrireditarForo = (foro) => {
        seteditarForo(true);
        setShowPopup(true);
        const formattedFechaInicio = foro.fecha_inicio.split('T')[0]; // If it's in ISO format        
        const formattedFechaFin = foro.fecha_fin.split('T')[0]; // If it's in ISO format
        // Asignar los valores del foro seleccionado al objeto nuevoForo
        setNuevoForo({
            idForo: foro.idForo,
            titulo: foro.titulo,
            descripcion: foro.descripcion,
            fecha_inicio: formattedFechaInicio,
            fecha_fin: formattedFechaFin,
            estado: foro.estado,
            idModulos: foro.idModulos
        });
    };


    const fetchIdModulos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Extraer los idModulos de la respuesta y almacenarlos en el estado
                setIdModulosOptions(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    useEffect(() => {
        fetchIdModulos();
        fetchForos();
    }, [nuevoForo]);
    // 

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevoForo.titulo && nuevoForo.descripcion && nuevoForo.fecha_inicio && nuevoForo.fecha_fin) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevoForo.titulo, nuevoForo.descripcion, nuevoForo.fecha_inicio, nuevoForo.fecha_fin]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    };

    // Paginación y limitación de mapeo de cursos

    const [currentPageCursos, setCurrentPageCursos] = useState(1);
    const coursesPorPage = 5; // Cantidad de cursos por página

    // Función para ir a la página anterior
    const previousPage = () => {
        if (currentPageCursos > 1) {
            setCurrentPageCursos(currentPageCursos - 1);
        }
    };

    // Función para ir a la página siguiente
    const nextPage = () => {
        const totalPages = Math.ceil(tareas.length / coursesPorPage);
        if (currentPageCursos < totalPages) {
            setCurrentPageCursos(currentPageCursos + 1);
        }
    };

    const indexOfLastCoursePage = currentPageCursos * coursesPorPage;
    const indexOfFirstCoursePage = indexOfLastCoursePage - coursesPorPage;
    const currentCoursesA = tareas.slice(indexOfFirstCoursePage, indexOfLastCoursePage);

    // Generar los botones de paginación
    const pageNumbersA = [];
    for (let i = 1; i <= Math.ceil(tareas.length / coursesPorPage); i++) {
        pageNumbersA.push(i);
    }


    // Utiliza useRef para mantener una referencia consistente a la instancia de Quill
    const quillInstanceRef = useRef(null);

    const updateQuillContent = (html) => {
        if (quillInstanceRef.current) {
            quillInstanceRef.current.clipboard.dangerouslyPasteHTML(html);
        }
    };

    const handleSaveHtmlChanges = () => {
        if (quillInstanceRef.current) {
            const html = quillInstanceRef.current.root.innerHTML;
            setEditorContent(html); // Actualiza el estado con el contenido actual del editor Quill
            setNuevoForo(prevState => ({ ...prevState, descripcion: html })); // Asegúrate de que se actualiza el estado de la tarea
        }
        handleCloseHtmlModal();
    };

    const handleCloseHtmlModal = () => {
        setShowHtmlModal(false);
    };
    const handleShowHtmlModal = () => {
        setHtmlCode(htmlCode);
        setShowHtmlModal(true);
    };


    const togglePopup = () => {
        resetForm();
        seteditarForo(false);
        setShowPopup(true);
        return true;
    };

    const closePopup = () => {
        seteditarForo(false);
        setShowPopup(false);
        return true;
    };

    useEffect(() => {

        let quillInstance;

        if (showPopup && quillRef.current && !quillInstanceRef.current) {
            const Font = Quill.import('formats/font');
            Font.whitelist = ['serif', 'sans-serif', 'monospace', 'times-new-roman', 'arial', 'tahoma', 'helvetica'];
            Quill.register(Font, true);
            Quill.register({
                'modules/tableUI': QuillTableUI.default
            }, true)

            const toolbarOptions = [
                ["bold", "italic", "underline", "strike"],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "+1" }, { indent: "-1" }],
                [{ align: [] }],
                [{ size: ["small", "large", "huge", false] }],
                ["image", "link", "video", "formula"],
                [{ color: [] }, { background: [] }],
                [{ font: ["arial", "helvetica", "serif", "sans-serif", "monospace", "times-new-roman", "tahoma"] }],
                ["code-block", "blockquote"]
            ];

            quillInstanceRef.current = new Quill(quillRef.current, {
                modules: {
                    toolbar: toolbarOptions,
                    table: true,
                    tableUI: true,
                },
                theme: 'snow',


            });

            quillInstanceRef.current.on('text-change', () => {
                const html = quillInstanceRef.current.root.innerHTML;
                setHtmlCode(html); // Asegúrate de que esta función actualiza el estado correctamente
            });

        }
        return () => {
            if (quillInstanceRef.current) {
                quillInstanceRef.current.off('text-change');
                quillInstanceRef.current.disable();
                quillInstanceRef.current = null;
            }
        };
    }, [showPopup]);

    useEffect(() => {
        if (quillInstance) {
            quillInstance.on("text-change", () => {
                const html = quillInstance.root.innerHTML;
                setEditorContent(html);
                // Update nuevoForo.descripcion with the editor content
                setNuevoForo((prevState) => ({
                    ...prevState,
                    descripcion: html,
                }));
            });
        }
    }, [quillInstance]);

    useEffect(() => {
        if (showPopup && quillInstanceRef.current) {
            // Actualizar contenido HTML solo cuando showPopup es true y Quill está inicializado
            const currentContent = quillInstanceRef.current.root.innerHTML;
            if (currentContent !== nuevoForo.descripcion) {
                quillInstanceRef.current.clipboard.dangerouslyPasteHTML(nuevoForo.descripcion);
                // Opcional: Mover el cursor al final del editor
            }
        }
    }, [showPopup, nuevoForo.descripcion]);

    return (
        <Fragment>
            <Container>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="col-12">
                        <h1>Lista de Control de Foros</h1>
                        <Button variant="success" onClick={togglePopup}>
                            Agregar Foros <FaPlus />
                        </Button>
                    </div>

                </div>
                <div className="table-responsive">
                {currentCoursesA.length > 0 ? (
                    <Table hover className="custom-table">
                        <thead>
                            <tr>
                                <th>Id Modulo</th>
                                <th>Titulo</th>
                                <th>Descripción</th>
                                <th>Fecha Inicio</th>
                                <th>Fecha Fin</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tareas.map((foro, index) => (
                                <tr key={foro.idForo}>
                                    <td className="align-middle">{foro.idModulos}</td>
                                    <td className="truncate-text-tabla-modulos-tarea-crud align-middle">{foro.titulo}</td>
                                    <td className="truncate-text-tabla-modulos-tarea-crud align-middle">{foro.descripcion}</td>
                                    <td className="align-middle">{formatDate(foro.fecha_inicio)}</td>
                                    <td className="align-middle">{formatDate(foro.fecha_fin)}</td>
                                    <td style={{ width: '98px', height: '40px' }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: foro.estado ? '#D55613' : '',
                                                borderRadius: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid #D55613',
                                            }}
                                        >
                                            <span style={{ color: foro.estado ? 'white' : 'black' }}>
                                                {foro.estado ? 'Si' : 'No'}
                                            </span>
                                        </div>
                                    </td>

                                    <td>
                                        <Button variant="warning" onClick={() => abrireditarForo(foro)}>
                                            <FaPencilAlt />
                                        </Button>{' '}
                                        <Button variant="danger" onClick={() => eliminarForo(foro.idForo)}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <h3 className="nohaycursoslista">No hay Foros para mostrar</h3>
                )}
                </div>
                {/* Paginación */}
                <nav aria-label="Page navigation example" className="fixed-pagination2">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {pageNumbersA.map((number) => (
                            <li key={number} className={`page-item ${currentPageCursos === number ? "active" : ""}`}>
                                <a className="page-link" href="#" onClick={() => setCurrentPageCursos(number)}>
                                    {number}
                                </a>
                            </li>
                        ))}
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </Container>
            <CSSTransition
                in={showPopup}
                timeout={300}
                classNames="popup-transition"
                unmountOnExit
            >
                <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} className="popup-contenta-html" contentStyle={{ maxWidth: '1103px' }} >
                    <div className="popup-wrapper tamano-popup" style={{ width: '1035px' }}>
                        <div className="popup-contenta-html">
                            <div>
                                <div className="popup-header">
                                    <div className="popup-title">
                                        {editarForo ? "Editar Foro" : "Agregar Foro"}
                                    </div>
                                    <div className="popup-close-button">
                                        <FaWindowClose color="#c82333" className="invert-icon" onClick={closePopup} style={{ cursor: 'pointer' }} />
                                    </div>
                                </div>
                                <div className="popup-message">
                                    Por favor Ingrese los siguientes datos:
                                </div>
                                <hr />
                                <form onSubmit={agregarForo}>
                                    <div className="input-group">
                                        <span>Titulo del foro: </span>
                                        <input
                                            id="titulo"
                                            type="text"
                                            className="popup-input"
                                            placeholder="Escribe el nombre del Foro"
                                            name="titulo"
                                            maxLength={100}
                                            value={nuevoForo.titulo}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <Button variant="info" style={{ marginRight: '8px' }} onClick={handleShowHtmlModal}>
                                        Cambiar vista a HTML
                                    </Button>
                                    <div className="input-group">
                                        <span>Descripción: </span>
                                        <div className="align-items-center justify-content-between mb-3">
                                            <div id="editor" ref={quillRef}>
                                                <p><br /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="input-group">
                                        <span>Fecha de Inicio:</span>
                                        <input
                                            id="fecha_inicio"
                                            type="date"
                                            className="popup-input"
                                            placeholder="Fecha de inicio"
                                            name="fecha_inicio"
                                            value={nuevoForo.fecha_inicio}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <hr />
                                    <div className="input-group">
                                        <span>Fecha Fin:</span>
                                        <input
                                            id="fecha_fin"
                                            type="date"
                                            className="popup-input"
                                            placeholder="Fecha cuando termina"
                                            name="fecha_fin"
                                            value={nuevoForo.fecha_fin}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <hr />
                                    <div className="input-group">
                                        <span>Modulos:</span>
                                        <select
                                            id="idModulos"
                                            className="popup-input"
                                            name="idModulos"
                                            value={nuevoForo.idModulos}
                                            onChange={handleChange}
                                            required
                                        >
                                            {/* Opción predeterminada del combobox */}
                                            <option value="">Seleccionar Modulos</option>
                                            {/* Mapear los idModulos disponibles en el estado */}
                                            {idModulosOptions.map((idModulo) => (
                                                <option key={idModulo.idModulos} value={idModulo.idModulos}>
                                                    {idModulo.nombre_modulo}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="input-group" style={{ marginBottom: '12px' }}>
                                        <span>Estado:</span>
                                        <label className="switch" style={{ marginTop: '5px', marginBottom: '0px', marginLeft: '5px' }}>
                                            <input
                                                id="estado"
                                                type="checkbox"
                                                name="estado"
                                                checked={nuevoForo.estado}
                                                onChange={() =>
                                                    setNuevoForo((prevState) => ({
                                                        ...prevState,
                                                        estado: !prevState.estado,
                                                    }))
                                                }
                                            />
                                            <span className="slider round">
                                            </span>
                                        </label>
                                    </div>
                                    <hr style={{ marginTop: '1px' }} />
                                    <div className="input-group">
                                        <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                                            {editarForo ? "Guardar cambios" : "Crear nuevo foro"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Popup>
            </CSSTransition>
            <Modal show={showHtmlModal} backdrop="static" keyboard={false} onHide={handleCloseHtmlModal} size="lg"> {/* Set the size to large */}
                <Modal.Header className="centered-title" closeButton>
                    <Modal.Title>Editor HTML</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        rows="10"
                        value={htmlCode} // El contenido HTML se muestra en el textarea
                        onChange={(e) => setHtmlCode(e.target.value)}
                        className="custom-textarea"
                        spellCheck="false"
                    />
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="danger" onClick={handleCloseHtmlModal}>
                        Cerrar
                    </Button>
                    <Button variant="success" onClick={() => {
                        updateQuillContent(htmlCode);
                        handleSaveHtmlChanges();
                    }}>
                        Guardar cambios
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default withRouter(CursoForoCrud);