import React, { Fragment, useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { FaWindowClose } from 'react-icons/fa';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import * as QuillTableUI from 'quill-table-ui'
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function CursoUpdateHTML(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idCurso } = props;
    const cookies = new Cookies();
    const [cursos, setCursos] = useState([]);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const [editorContent, setEditorContent] = useState(''); // Content in rich text format
    const [htmlCode, setHtmlCode] = useState(''); // HTML code content
    const [showHtmlModal, setMostrarModalHTML] = useState(false); // To control the HTML modal
    const [openHtmlModal, setOpenHtmlModal] = useState(false);
    const [idPaginacion, setIdPaginacion] = useState(null);
    const [idPaginaContenido, setIdPaginaContenido] = useState(null);

    // Ref para el editor Quill
    const quillRef = useRef(null);
    const [quillInstance, setQuillInstance] = useState(null);
    const [nuevoHTML, setEditorHTML] = useState({
        contenido: '', // Initialize contenido as an empty string
        idPagina_contenido: idPaginacion, // Use idPagina here
    });

    // Cerrar sesión


    const fetchCursos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setCursos(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };



    // This function could be triggered by a save button, for example.
    const ActualizarDatosdeContenido = async () => {
        try {
            const token = cookies.get('token');
            nuevoHTML.contenido = editorContent;
            nuevoHTML.idPagina_contenido = idPaginaContenido;

            // Make the POST request to update content
            const response = await axios.post(baseUrl + "Paginas_contenido/editarPaginas_contenido", nuevoHTML, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            // Actions to take after successful update
            if (response.status === 200 || response.status === 201) {
                let timerInterval;
                Swal.fire({
                    icon: 'success',
                    title: 'Cambio exitoso',
                    html: 'Contenido Actualizado Correctamente! <br>Aplicando cambios, será redireccionado al inicio: <b></b>',
                    timer: 5000, // countdown duration in milliseconds
                    timerProgressBar: true,
                    didOpen: () => {
                        timerInterval = setInterval(() => {
                            const content = Swal.getHtmlContainer();
                            const b = content ? content.querySelector('b') : null;
                            if (b) {
                                // Update the text to show the countdown in seconds
                                b.textContent = Math.round(Swal.getTimerLeft() / 1000);
                            }
                        }, 100); // Update every 100 milliseconds
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result) => {
                    // If the user closes the modal by clicking on the confirm button or the timer runs out, redirect to the homepage
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('I was closed by the timer');
                    }
                    window.location.href = '/menu';
                });
            }

        } catch (error) {
            // Error handling
            console.error(error); // Log the error
            Swal.fire({
                icon: 'error',
                title: 'Error al actualizar!',
                text: error.response?.data?.message || 'Ha ocurrido un error al intentar actualizar el contenido.',
            });
        }
    };

    const traerDatosHTML = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "Paginas_contenido/BuscarPaginas_contenidoCurso", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Use the function to set content in Quill
                setQuillContent(result.data.contenido);
                // Set the idPagina_contenido in the state
                setIdPaginaContenido(result.data.idPagina_contenido);
            } else {
                // Handle non-successful status
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchCursos();

        traerDatosHTML();

    }, []);
    // 

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevoHTML.contenido) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevoHTML.contenido]);

    const CerrarModalHTML = () => {
        setMostrarModalHTML(false);
    };

    // Llamada a la función para mostrar el modal con el contenido HTML
    const controlarMostrarModal = () => {
        if (!showHtmlModal) { // Verifica si el modal HTML ya está abierto
            setHtmlCode(editorContent); // Establece el contenido HTML en el estado local htmlCode
            setMostrarModalHTML(true); // Abre el modal HTML
        }
    };

    // Efecto que se ejecuta cuando editorContent u openHtmlModal cambian
    useEffect(() => {
        if (openHtmlModal) {
            controlarMostrarModal(); // Llama a la función para mostrar el modal con el contenido HTML
            setOpenHtmlModal(false); // Restablece el estado para evitar que el modal se abra nuevamente inmediatamente
        }
    }, [editorContent, openHtmlModal]);


    const ActualizarContenidoQuill = (html) => {
        if (quillInstance) {
            quillInstance.root.innerHTML = html;
        }
    };

    const almacenarDatosHTML = () => {
        setEditorContent(htmlCode); // Update the content immediately in the rich text editor
        CerrarModalHTML();
    };

    //Llamando Quill EDITOR HTML
    useEffect(() => {
        // Inicializa Quill dentro de useEffect para asegurarnos de que el DOM esté listo
        if (!quillInstance && quillRef.current) {
            const Font = Quill.import('formats/font');
            Font.whitelist = ['serif', 'sans-serif', 'monospace', 'times-new-roman', 'arial', 'tahoma', 'helvetica'];
            Quill.register(Font, true);
            Quill.register({
                'modules/tableUI': QuillTableUI.default
            }, true)

            const toolbarOptions = [
                ["bold", "italic", "underline", "strike"],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "+1" }, { indent: "-1" }],
                [{ align: [] }],
                [{ size: ["small", "large", "huge", false] }],
                ["image", "link", "video", "formula"],
                [{ color: [] }, { background: [] }],
                [{ font: ["arial", "helvetica", "serif", "sans-serif", "monospace", "times-new-roman", "tahoma"] }],
                ["code-block", "blockquote"]
            ];

            const quill = new Quill(quillRef.current, {
                modules: {
                    toolbar: toolbarOptions,
                    table: true,
                    tableUI: true,
                },
                theme: 'snow',
            });

            setQuillInstance(quill);
        }
    }, [quillInstance]);

    const crearTabla = () => {
        if (quillInstance) {
            const table = quillInstance.getModule('table');
            if (table) {
                table.insertTable(2, 2); // You can specify the number of rows and columns
            }
        }
    };

    const handleOpenHtmlEditor = () => {
        // Make sure the editor content is up-to-date before opening
        traerDatosHTML();
        controlarMostrarModal();
    };

    // This useEffect hook is for initializing the Quill instance and fetching content once.
    useEffect(() => {
        if (quillInstance) {
            traerDatosHTML(); // Fetch data when Quill is ready.
        }
    }, [quillInstance]); // Only re-run if quillInstance changes, which should only happen when it's initialized.

    // This useEffect hook is for setting up the 'text-change' listener.
    useEffect(() => {
        if (quillInstance) {
            const handleChange = () => {
                const html = quillInstance.root.innerHTML;
                setEditorContent(html);
            };

            quillInstance.on("text-change", handleChange);

            // Cleanup function to remove the event listener when the component unmounts
            return () => {
                quillInstance.off("text-change", handleChange);
            };
        }
    }, [quillInstance]); // Only re-run if quillInstance changes.

    const setQuillContent = (content) => {
        if (quillInstance) {
            // Check if the clipboard module is available, which is the proper way to set HTML content
            if (quillInstance.clipboard) {
                quillInstance.clipboard.dangerouslyPasteHTML(content);
            } else {
                // If the clipboard module is not available, fall back to directly setting innerHTML
                quillInstance.root.innerHTML = content;
            }
        }
    };


    return (
        <Fragment>
            <Container>
                <div className="col-12">
                    <h1>Editor html</h1>
                </div>
                <div>
                    <Button variant="info" onClick={handleOpenHtmlEditor}>
                        Cambiar vista a HTML
                    </Button>
                </div>
                <br></br>
                <div className="align-items-center justify-content-between mb-3">
                    <div id="editando" ref={quillRef}>
                        <p><br /></p>
                    </div>
                    <br />
                    <Button variant="warning" onClick={crearTabla}>Crear tabla</Button>&nbsp;&nbsp;
                    <Button variant="success" onClick={ActualizarDatosdeContenido}>Actualizar Datos</Button>
                </div>
                {/* En el modal, el contenido HTML se muestra en el textarea y se puede editar */}
                <Modal show={showHtmlModal} backdrop="static" keyboard={false} onHide={CerrarModalHTML} size="lg"> {/* Set the size to large */}
                    <Modal.Header className="centered-title" closeButton>
                        <Modal.Title>Editor HTML</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <textarea
                            rows="10"
                            value={htmlCode} // El contenido HTML se muestra en el textarea
                            onChange={(e) => setHtmlCode(e.target.value)}
                            className="custom-textarea"
                            spellCheck="false"
                        />
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <Button variant="danger" onClick={CerrarModalHTML}>
                            Cerrar
                        </Button>
                        <Button variant="success" onClick={() => {
                            ActualizarContenidoQuill(htmlCode);
                            almacenarDatosHTML();
                        }}>
                            Guardar cambios
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </Fragment>
    );
}

export default withRouter(CursoUpdateHTML);