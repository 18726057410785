import React, { Fragment, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withRouter } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { FaCheckSquare, FaWindowClose, FaExternalLinkSquareAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js";
import { faL } from '@fortawesome/free-solid-svg-icons';
import ExcelJS from 'exceljs';

function TareasNotas(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    const { idCurso } = props;
    const { idTarea } = props;
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const cookies = new Cookies();
    // Estado para almacenar los idModulos disponibles
    const [idModulosOptions, setIdModulosOptions] = useState([]);
    const [tareas, setForos] = useState([]);
    const [respuesta, setForosRespuestas] = useState([]);
    const [nombresApellidos, setNombresApellidos] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [cursoSeleccionado, setForoSeleccionado] = useState(null);
    const [checkboxesSeleccionados, setCheckboxesSeleccionados] = useState({});
    const [nuevoCursoDocente, setNuevoCursoDocente] = useState([]);
    const [nuevoCursoEstudiante, setNuevoCursoEstudiante] = useState([]);
    const [soloForo, setsoloForo] = useState(true);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const { idModulos } = props;
    const [cursos, setCursos] = useState([]);
    const [editorContent, setEditorContent] = useState(''); // Content in rich text format
    const [htmlCode, setHtmlCode] = useState(''); // HTML code content
    const [showHtmlModal, setShowHtmlModal] = useState(false); // To control the HTML modal

    const [nuevaTarea, setNuevaTarea] = useState({
        idTarea: '',
        idUsuario: '',
        enlace_respuesta: '',
        fecha_entrega: '',
        calificacion: '',
    });

    // Capturando datos para el nuevaTarea.
    const ModuloTarea = {
        idTareas_respuesta: parseInt(nuevaTarea.idTareas_respuesta),
        calificacion: parseInt(nuevaTarea.calificacion),
    };
    const resetForm = () => {
        setNuevaTarea({
            idTarea: '',
            idUsuario: '',
            enlace_respuesta: '',
            fecha_entrega: '',
            calificacion: '',
        });
    };


    // Popup Editar
    const [editarTarea, setEditarTarea] = useState(false);

    const abrirEditarTarea = (respuestaItem) => {
        setEditarTarea(true);
        const usuario = nombresApellidos[respuestaItem.idTareas_respuesta];

        // Asignar los valores del tarea seleccionado al objeto nuevaTarea
        setNuevaTarea({
            ...respuestaItem, // Esto establece todos los campos de respuestaItem en nuevaTarea
            nombres: usuario?.nombres,
            apellidos: usuario?.apellidos
        });
        setShowPopup(true);
    };

    const togglePopup = () => {
        if (showPopup) {
            resetForm();
            setEditarTarea(false);
        }
        setShowPopup(!showPopup);
    };

    const fetchForos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "Foros/listarForosMenu", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setForos(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchForosRespuesta = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idTarea: idTarea };
            const result = await axios.post(baseUrl + "Tareas_respuesta/listarTareasRespuestas", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setForosRespuestas(result.data.tareas_respuestas);
                setNombresApellidos(result.data.nombresApellidos);
            }
            else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const handleExportExcelForos = () => {
        // Generar el archivo Excel con los datos de las respuestas de tareas
        const workbook = generarArchivoExcelForos({ tareas_respuestas: respuesta, nombresApellidos });

        // Escribir el archivo Excel en un buffer y descargarlo
        workbook.xlsx.writeBuffer().then((buffer) => {
            // Crear un Blob con el buffer y definir el tipo MIME
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crear una URL para el Blob
            const url = window.URL.createObjectURL(blob);

            // Crear un elemento <a> para descargar el archivo
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Reporte_de_tareas.xlsx'; // Nombre del archivo
            a.click();

            // Liberar la URL del Blob
            window.URL.revokeObjectURL(url);
        });
    };


    const generarArchivoExcelForos = (data) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        worksheet.columns = [
            { header: 'Nombre', key: 'nombres', width: 20, style: { font: { color: { argb: '000000' }, bold: true } } },
            { header: 'Apellido', key: 'apellidos', width: 20, style: { font: { color: { argb: '000000' }, bold: true } } },
            { header: 'Enlace', key: 'enlace_respuesta', width: 30, style: { font: { color: { argb: '000000' }, bold: true } } },
            { header: 'Fecha', key: 'fecha_registro', width: 20, style: { font: { color: { argb: '000000' }, bold: true } } },
            { header: 'Nota', key: 'calificacion', width: 10, style: { font: { color: { argb: '008000' }, bold: true } } }
        ];

        data.tareas_respuestas.forEach(respuesta => {
            const usuario = data.nombresApellidos[respuesta.idTareas_respuesta];
            worksheet.addRow({
                nombres: usuario?.nombres || 'N/A',
                apellidos: usuario?.apellidos || 'N/A',
                enlace_respuesta: respuesta.enlace_respuesta,
                fecha_registro: respuesta.fecha_registro,
                calificacion: respuesta.calificacion || 'Sin clasificar'
            });
        });

        return workbook;
    };



    const setOptionColor = (optionValue) => {
        if (optionValue >= 0 && optionValue <= 12) {
            return 'option-red';
        } else if (optionValue >= 13 && optionValue <= 20) {
            return 'option-green';
        }
    };

    // Agregar nuevo Modulo:
    const handleChange = (event) => {
        const { name, value } = event.target;
        setNuevaTarea(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const agregarTarea = async (event) => {
        event.preventDefault(); // Cancelar el evento por defecto
        try {
            if (editarTarea) {
                // EDITAR TAREA
                //
                // Establecer los valores del foro seleccionado al formulario popup
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevaTarea.calificacion
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                const response = await axios.post(
                    baseUrl + 'Tareas_respuesta/calificarTarea_respuesta',
                    ModuloTarea,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                // Realizar alguna acción después de registrar el foro exitosamente
                // Por ejemplo, cerrar el popup y volver a cargar la lista de tareas
                if (response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Calificado Correctamente!',
                        text: 'Nota calificada correctamente!',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    togglePopup();
                    fetchForos();
                    resetForm();
                }
            } else {
                // AGREGAR TAREA
                //
                // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
                if (
                    !nuevaTarea.titulo ||
                    !nuevaTarea.descripcion ||
                    !nuevaTarea.fecha_inicio ||
                    !nuevaTarea.fecha_fin ||
                    !nuevaTarea.idModulos
                ) {
                    throw new Error('Por favor, complete todos los campos.');
                }
                const token = cookies.get('token');
                if (soloForo) {

                    // Llamar a la API para agregar un foro, docentes y estudiantes
                    const response = await axios.post(
                        baseUrl + 'Foros/registrarForo',
                        ModuloTarea,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    // Realizar alguna acción después de registrar el foro exitosamente
                    // cerrar el popup y volver a cargar la lista de tareas
                    if (response.status === 201) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro exitoso',
                            text: 'Foro creado correctamente!',
                            showConfirmButton: true,
                            confirmButtonText: 'Ok'
                        });
                        togglePopup();
                        fetchForos();
                        resetForm();
                    }
                }
            }
        } catch (error) {
            // MANEJO DE ERRORES
            if (error.message === 'Por favor, complete todos los campos.') {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos incompletos!',
                    text: error.message
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Lo sentimos!',
                    text: error.message,
                });
            }
        }
    };


    const eliminarForo = async (idForo) => {
        Swal.fire({
            title: '¿Estás seguro de que deseas ELIMINAR este Foro?',
            text: 'El modulo y su contenido no se podrán recuperar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const request = {
                        idForo: idForo
                    };
                    const token = cookies.get('token');
                    const response2 = await axios.post(baseUrl + 'Foros/eliminarForo', request, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });

                    // Mostrar Sweet Alert
                    Swal.fire({
                        icon: 'success',
                        title: 'Foro eliminado correctamente',
                        showConfirmButton: true,
                        confirmButtonText: 'Ok'
                    });
                    fetchForos();

                } catch (error) {
                    console.error(error);
                    // Manejar el error de la solicitud al backend
                }
            } else {
                setForoSeleccionado(null); // Restablecer a null
            }
        });
    };
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    };

    //

    // Popup Editar
    const [editarForo, seteditarForo] = useState(false);

    const abrireditarForo = (foro) => {
        seteditarForo(true);
        togglePopup();

        // Asignar los valores del foro seleccionado al objeto nuevaTarea
        setNuevaTarea({
            idForo: foro.idForo,
            titulo: foro.titulo,
            descripcion: foro.descripcion,
            fecha_inicio: foro.fecha_inicio,
            fecha_fin: foro.fecha_fin,
            estado: foro.estado,
            idModulos: foro.idModulos
        });
    };


    const fetchIdModulos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Extraer los idModulos de la respuesta y almacenarlos en el estado
                setIdModulosOptions(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    useEffect(() => {
        fetchIdModulos();
        fetchForos();
        fetchForosRespuesta();
    }, [nuevaTarea]);
    // 

    useEffect(() => {
        // Realiza la validación cuando se actualicen los valores relevantes
        if (nuevaTarea.titulo && nuevaTarea.descripcion && nuevaTarea.fecha_inicio && nuevaTarea.fecha_fin) {
            setValidacionCompleta(true);
        } else {
            setValidacionCompleta(false);
        }
    }, [nuevaTarea.titulo, nuevaTarea.descripcion, nuevaTarea.fecha_inicio, nuevaTarea.fecha_fin]);

    // Paginación y limitación de mapeo de cursos

    const [currentPageCursos, setCurrentPageCursos] = useState(1);
    const coursesPorPage = 2; // Cantidad de cursos por página
    const totalPages = Math.ceil(respuesta.length / coursesPorPage);

    // Función para ir a la página anterior
    const previousPage = () => {
        if (currentPageCursos > 1) {
            setCurrentPageCursos(currentPageCursos - 1);
        }
    };

    // Función para ir a la página siguiente
    const nextPage = () => {
        if (currentPageCursos < totalPages) {
            setCurrentPageCursos(currentPageCursos + 1);
        }
    };

    const indexOfLastCoursePage = currentPageCursos * coursesPorPage;
    const indexOfFirstCoursePage = indexOfLastCoursePage - coursesPorPage;
    const currentCoursesA = respuesta.slice(indexOfFirstCoursePage, indexOfLastCoursePage);

    // Generar los botones de paginación
    const pageNumbersA = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbersA.push(i);
    }

    return (
        <Fragment>
            <Container>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="col-12">
                        <h1>Lista Respuestas de Tareas</h1>
                        <br />
                        {currentCoursesA.length > 0 && (
                            <Button variant="success" onClick={() => handleExportExcelForos()}>Exportar a Excel  <FaExternalLinkSquareAlt /></Button>
                        )}
                    </div>
                </div>



                {currentCoursesA.length > 0 ? (
                    <Table hover className="custom-table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Apellido</th>
                                <th>Enlace</th>
                                <th>Fecha</th>
                                <th>Nota</th>
                                <th>Calificar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentCoursesA.map((respuestaItem, index) => {
                                const usuario = nombresApellidos[respuestaItem.idTareas_respuesta];
                                return (
                                    <tr key={respuestaItem.idTareas_respuesta}>
                                        <td className="truncate-text-tabla-modulos-nombreyapellido align-middle" >{usuario?.nombres}</td>
                                        <td className="truncate-text-tabla-modulos-nombreyapellido align-middle">{usuario?.apellidos}</td>
                                        <td className="truncate-text-tabla-modulos-link align-middle">{respuestaItem.enlace_respuesta}</td>
                                        <td className="align-middle">{formatDate(respuestaItem.fecha_registro)}</td>
                                        <td className="align-middle">
                                            {
                                                respuestaItem.calificacion !== null ? (
                                                    respuestaItem.calificacion >= 12 ? (
                                                        <span style={{ color: 'green' }}>{respuestaItem.calificacion}</span>
                                                    ) : (
                                                        <span style={{ color: 'red' }}>{respuestaItem.calificacion}</span>
                                                    )
                                                ) : (
                                                    <span style={{ color: '#004d99' }}>Sin clasificar</span>
                                                )
                                            }
                                        </td>

                                        <td className="align-middle">
                                            <Button variant="warning" onClick={() => abrirEditarTarea(respuestaItem)}> <FaCheckSquare /></Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>

                ) : (
                    <h3 className="nohaycursoslista">No hay Tareas para calificar</h3>
                )}
                {/* Paginación */}
                <nav aria-label="Page navigation example" className="fixed-pagination2">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {pageNumbersA.map((number) => (
                            <li key={number} className={`page-item ${currentPageCursos === number ? "active" : ""}`}>
                                <a className="page-link" href="#" onClick={() => setCurrentPageCursos(number)}>
                                    {number}
                                </a>
                            </li>
                        ))}
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </Container>
            <CSSTransition
                in={showPopup}
                timeout={300}
                classNames="popup-transition"
                unmountOnExit
            >
                <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} className="popup-content" contentStyle={{ maxWidth: '745px' }} >
                    <div className="popup-wrapperTR" style={{ width: '1035px' }}>
                        <div className="popup-contentTR">
                            <div className="popup-headerTR">
                                <div className="popup-titleTR">
                                    Ingrese la nota a calificar
                                </div>
                                <div className="popup-close-buttonTR">
                                    <FaWindowClose color="#c82333" className="invert-icon" onClick={togglePopup} style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                            <hr />
                            <form onSubmit={agregarTarea}>
                                <div className="input-groupTR">
                                    <span>Nombres:</span>
                                    <p className="popup-textTR">  {nuevaTarea.nombres || 'No disponible'}</p>
                                </div>

                                <div className="input-groupTR">
                                    <span>Apellidos:</span>
                                    <p className="popup-textTR">  {nuevaTarea.apellidos || 'No disponible'}</p>
                                </div>

                                <div className="input-groupTR">
                                    <span>Enlace de Respuesta:</span>
                                    <a style={{
                                        color: '#007bff',
                                        transition: 'color 0.3s ease-in-out',
                                        cursor: 'pointer'
                                    }} href={nuevaTarea.enlace_respuesta} target="_blank" rel="noopener noreferrer" className="popup-text">
                                        <u>{nuevaTarea.enlace_respuesta || 'No disponible'}</u>
                                    </a>
                                </div>

                                <div className="input-groupTR">
                                    <span>Fecha de Registro:</span>
                                    <p className="popup-text">
                                        {nuevaTarea.fecha_registro ? formatDate(nuevaTarea.fecha_registro) : 'No disponible'}
                                    </p>
                                </div>
                                <div className="input-groupTR">
                                    <span>Calificación:</span>
                                    <select
                                        id="calificacion"
                                        type="text"
                                        className="popup-inputTR"
                                        placeholder="Ingrese la nueva calificación"
                                        name="calificacion"
                                        value={nuevaTarea.calificacion || ''}
                                        onChange={handleChange}
                                        required
                                    >
                                        {Array.from({ length: 21 }, (_, i) => (
                                            <option
                                                key={i}
                                                value={i}
                                                className={setOptionColor(i)}
                                            >
                                                {i.toString().padStart(2, '0')}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="input-groupTR">
                                    <button type="submit" className="btn btn-block mybtn btn-successTR tx-tfm naranjabtn">
                                        {editarTarea ? "Guardar cambios" : "Crear nueva tarea"}
                                    </button>
                                </div>
                            </form>


                        </div>
                    </div>
                </Popup>
            </CSSTransition>
        </Fragment>
    );
}

export default withRouter(TareasNotas);