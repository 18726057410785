import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

const MobileDropdownMenu = ({ activeOption, handleClick }) => {
    return (
        <div className="d-md-none">
            <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                    {activeOption || "Navegar"}
                </Dropdown.Toggle>


                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() => handleClick('Inicio')}
                        className={activeOption === 'Inicio' ? 'selected custom-dropdown-item' : 'custom-dropdown-item'}
                    >
                        INICIO
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => handleClick('Modulo')}
                        className={activeOption === 'Modulo' ? 'selected custom-dropdown-item' : 'custom-dropdown-item'}
                    >
                        MÓDULO
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => handleClick('Contenido')}
                        className={activeOption === 'Contenido' ? 'selected custom-dropdown-item' : 'custom-dropdown-item'}
                    >
                        CONTENIDO
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => handleClick('Examenes')}
                        className={activeOption === 'Examenes' ? 'selected custom-dropdown-item' : 'custom-dropdown-item'}
                    >
                        EXAMEN
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => handleClick('Tarea')}
                        className={activeOption === 'Tarea' ? 'selected custom-dropdown-item' : 'custom-dropdown-item'}
                    >
                        TAREA
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => handleClick('Foro')}
                        className={activeOption === 'Foro' ? 'selected custom-dropdown-item' : 'custom-dropdown-item'}
                    >
                        FORO
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default MobileDropdownMenu;
