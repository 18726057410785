import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import axios from "axios";
import { url } from "../config";

const CerrarSesion = async (props) => {
    const baseUrl = url;
    const cookies = new Cookies();
    //const history = useHistory();

    try {
        const token = cookies.get("token");
        const resultado = await axios.post(
            baseUrl + "usuarios/restablecer",
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        cookies.remove("token", { path: "/" });
        props.history.push("./");
    } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
    }

    return (<></>);
};

export default CerrarSesion;