import React, { Fragment, useEffect, useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import {
  FaArrowUp,
  FaChevronDown,
  FaArrowDown,
  FaPencilAlt,
  FaTrash,
  FaChevronRight,
  FaArrowCircleLeft,
  FaTable,
  FaPlusSquare,
  FaComments,
} from "react-icons/fa";
import Cookies from "universal-cookie";
import axios from "axios";
import { withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import CursoForoCrud from "./CursoForoCrud";
import CursoForoDiscu from "./CursoForoDiscu";
import ForoEditorHTML from "./ForoEditorHTML";
import ForoUpdateHTML from "./ForoUpdateHTML";
import { url } from "../../../../../config";
import { CSSTransition } from 'react-transition-group';
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function CursoForo(props) {

  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  }
  const baseUrl = url;
  const { idCurso } = props;
  const { idUser } = props;
  const { idPagina } = props;
  const [nombreforos, setNombreForos] = useState([]);
  const [nombreClase, setNombreClase] = useState("");
  const [mostrarCrud, setMostrarCrud] = useState(false);
  const [selectedForum, setSelectedForum] = useState(null);
  const [cursoSeleccionado, setForoSeleccionado] = useState(null);
  const [showCursoForoDiscu, setShowCursoForoDiscu] = useState(false); // Add a state for CursoForoDiscu visibility
  const [mostrarEditor, setMostrarEditor] = useState(false);
  const [mostrarUpdate, setMostrarUpdate] = useState(false);
  const [idModulo, setIdModulo] = useState(null);
  const [idForo, setIdForo] = useState(null);
  const [idModulosArray, setIdModulosArray] = useState([]); // Initialize idModulosArray
  // Estado para controlar si el collapse está abierto o cerrado
  const [open, setOpen] = useState(true);

  const [mostrarTareasCrud, setMostrarTareasCrud] = useState(false);
  const [showHeader, setShowHeader] = useState(true); // Initialize as true  

  const hasForos = (moduleId) => {
    // Assuming `nombreforos` contains exam data fetched from your API
    return nombreforos.some((foros) => foros.idModulos === moduleId);
  };
  const handleOcultarCrud = () => {
    setMostrarTareasCrud(false);
  };


  const handlemostrarForo = () => {
    setShowCursoForoDiscu(true);
  }

  const handleocultarForo = () => {
    setShowCursoForoDiscu(false);
  }

  // Define the functions
  const handleAgregarPagina = (idModulo) => {
    setIdModulo(idModulo);
    setMostrarEditor(true);
  };

  const handleOcultarAgregar = () => {
    setMostrarEditor(false);
  };

  const handleEditarPagina = (idForo) => {
    setIdForo(idForo);
    setMostrarUpdate(true);
  };

  const handleOcultarEditar = () => {
    setMostrarUpdate(false);
  };

  const handlemostrarEditor = () => {
    setMostrarEditor(true);
  };

  const handlemostrarUpdate = () => {
    setMostrarUpdate(true);
  };

  const handleMostrarCrud = () => {
    setMostrarCrud(true);
  };

  const handleOcultarsCrud = () => {
    setMostrarCrud(false);
  };

  // Define a function to render CursoForoDiscu based on the selected forum
  const renderSelectedForum = () => {
    if (selectedForum) {
      return (
        <CursoForoDiscu
          idCurso={idCurso}
          idModulos={selectedForum.idModulos}
          forum={selectedForum}
          idUser={idUser}
        />
      );
    }
    return null;
  };

  // Función para alternar el collapse
  const toggleCollapse = () => {
    setOpen(!open);
  };


  // Cerrar sesión


  const cookies = new Cookies();
  const usuarioId = parseInt(idUser, 10);

  const EliminarForo = async (idForo) => {
    Swal.fire({
      title: "¿Estás seguro de que deseas ELIMINAR este Foro?",
      text: "El modulo y su contenido no se podrán recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const request = {
            idForo: idForo,
          };
          const token = cookies.get("token");
          const response2 = await axios.post(
            baseUrl + "Foros/eliminarForo",
            request,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Mostrar Sweet Alert
          Swal.fire({
            icon: "success",
            title: "Foro eliminado correctamente",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
          fetchForos();
        } catch (error) {
          console.error(error);
          // Manejar el error de la solicitud al backend
        }
      } else {
        setForoSeleccionado(null); // Restablecer a null
      }
    });
  };

  const renderHeader = () => (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {nombreClase.nom_curso && <h5 style={{ margin: '0', marginRight: '10px' }}>{nombreClase.nom_curso}</h5>}
        <FaChevronRight color="#F47735" />
        <h5 style={{ margin: '0', marginLeft: '10px' }}>Portafolio de Foros</h5>
        <FaChevronRight color="#F47735" />
        <h5 style={{ margin: '0', marginLeft: '10px' }}>Foro</h5>
      </div>
      <hr />
    </div>
  );


  //<------------------------------------------MANEJANDO REORGANIZACION------------------------------------------
  // 0
  // Estado para rastrear si se han cargado las tareas
  const [forosFetched, setForosFetched] = useState(false);
  const [forosOrdenados, setForosOrdenados] = useState([]);
  const [alertaMostrada, setAlertaMostrada] = useState(false);

  //1
  const fetchForos = async () => {
    try {
      const token = cookies.get("token");
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(
        baseUrl + "Foros/listarForosMenu",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        console.log("Respuesta exitosa en fetchExamenes:", result.data);
        const idModulo = result.data[0].idModulos; // Extract idModulo from the first object
        const idModulosArray = result.data.map((item) => item.idModulos); // Extract idModulos as an array
        setIdModulo(idModulo); // Set idModulo in the state
        setIdModulosArray(idModulosArray); // Set idModulosArray in the state
        setNombreForos(result.data);
        setForosFetched(true);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  // 2
  // Función para manejar la confirmación de movimiento de tareas
  const confirmMoveForo = (currentIndex, targetIndex) => {
    if (!alertaMostrada) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Deseas mover este foro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, moverlo',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          // Marcar la alerta como mostrada

          handleMoveTarea(currentIndex, targetIndex);
        }
      });

      // Marcar la alerta como mostrada
      setAlertaMostrada(true);
    } else {
      // Si la alerta ya se mostró antes, simplemente realizar la acción
      handleMoveTarea(currentIndex, targetIndex);
    }
  };

  // 3
  // Función para manejar el movimiento de tareas
  const handleMoveTarea = (currentIndex, targetIndex) => {
    const updatedForos = [...nombreforos];
    const temp = updatedForos[currentIndex];
    updatedForos[currentIndex] = updatedForos[targetIndex];
    updatedForos[targetIndex] = temp;

    // Actualizar el estado con los tareas reordenados
    setNombreForos(updatedForos);

    // Actualizar el estado de los IDs de los tareas reordenados
    setForosOrdenados(updatedForos.map(foro => foro.idForo));

    // Aquí puedes enviar el nuevo orden al backend o realizar cualquier otra operación necesaria
    guardarOrdenModulos(updatedForos);
  };

  // 4
  const guardarOrdenModulos = async (updatedForos) => {
    try {
      const token = cookies.get('token');
      const requestBody = {
        idCurso: idCurso,
        idPagina: idPagina,
        nuevoOrden: updatedForos.map(foro => foro.idForo).join(",")
      };
      const result = await axios.post(baseUrl + "OrdenModulo/editarOrdenModulos", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        console.log("Orden de módulos guardado exitosamente");
      } else {
        // Manejar errores o respuestas no exitosas
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  //????
  const ordenarTareasSegunId = (forosOrdenados, tareasOriginales) => {
    return forosOrdenados.map(idForo =>
      tareasOriginales.find(foro => foro.idForo === idForo)
    );
  };

  // 5
  const fetchForosOrdenados = async () => {
    try {
      console.log("Ejecutando useEffect para fetchModulosOrdenados...");
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso, idPagina: 6 };
      const result = await axios.post('https://localhost:44322/api/OrdenModulo/listarModulos', requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        console.log("Respuesta exitosa en fetchModulosOrdenados:", result.data);
        setForosOrdenados(result.data);
      } else {
        console.error("Error al obtener los módulos ordenados");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 6
  // Efecto para cargar los tareas al montar el componente o cuando cambia el estado de buttonClicked
  useEffect(() => {
    fetchForos();
  }, [idCurso]);

  useEffect(() => {
    if (forosFetched) { // Solo ejecuta si fetchModulos se ha completado
      console.log("Ejecutando useEffect para fetchModulosOrdenados...");
      fetchForosOrdenados();
    }
  }, [forosFetched]);  // Ahora solo depende de modulosFetched
  //<---------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    const fetchNombreCurso = async () => {
      try {
        const token = cookies.get("token");
        const requestBody = { idCurso: idCurso };
        const result = await axios.post(
          baseUrl + "cursos/buscarCurso",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.status === 200) {
          setNombreClase(result.data);
        } else {
          // Realizar alguna acción en caso de que la respuesta no sea exitosa
        }
      } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
      }
    };

    fetchForos();
    fetchNombreCurso();


  }, [idCurso]);

  return (
    <Fragment>

      <div className="container">
        {showCursoForoDiscu && (
          <>
            <div style={{ position: 'absolute', top: '19px', right: '162px' }}>

              <Button className="tealbu direction-button" onClick={handleocultarForo}>
                Ver lista de foros <FaArrowCircleLeft className="fa-icon" />
              </Button>
            </div>
          </>
        )}
        {showCursoForoDiscu ? (
          <>
            <CursoForoDiscu
              idCurso={idCurso}
              idModulos={selectedForum.idModulos}
              forum={selectedForum}
              idForo={selectedForum.idForo}
              idUser={idUser}
            />
          </>

        ) : (
          <div>
            {showHeader && renderHeader()} {/* Show header if showHeader is true */}
            <div className="d-flex justify-content-end mb-3">
              {usuarioId === 4 || usuarioId === 5 ? (
                <div className="d-flex justify-content-end mb-3">
                  {!showCursoForoDiscu && !mostrarCrud && !mostrarEditor && !mostrarUpdate && (
                    <>
                      <Button className="verdetabla direction-button" onClick={handleMostrarCrud}>
                        Ver tabla de Foros <FaTable className="fa-icon" />
                      </Button>&nbsp;&nbsp;
                      <Button className="azulbu direction-button" onClick={() => handleAgregarPagina(idCurso)}>
                        Agregar Foro <FaPlusSquare className="fa-icon" />
                      </Button>
                    </>
                  )}
                  {mostrarCrud && (
                    <Button className="tealbu direction-button" onClick={handleOcultarsCrud}>
                      Volver a modulos foros <FaArrowCircleLeft className="fa-icon" />
                    </Button>
                  )}
                  {mostrarEditor && (
                    <Button className="tealbu direction-button" onClick={handleOcultarAgregar}>
                      Volver a modulos foros <FaArrowCircleLeft className="fa-icon" />
                    </Button>
                  )}
                  {mostrarUpdate && (
                    <Button className="tealbu direction-button" onClick={handleOcultarEditar}>
                      Volver a modulos foros <FaArrowCircleLeft className="fa-icon" />
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
            {mostrarCrud ? (
              <CursoForoCrud idCurso={idCurso} />
            ) : mostrarEditor ? (
              <ForoEditorHTML idModulos={idModulo} idCurso={idCurso} />
            ) : mostrarUpdate ? (
              <ForoUpdateHTML idForo={idForo} idCurso={idCurso} />
            ) : (
              <div className='item-group-condensed ig-header'>
                <div className="collapse-header" onClick={toggleCollapse}>
                  {open ? <FaChevronDown color="#F47735" /> : <FaChevronRight color="#F47735" />}
                  <h4 className="naranjah4">Foros registrados</h4>
                </div>
                <Collapse in={open}>
                  <div className="collapse-content">
                    {forosOrdenados.length > 0 ? forosOrdenados.map((foroId, index) => {
                      const foro = nombreforos.find(t => t.idForo === foroId); // Buscar la tarea por ID
                      if (!foro) return null; // Si no se encuentra la tarea, no renderizar
                      return (
                        <Fragment key={index}>
                          {/* Foro details */}
                          <hr className='lineamodulo' />
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/*BOTONES DE REORDENAMIENTO*/}
                            {/*{usuarioId === 4 || usuarioId === 5 ? (
                              <div className="d-flex align-items-center" style={{ flexDirection: 'column' }}>
                               
                                {index > 0 && (
                                  <Button className="boton-arriba blue-outline-button mb-2" onClick={() => confirmMoveForo(index, index - 1)}>
                                    <FaArrowUp />
                                  </Button>
                                )}                                
                                {index < nombreforos.length - 1 && (
                                  <Button className="boton-abajo green-outline-button" onClick={() => confirmMoveForo(index, index + 1)}>
                                    <FaArrowDown />
                                  </Button>
                                )}
                              </div>
                            ) : null}&nbsp;&nbsp;&nbsp;&nbsp;*/}
                            <span><h4 className="naranjah4 truncate-text-listar-modulo-tarea">{foro.titulo}</h4></span>
                            <div className="d-flex align-items-center justify-content-end texto-derecha ml-auto">
                              {usuarioId === 4 || usuarioId === 5 ? (
                                <>
                                  <Button className="yellow-outline-button me-2" onClick={() => handleEditarPagina(foro.idForo)}>
                                    <FaPencilAlt color="#F47735" />
                                  </Button>
                                  <Button className="red-outline-button me-2" onClick={() => EliminarForo(foro.idForo)}>
                                    <FaTrash color="red" />
                                  </Button>
                                </>
                              ) : null}
                              <Button
                                className="forobu direction-button"
                                onClick={() => {
                                  setSelectedForum({
                                    ...foro,
                                    idForo: foro.idForo,
                                  });
                                  handlemostrarForo();
                                }}
                              >
                                Ir al Foro <FaComments className="fa-icon" />
                              </Button>
                            </div>
                          </div>

                          <hr className='lineamodulo' />
                        </Fragment>
                      );
                    }) : nombreforos.length > 0 ?
                      nombreforos.map((foro, index) => {
                        // Aquí va el código para renderizar nomtarea en su orden original...
                        return (
                          <Fragment key={index}>
                            {/* Foro details */}
                            <hr className='lineamodulo' />
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {/*BOTONES DE REORDENAMIENTO2*/}
                              {/*{usuarioId === 4 || usuarioId === 5 ? (
                                <div className="d-flex align-items-center" style={{ flexDirection: 'column' }}>
                                  {index > 0 && (
                                    <Button className="boton-arriba blue-outline-button mb-2" onClick={() => confirmMoveForo(index, index - 1)}>
                                      <FaArrowUp />
                                    </Button>
                                  )}
                                  {index < nombreforos.length - 1 && (
                                    <Button className="boton-abajo green-outline-button" onClick={() => confirmMoveForo(index, index + 1)}>
                                      <FaArrowDown />
                                    </Button>
                                  )}
                                </div>
                              ) : null}&nbsp;&nbsp;&nbsp;&nbsp;*/}
                              <h4 className="naranjah4 cambio-truncate-moviles me-2">{foro.titulo}</h4>
                              <div className="d-flex align-items-center justify-content-end texto-derecha ml-auto">
                                {usuarioId === 4 || usuarioId === 5 ? (
                                  <>
                                    <Button className="yellow-outline-button me-2" onClick={() => handleEditarPagina(foro.idForo)}>
                                      <FaPencilAlt color="#F47735" />
                                    </Button>
                                    <Button className="red-outline-button me-2" onClick={() => EliminarForo(foro.idForo)}>
                                      <FaTrash color="red" />
                                    </Button>
                                  </>
                                ) : null}
                                <Button
                                  className="forobu direction-button"
                                  onClick={() => {
                                    setSelectedForum({
                                      ...foro,
                                      idForo: foro.idForo,
                                    });
                                    handlemostrarForo();
                                  }}
                                >
                                  Ir al Foro <FaComments className="fa-icon" />
                                </Button>
                              </div>
                            </div>

                            <hr className='lineamodulo' />
                          </Fragment>
                        );
                      })
                      :
                      <h3 className="nohaycursos">No hay Foros registrados.</h3>
                    }
                  </div>
                </Collapse>
              </div>
            )}
          </div>
        )}
        {/* Popup and other components if any */}
      </div>
    </Fragment>
  );
};
export default withRouter(CursoForo);
