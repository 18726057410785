import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { FaExternalLinkSquareAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import 'reactjs-popup/dist/index.css';
import 'react-tabs/style/react-tabs.css';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../../config";
import SessionManager from "../../../../../SessionManagement.js";
import CerrarSesion from "../../../../../CerrarSesion.js"
import ExcelJS from 'exceljs';
import { DateTime } from 'luxon';

function ExamenRespuestas(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    const { idCurso } = props;
    const { idExamen } = props;
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const cookies = new Cookies();
    // Estado para almacenar los idModulos disponibles
    const [idModulosOptions, setIdModulosOptions] = useState([]);
    const [tareas, setForos] = useState([]);
    const [respuesta, setForosRespuestas] = useState([]);
    const [nombresApellidos, setNombresApellidos] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [cursoSeleccionado, setForoSeleccionado] = useState(null);
    const [soloForo, setsoloForo] = useState(true);
    const [validacionCompleta, setValidacionCompleta] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [examenesRespuestas, setExamenesRespuestas] = useState([]);
    const pageSize = 120;// Estado para el tamaño de página


    const fetchForos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "Examen_respuesta/listarExamen_respuesta", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setForos(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const fetchExamenesRespuestas = async (pageNumber) => {
        try {
            const token = cookies.get('token');
            const requestBody = { idExamen: idExamen };
            const result = await axios.post(baseUrl + "Examen_respuesta/listarExamenesRespuestas?pageNumber=" + pageNumber, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                setForosRespuestas(result.data.examen_respuestas);
                setNombresApellidos(result.data.nombresApellidos);
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };


    useEffect(() => {
        fetchExamenesRespuestas(currentPage);
    }, [currentPage]); // Esto se ejecutará cada vez que currentPage cambie

    const nextPageApi = () => {
        setCurrentPage(currentPage + 1);
        setCurrentPageCursos(1);
    };

    const previousPageApi = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
        setCurrentPageCursos(1);
    };

    const handleExportExcel = () => {
        const workbook = generarArchivoExcel({ examen_respuestas: respuesta, nombresApellidos });
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'reporte_notas_examenes.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const generarArchivoExcel = (data) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Datos');
        worksheet.columns = [
            { header: 'Nombres', key: 'nombres', width: 20, style: { font: { color: { argb: '000000' }, bold: true } } },
            { header: 'Apellidos', key: 'apellidos', width: 20, style: { font: { color: { argb: '000000' }, bold: true } } },
            { header: 'Fecha de Inicio', key: 'fecha_inicio_examen', width: 20, style: { font: { color: { argb: '000000' }, bold: true } } },
            { header: 'Fecha de Fin', key: 'fecha_fin_examen', width: 20, style: { font: { color: { argb: '000000' }, bold: true } } },
            { header: 'Nota', key: 'nota', width: 10, style: { font: { color: { argb: '008000' }, bold: true } } },
            { header: 'Intentos', key: 'intentos', width: 10, style: { font: { color: { argb: '0000FF' }, bold: true } } },
            { header: 'Intentos Hechos', key: 'inten_hechos', width: 15, style: { font: { color: { argb: '0000FF' }, bold: true } } }
        ];

        data.examen_respuestas.forEach(respuesta => {
            const usuario = data.nombresApellidos[respuesta.idExamen_respuesta];
            worksheet.addRow({
                id: respuesta.idExamen_respuesta,
                fecha_inicio_examen: respuesta.fecha_inicio_examen,
                nota: respuesta.nota,
                fecha_fin_examen: respuesta.fecha_fin_examen,
                intentos: respuesta.intentos,
                inten_hechos: respuesta.inten_hechos,
                idExamen: respuesta.idExamen,
                idUsuario: respuesta.idUsuario,
                nombres: usuario?.nombres || 'N/A',
                apellidos: usuario?.apellidos || 'N/A'
            });
        });
        return workbook;
    };


    const setOptionColor = (optionValue) => {
        if (optionValue >= 0 && optionValue <= 12) {
            return 'option-red';
        } else if (optionValue >= 13 && optionValue <= 20) {
            return 'option-green';
        }
    };

    const formatDate = (dateString) => {
        // Convertir la fecha y hora del servidor a un objeto DateTime de Luxon
        const serverDateTime = DateTime.fromISO(dateString, { zone: 'UTC' }); // Especificar la zona horaria del servidor (UTC)

        // Obtener la fecha y hora local del cliente
        const localDateTime = serverDateTime.toLocal();

        // Formatear la fecha y hora local según tus preferencias
        return localDateTime.toLocaleString(DateTime.DATETIME_SHORT);
    };

    const fetchIdModulos = async () => {
        try {
            const token = cookies.get('token');
            const requestBody = { idCurso: idCurso };
            const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (result.status === 200) {
                // Extraer los idModulos de la respuesta y almacenarlos en el estado
                setIdModulosOptions(result.data);
            } else {
                // Realizar alguna acción en caso de que la respuesta no sea exitosa
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    useEffect(() => {
        fetchIdModulos();
        fetchForos();
    }, []);
    // 

    // Paginación y limitación de mapeo de cursos

    const [currentPageCursos, setCurrentPageCursos] = useState(1);
    const coursesPorPage = 6; // Cantidad de cursos por página
    const totalPages = Math.ceil(respuesta.length / coursesPorPage);

    // Función para ir a la página anterior
    const previousPage = () => {
        if (currentPageCursos > 1) {
            setCurrentPageCursos(currentPageCursos - 1);
        }
    };

    // Función para ir a la página siguiente
    const nextPage = () => {
        if (currentPageCursos < totalPages) {
            setCurrentPageCursos(currentPageCursos + 1);
        }
    };

    const indexOfLastCoursePage = currentPageCursos * coursesPorPage;
    const indexOfFirstCoursePage = indexOfLastCoursePage - coursesPorPage;
    const currentCoursesA = respuesta.slice(indexOfFirstCoursePage, indexOfLastCoursePage);

    // Generar los botones de paginación
    const pageNumbersA = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbersA.push(i);
    }

    return (
        <Fragment>
            <Container>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="col-12">
                        <h1>Lista Respuestas de Examen</h1>
                    </div>

                </div>
                {currentCoursesA.length > 0 ? (
                    <div className="table-container table-containerEXAM">
                        <div className="table-responsive">
                            <Table hover className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Apellido</th>
                                        <th>Hora Inicio</th>
                                        <th>Hora final</th>
                                        <th>Nota</th>
                                        <th>Intentos</th>
                                        <th>Exportar</th>
                                    </tr> 
                                </thead>
                                <tbody>
                                    {currentCoursesA.map((respuestaItem, index) => {
                                        const usuario = nombresApellidos[respuestaItem.idExamen_respuesta];
                                        return (
                                            <tr key={respuestaItem.idExamen_respuesta}>
                                                <td className="truncate-text-tabla-modulos-nombreyapellido align-middle" >{usuario?.nombres}</td>
                                                <td className="truncate-text-tabla-modulos-nombreyapellido align-middle">{usuario?.apellidos}</td>
                                                <td className="align-middle">{formatDate(respuestaItem.fecha_inicio_examen)}</td>
                                                <td className="align-middle">{formatDate(respuestaItem.fecha_fin_examen)}</td>
                                                <td className="align-middle" style={{ color: respuestaItem.nota >= 12 ? '#00a84a' : '#ff596b' }}>
                                                    {respuestaItem.nota || <span style={{ color: '#ff596b' }}>Sin clasificar</span>}
                                                </td>
                                                <td className="align-middle">{respuestaItem.inten_hechos + '/' + respuestaItem.intentos}</td>
                                                <td className="align-middle">
                                                    <Button variant="success" onClick={handleExportExcel}> <FaExternalLinkSquareAlt /></Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        {/* Pagination buttons */}
                        <div className="pagination-buttons">
                            <button onClick={previousPageApi} disabled={currentPage === 1}>Anterior</button>
                            <button onClick={nextPageApi} className="next-button">Siguiente</button>
                        </div>
                    </div>
                ) : (
                    <h3 className="nohaycursoslista">No hay Examenes Completados</h3>
                )}

                {/* Paginación */}
                <nav aria-label="Page navigation example" className="fixed-pagination3">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Previous" onClick={previousPage}>
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        {pageNumbersA.map((number) => (
                            <li key={number} className={`page-item ${currentPageCursos === number ? "active" : ""}`}>
                                <a className="page-link" href="#" onClick={() => setCurrentPageCursos(number)}>
                                    {number}
                                </a>
                            </li>
                        ))}
                        <li className="page-item">
                            <a className="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </Container>
        </Fragment>
    );
}

export default withRouter(ExamenRespuestas);