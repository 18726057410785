import React, { Fragment, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import jwt_decode from 'jwt-decode';
import { url } from "../../../../../../config";
import SessionManager from "../../../../../SessionManagement.js";
import CerrarSesion from "../../../../../CerrarSesion.js"


function ExamenVPrevia(props) {
    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idExamen } = props;
    const { idCurso } = props;
    const [nombreClase, setNombreClase] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [examenPreguntas, setExamenPreguntas] = useState([]);
    const [opcionesPorPregunta, setOpcionesPorPregunta] = useState({});
    const [preguntaActual, setPreguntaActual] = useState(0);
    const [examCompleted, setExamCompleted] = useState(false);
    const [respuestas, setRespuestas] = useState([]);


    // 
    // Cerrar sesión


    const cookies = new Cookies();

    useEffect(() => {
        const fetchPreguntas = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idExamen: idExamen };
                const result = await axios.post(baseUrl + "examen/vistaPreviaExamen", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    setExamenPreguntas(result.data.examenPreguntas);
                    setOpcionesPorPregunta(result.data.opcionesPorPregunta);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };

        const fetchNombreCurso = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idCurso: idCurso };
                const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    setNombreClase(result.data);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };



        fetchNombreCurso();
        fetchPreguntas();




    }, []);

    // Manejar el avance a la siguiente pregunta
    const handleSiguientePregunta = () => {
        if (selectedOption !== '') {
            const respuestaActual = { preguntaId: examenPreguntas[preguntaActual].idExamen_preguntas, opcionId: selectedOption };
            setRespuestas([...respuestas, respuestaActual]);

            if (preguntaActual < examenPreguntas.length - 1) {
                setPreguntaActual(preguntaActual + 1);
                setSelectedOption('');
            }
        }
    };

    return (
        <div>
            <Fragment>
                <br />
                <Container className="my-5 p-3 bg-light border rounded mas-alto"  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {nombreClase.nom_curso && <h5 style={{ margin: '0', marginRight: '10px' }}>{nombreClase.nom_curso}</h5>}
                        <span style={{ fontSize: '12px', color: '#888' }}>{'\u003E'}</span>
                        <h5 style={{ margin: '0', marginLeft: '10px' }}>Portafolio de Examenes</h5>
                        <span style={{ fontSize: '12px', color: '#888' }}>{'\u003E'}</span>
                        <h5 style={{ margin: '0', marginLeft: '10px' }}>Examenes</h5>
                        <span style={{ fontSize: '12px', color: '#888' }}>{'\u003E'}</span>
                        <h5 style={{ margin: '0', marginLeft: '10px' }}>Vista Previa del Examen</h5>
                    </div>
                    <hr />
                    <h1>Vista previa del examen</h1>

                    <main style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {examenPreguntas[preguntaActual] ? (
                            <div key={examenPreguntas[preguntaActual].idExamen_preguntas}>
                                <div className="recuadro-naranja-vprevia">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px', backgroundColor: '#F47735', padding: '5px 10px', borderRadius: '3px' }}>
                                        <h2 style={{ textAlign: 'center', margin: '0' }}>{examenPreguntas[preguntaActual].descripcion_pregunta}</h2>
                                        <p style={{ backgroundColor: 'white', color: '#F47735', padding: '5px 10px', borderRadius: '3px' }}>Puntos: {examenPreguntas[preguntaActual].puntos}</p>
                                    </div>
                                    {opcionesPorPregunta[examenPreguntas[preguntaActual].idExamen_preguntas] &&
                                        opcionesPorPregunta[examenPreguntas[preguntaActual].idExamen_preguntas].map((opcion) => (
                                            <label
                                                key={opcion.idExamen_pregunta_opciones}
                                                className={`opcion-label ${selectedOption === opcion.idExamen_pregunta_opciones ? 'seleccionada' : ''}`}
                                                onClick={() => setSelectedOption(opcion.idExamen_pregunta_opciones)}
                                                style={{
                                                    backgroundColor: '#F47735',
                                                    marginBottom: '10px',
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    transition: 'background-color 0.3s ease',
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    name={`respuesta_${examenPreguntas[preguntaActual].idExamen_preguntas}`}
                                                    value={opcion.idExamen_pregunta_opciones}
                                                    style={{ marginRight: '10px', cursor: 'pointer' }}
                                                />
                                                <span className='truncate-span-examen'>{opcion.valor}</span>
                                            </label>
                                        ))}
                                </div>
                            </div>
                        ) : (
                            <p><em>Lo sentimos, parece que no hay preguntas registradas</em></p>
                        )}

                        <div>
                            {preguntaActual > 0 && (
                                <button onClick={() => setPreguntaActual(preguntaActual - 1)} className="button_as">Anterior</button>
                            )}
                            {preguntaActual < examenPreguntas.length - 1 && (
                                <button onClick={handleSiguientePregunta} className="button_as">Siguiente</button>
                            )}
                        </div>
                        <p>Pregunta {preguntaActual + 1} de {examenPreguntas.length}</p>
                    </main>
                </Container>
            </Fragment>
        </div>
    );
}

export default withRouter(ExamenVPrevia);